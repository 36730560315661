
import { Options, Vue } from 'vue-class-component';
import Password from 'primevue/password';
import app from '@/main';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useStore } from '@/store';

@Options({
  components: {
    Password
  }
})
export default class Login extends Vue {
  public store = useStore();
  public email = '';
  public password = '';
  msg!: string;
  public validationErrors = {};
  public submitted = false;
  public itemId;
  public pageBefore;

  mounted() {
    //store.commit('setUser', user)
  }
  public async created() {
    this.reload();
  }
  public reload() {
    this.itemId = this.$router.currentRoute.value.params.id;
    this.pageBefore = this.$router.currentRoute.value.params.before;
  }
  public login() {
    this.submitted = true;
    if (this.validateForm()) {
      // eslint-disable-next-line no-unreachable
      app.config.globalProperties.$firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((user: firebase.auth.UserCredential) => {
          this.store.commit('setUser', user);
          if (this.pageBefore) {
            this.$router.push({ name: this.pageBefore });
          } else {
            this.$router.push({ name: 'Home' });
          }
        })
        // eslint-disable-next-line
        .catch((error: any) => {
          window.location.reload();
          if (error.code && error.code === 'auth/wrong-password') {
            this.validationErrors['password2'] = true;
          }
          if ((error.code && error.code === 'auth/invalid-email') || error.code === 'auth/user-not-found') {
            this.validationErrors['email2'] = true;
          }
        });
    }
  }

  validateForm() {
    if (!this.email.trim()) this.validationErrors['email'] = true;
    else delete this.validationErrors['email'];

    if (!this.password.trim()) this.validationErrors['password'] = true;
    else delete this.validationErrors['password'];

    return !Object.keys(this.validationErrors).length;
  }
}
