import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardMenu = _resolveComponent("DashboardMenu")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DashboardMenu),
    _createVNode(_component_DataTable, { value: _ctx.partners }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "ID",
          header: "ID"
        }),
        _createVNode(_component_Column, {
          field: "Name",
          header: "Name"
        }),
        _createVNode(_component_Column, {
          field: "Ansprechpartner",
          header: "Ansprechpartner"
        }),
        _createVNode(_component_Column, {
          field: "E-Mail",
          header: "E-Mail"
        }),
        _createVNode(_component_Column, {
          field: "Konditionen",
          header: "Konditionen"
        }),
        _createVNode(_component_Column, {
          field: "AGBs",
          header: "AGBs"
        }),
        _createVNode(_component_Column, {
          field: "Partner seit",
          header: "Partner seit"
        }),
        _createVNode(_component_Column, {
          field: "aktive Affiliate-Links",
          header: "aktive Affiliate-Links"
        }),
        _createVNode(_component_Column, {
          field: "Klicks",
          header: "Klicks"
        }),
        _createVNode(_component_Column, {
          field: "Umsatz",
          header: "Umsatz"
        })
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}