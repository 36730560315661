
import { Vue, prop } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';
import { useConfirm } from 'primevue/useconfirm';

// Define the props by using Vue's canonical way.
class Props {
  tip = prop({
    type: Object,
    required: true
  });
}

export default class TipReview extends Vue.with(Props) {
  public store = useStore();
  // eslint-disable-next-line
  public images: [unknown?] = [];
  public imageSrc = null;
  public favoriteTip = false;
  public tipWarned = false;
  public tipLocked = false;
  public userWarningOff = false;
  public confirm;
  public tipOnList = false;
  public selectedList;
  public blogshopDoc = {};

  public goToProductPage() {
    if (this.tip.locked == true) {
      return;
    }
    this.$router.push({ name: 'TipPage', params: { id: this.tip.id } });
  }

  public async likeTip(tipId) {
    // eslint-disable-next-line
    if (!this.user.uid) {
      return;
    }
    this.favoriteTip = true;
    const item = {
      type: 'tip',
      createdAt: new Date()
    };
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(tipId)
      .set(item);

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(1);
    await app.config.globalProperties.$firestore
      .collection('tips')
      .doc(this.tip.id)
      .update({ countFavorite: increment });
  }
  public async dislikeTip(tipId) {
    this.favoriteTip = false;

    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(tipId)
      .delete();

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(-1);
    await app.config.globalProperties.$firestore
      .collection('tips')
      .doc(this.tip.id)
      .update({ countFavorite: increment });
  }
  public confirmDeleteFromList(tipId) {
    this.confirm.require({
      message: 'Möchtest du den Tipp von deiner Liste entfernen?',
      header: 'Tipp entfernen',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.deleteTipFromList(tipId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public async deleteTipFromList(tipId) {
    this.tipOnList = false;
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .doc(this.selectedList)
      .collection('items')
      .doc(tipId)
      .delete();
    this.$toast.add({
      severity: 'success',
      summary: 'Tipp von Liste entfernt',
      detail: 'Der Tipp wurde von deiner Liste entfernt!',
      life: 3000
    });
  }

  public async created() {
    this.confirm = useConfirm();

    if (this.tip) {
      this.imageSrc = this.tip?.imageUrls ? this.tip.imageUrls[0] : null;
    }
    if (this.tip.locked == true) {
      this.tipLocked = true;
    }
    if (this.user) {
      const favoriteItem = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('favorites')
        .doc(this.tip.id)
        .get();
      if (favoriteItem && favoriteItem.exists) {
        this.favoriteTip = true;
      }
    }
    const warningItem = await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(this.tip.id)
      .get();

    if (warningItem && warningItem.exists) {
      this.tipWarned = true;
    }
    if (warningItem && warningItem.exists && warningItem.data().userWarningOff === true) {
      this.userWarningOff = true;
    }
    if (this.user) {
      const itemsRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .get();
      itemsRef.docs.forEach(async item => {
        const itemOnList = await app.config.globalProperties.$firestore
          .collection('blogshops')
          .doc(this.user.uid)
          .collection('lists')
          .doc(item.id)
          .collection('items')
          .doc(this.tip.id)
          .get();
        if (itemOnList.exists) {
          this.tipOnList = true;
          this.selectedList = item.id;
        }
      });
    }
    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.tip.userId)
      .get();
    this.blogshopDoc = blogshopRef.data();
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  public toDate() {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    if (this.tip.dates) {
      if (this.tip.dates[0] && this.tip.dates[1]) {
        const start = this.tip.dates[0].toDate().toLocaleDateString('de-DE', options);
        const end = this.tip.dates[1].toDate().toLocaleDateString('de-DE', options);
        return start + ' - ' + end;
      }
      if (this.tip.dates[0] && this.tip.dates[1] == null) {
        const start = this.tip.dates[0].toDate().toLocaleDateString('de-DE', options);
        return start;
      }
    }
  }
}
