// class-component-hooks.js
import { Vue } from 'vue-class-component';
// Register the router hooks with their names
Vue.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store, key } from './store';
import mitt from 'mitt';
import ConfirmationService from 'primevue/confirmationservice';


import VueGoogleMaps from '@fawmi/vue-google-maps';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import PrimeVue from 'primevue/config';
import localesGerman from '@/locales.json';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Sidebar from 'primevue/sidebar';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ScrollTop from 'primevue/scrolltop';
import AutoComplete from 'primevue/autocomplete';
import Textarea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';
import FileUpload from 'primevue/fileupload';
import Galleria from 'primevue/galleria';
import Checkbox from 'primevue/checkbox';
import InputMask from 'primevue/inputmask';
import ConfirmDialog from 'primevue/confirmdialog';
import MultiSelect from 'primevue/multiselect';
import ProgressBar from 'primevue/progressbar';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ToastService from 'primevue/toastservice';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import CascadeSelect from 'primevue/cascadeselect';
import Chart from 'primevue/chart';

const emitter = mitt();
const app = createApp(App);

app.component('InputText', InputText);
app.component('Button', Button);
app.component('Sidebar', Sidebar);
app.component('Dialog', Dialog);
app.component('Calendar', Calendar);
app.component('Dropdown', Dropdown);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('ScrollTop', ScrollTop);
app.component('AutoComplete', AutoComplete);
app.component('Textarea', Textarea);
app.component('InputNumber', InputNumber);
app.component('FileUpload', FileUpload);
app.component('Galleria', Galleria);
app.component('Checkbox', Checkbox);
app.component('InputMask', InputMask);
app.component('ConfirmDialog', ConfirmDialog);
app.component('MultiSelect', MultiSelect);
app.component('ProgressBar', ProgressBar);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('CascadeSelect', CascadeSelect);
app.component('Chart', Chart);

app.use(VueGoogleMaps, {
  load: {
      key: 'AIzaSyBqAWw5Ah-Uzp2PpsF6rv0UG_RuILaGpfk',
      libraries: "places"
  },
});

app.config.globalProperties.$emitter = emitter;

console.log(process.env.VUE_APP_FIREBASE_PROJECT);
const firebaseConfig = require(`@/environments/${process.env.VUE_APP_FIREBASE_PROJECT}.js`).firebaseConfig;
const algoliaConfig = require(`@/environments/${process.env.VUE_APP_FIREBASE_PROJECT}.js`).algoliaConfig;

firebase.initializeApp(firebaseConfig);

app.config.globalProperties.$firebase = firebase;
app.config.globalProperties.$algoliaConfig = algoliaConfig;
app.config.globalProperties.$firestore = firebase.firestore();
app.config.globalProperties.$functions = firebase.app().functions();
app.config.globalProperties.$storage = firebase.app().storage();

let unsubscribeUserDocumentListener;
let firestoreAuthInitialized = false;
firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    store.commit('setUser', user);
    if (unsubscribeUserDocumentListener) {
      unsubscribeUserDocumentListener();
    }
    const userDocumentRef = await firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .get();
    if (userDocumentRef.exists) {
      store.commit('setUserDocument', userDocumentRef.data());
    }
    if (firebase.auth().currentUser) {
      // eslint-disable-next-line
      (window as any).user = firebase.auth().currentUser;
      // eslint-disable-next-line
      const idTokenResult = await firebase.auth().currentUser!.getIdTokenResult();
      if (idTokenResult && idTokenResult.claims) {
        store.commit('setCustomClaims', idTokenResult.claims);
      }
      if (firebase.auth().currentUser?.emailVerified === false) {
        console.log('not verified');
        router.push({ name: 'Verification' });
      }
    }

    unsubscribeUserDocumentListener = await firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .onSnapshot(userDocumentRef => {
        if (userDocumentRef.exists) {
          store.commit('setUserDocument', userDocumentRef.data());
        }
      });
  } else {
    console.log('No one signed in');
  }
  if (!firestoreAuthInitialized) {
    firestoreAuthInitialized = true;

    app
      .use(store, key)
      .use(router)
      .use(ConfirmationService)
      .use(ToastService)
      .use(PrimeVue, { locale: localesGerman })
      .mount('#app');
  }
});

app.config.globalProperties.$primevue = { ripple: true };
export default app;
