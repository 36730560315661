
import { Vue, prop } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';
import { useConfirm } from 'primevue/useconfirm';

// Define the props by using Vue's canonical way.
class Props {
  product = prop({
    type: Object,
    required: true
  });
}

export default class ProductReview extends Vue.with(Props) {
  public store = useStore();
  // eslint-disable-next-line
  public images: [unknown?] = [];
  public imageSrc = null;
  public favoriteProduct = false;
  public productWarned = false;
  public productLocked = false;
  public userWarningOff = false;
  public confirm;
  public productOnList = false;
  public selectedList;
  public blogshopDoc = {};

  public goToProductPage() {
    if (this.product.locked == true) {
      return;
    }

    this.$router.push({ name: 'ProductPage', params: { id: this.product.id } });
  }

  public async likeProduct(productId) {
    // eslint-disable-next-line
    if (!this.user.uid) {
      return;
    }
    this.favoriteProduct = true;
    const item = {
      type: 'product',
      createdAt: new Date()
    };
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(productId)
      .set(item);

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(1);
    await app.config.globalProperties.$firestore
      .collection('products')
      .doc(this.product.id)
      .update({ countFavorite: increment });
  }
  public async dislikeProduct(productId) {
    this.favoriteProduct = false;

    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(productId)
      .delete();
    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(-1);
    await app.config.globalProperties.$firestore
      .collection('products')
      .doc(this.product.id)
      .update({ countFavorite: increment });
  }
  public confirmDeleteFromList(productId) {
    this.confirm.require({
      message: 'Möchtest du das Produkt von deiner Liste entfernen?',
      header: 'Produkt entfernen',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.deleteProductFromList(productId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public showPrice(price) {
    return price.toFixed(2).replace('.', ',');
  }
  public async deleteProductFromList(productId) {
    this.productOnList = false;
    console.log(productId);
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .doc(this.selectedList)
      .collection('items')
      .doc(productId)
      .delete();

    this.$toast.add({
      severity: 'success',
      summary: 'Produkt von Liste entfernt',
      detail: 'Das Produkt wurde von deiner Liste entfernt!',
      life: 3000
    });
  }

  public async created() {
    this.confirm = useConfirm();
    if (this.product) {
      this.imageSrc = this.product?.imageUrls ? this.product.imageUrls[0] : null;
    }
    if (this.product.locked == true) {
      this.productLocked = true;
    }
    if (this.user) {
      const favoriteItem = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('favorites')
        .doc(this.product.id)
        .get();
      if (favoriteItem && favoriteItem.exists) {
        this.favoriteProduct = true;
      }
    }
    const warningItem = await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(this.product.id)
      .get();

    if (warningItem && warningItem.exists) {
      this.productWarned = true;
    }
    if (warningItem && warningItem.exists && warningItem.data().userWarningOff === true) {
      this.userWarningOff = true;
    }
    if (this.user) {
      const itemsRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .get();
      itemsRef.docs.forEach(async item => {
        const itemOnList = await app.config.globalProperties.$firestore
          .collection('blogshops')
          .doc(this.user.uid)
          .collection('lists')
          .doc(item.id)
          .collection('items')
          .doc(this.product.id)
          .get();
        if (itemOnList.exists) {
          this.productOnList = true;
          this.selectedList = item.id;
        }
      });
    }
    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.product.userId)
      .get();
    this.blogshopDoc = blogshopRef.data();
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
}
