
import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';
import { useConfirm } from 'primevue/useconfirm';
import * as blogolinoData from '@/blogolinoData.json';
import ProductGrid from '@/components/ProductGrid.vue'; // @ is an alias to /src

@Options({
  components: {
    ProductGrid
  }
})
export default class PacketPage extends Vue {
  public store = useStore();
  public packetId;
  // eslint-disable-next-line
  public packet: any = null;
  public blogshopDoc = {};
  public images: [unknown?] = [];
  public imageSrc = null;
  public confirm;
  public favoritePacket = false;
  public packetWarned = false;
  public packetLocked = false;

  public userWarningOff = false;
  public productPacketIds: [unknown?] = [];
  public listExists = false;
  public packetOnList = false;
  public selectedList;
  public totalPrice = 0;

  public openBlogshop() {
    this.$router.push({ name: 'Blogshop', params: { id: this.packet.userId } });
  }
  getCategoryName(selectedCategory) {
    for (const packet of blogolinoData.packets) {
      if (selectedCategory === packet.code) {
        return packet.name;
      }
    }
  }

  public showSelectedAge(selectedAge) {
    let age = '';
    for (const element of selectedAge) {
      for (const item of blogolinoData.ages) {
        if (element === item.code) {
          age = age + ' ' + item.name + ',';
        }
      }
    }
    return age.slice(0, -1);
  }
  public showPrice(price) {
    return price.toFixed(2).replace('.', ',');
  }

  public addToList(packetId) {
    if (this.listExists == true) {
      this.$router.push({ name: 'AddToList', params: { itemId: packetId, type: 'packet' } });
    } else {
      this.$router.push({ name: 'AddNewList', params: { itemId: packetId, type: 'packet' } });
    }
  }

  public confirmPacketWarning(packetId) {
    this.confirm.require({
      message: 'Möchtest du das Paket melden, weil es verbotene Inhalte hat?',
      header: 'Paket melden',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.packetWarning(packetId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }

  public async packetWarning(packetId) {
    this.packetWarned = true;
    const item = {
      type: 'packet',
      whoSetWarning: this.user.uid,
      createdAt: new Date(),
      userWarningOff: false
    };
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(packetId)
      .set(item);

    this.$toast.add({ severity: 'success', summary: 'Produkt gemeldet', detail: 'Das Produkt wurde dem Support gemeldet!', life: 3000 });
    this.$router.push({ name: 'PacketPage', params: { id: packetId } });
  }

  public confirmPacketLock(packetId) {
    this.confirm.require({
      message: 'Möchtest du das Paket sperren?',
      header: 'Paket sperren',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.packetLock(packetId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public packetLock(packetId) {
    this.packetLocked = true;
    // eslint-disable-next-line
    const newPacket: any = {
      locked: this.packetLocked
    };
    app.config.globalProperties.$firestore
      .collection('packets')
      .doc(packetId)
      .set(newPacket, { merge: true });
  }
  public confirmPacketWarningOff(packetId) {
    this.confirm.require({
      message: 'Möchtest du das Paket wieder entsperren?',
      header: 'Paket entsperren',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.packetWarningOff(packetId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public async packetWarningOff(packetId) {
    this.packetWarned = false;
    this.packetLocked = false;
    const locked = app.config.globalProperties.$firebase.firestore.FieldValue.delete();
    await app.config.globalProperties.$firestore
      .collection('packets')
      .doc(packetId)
      .update({ locked });
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(packetId)
      .delete();
    this.$toast.add({ severity: 'success', summary: 'Paket entsperrt', detail: 'Das Paket wurde wieder entsperrt!', life: 3000 });
    this.$router.push({ name: 'PacketPage', params: { id: packetId } });
  }
  public async confirmPacketWarningOffOnlyForUser(packetId) {
    this.confirm.require({
      message: 'Möchtest du das Paket wieder für den User entsperren?',
      header: 'Paket für User entsperren',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.packetWarningOffOnlyForUser(packetId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public async packetWarningOffOnlyForUser(packetId) {
    this.userWarningOff = true;

    const packetDocument = {
      userWarningOff: true
    };
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(packetId)
      .set(packetDocument, { merge: true });
    this.$toast.add({ severity: 'success', summary: 'Paket für User entsperrt', detail: 'Das Paket wurde für den User wieder entsperrt!', life: 3000 });
  }
  public async confirmPacketFreeze(packetId) {
    this.userWarningOff = false;
    const packetDocument = {
      userWarningOff: false
    };
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(packetId)
      .set(packetDocument, { merge: true });
    this.$toast.add({ severity: 'success', summary: 'Paket wieder gesperrt', detail: 'Das Paket wurde wieder gesperrt!', life: 3000 });
  }
  public sendMessage(userId, packetId) {
    console.log(userId, packetId);
    this.$router.push({
      name: 'WriteMessage',
      params: { receiverId: userId },
      query: { itemId: packetId, type: 'packets' }
    });
  }
  public copyShareLink() {
    const link = location.protocol + '//' + location.host + '/packet/' + this.packetId;
    // eslint-disable-next-line
    const testingCodeToCopy: any = document.querySelector('#copy-clipboard-input')!;
    testingCodeToCopy.value = link;
    testingCodeToCopy.setAttribute('type', 'text');
    testingCodeToCopy.select();

    try {
      document.execCommand('copy');
      this.$toast.add({ severity: 'success', summary: 'Link kopiert', detail: 'Der Link wurde in deine Zwischenablage kopiert', life: 3000 });
      testingCodeToCopy.setAttribute('type', 'hidden');
    } catch (err) {
      alert('Oops, unable to copy');
    }
  }

  public async likePacket(packetId) {
    // eslint-disable-next-line
    if (!this.user.uid) {
      return;
    }
    this.favoritePacket = true;
    const item = {
      type: 'packet',
      createdAt: new Date()
    };
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(packetId)
      .set(item);

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(1);
    await app.config.globalProperties.$firestore
      .collection('packets')
      .doc(this.packetId)
      .update({ countFavorite: increment });
  }
  public async dislikePacket(packetId) {
    this.favoritePacket = false;

    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(packetId)
      .delete();
    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(-1);
    await app.config.globalProperties.$firestore
      .collection('packets')
      .doc(this.packetId)
      .update({ countFavorite: increment });
  }

  public async confirmDeletePacket(packetId) {
    this.confirm.require({
      message: 'Bist du sicher, dass du das Paket löschen willst?',
      header: 'Paket löschen',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.deletePacket(packetId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }

  public async deletePacket(packetId) {
    await app.config.globalProperties.$firestore
      .collection('packets')
      .doc(packetId)
      .delete();
    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(-1);
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .update({ countPackets: increment });
    this.$router.push({ name: 'MeinBlogshop' });
    this.$toast.add({ severity: 'success', summary: 'Paket gelöscht', detail: 'Dein Paket wurde erfolgreich gelöscht', life: 3000 });
  }

  public async created() {
    this.confirm = useConfirm();
    this.loadPacket();
  }

  public async loadPacket() {
    this.packetId = this.$router.currentRoute.value.params.id;
    const warningItem = await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(this.packetId)
      .get();

    if (warningItem && warningItem.exists) {
      this.packetWarned = true;
    }
    if (warningItem && warningItem.exists && warningItem.data().userWarningOff === true) {
      this.userWarningOff = true;
    }

    const packetRef = await app.config.globalProperties.$firestore
      .collection('packets')
      .doc(this.packetId)
      .get();
    if (packetRef.exists) {
      this.packet = packetRef.data();
      if (this.packet.locked == true) {
        this.packetLocked = true;
      }
      this.packet?.imageUrls?.forEach(url => {
        // eslint-disable-next-line no-unreachable
        const imageItem = {
          itemImageSrc: url,
          thumbnailImageSrc: url,
          alt: 'Beschreibung'
        };
        this.images.push(imageItem);
        this.imageSrc = this.packet.imageUrls[0] ? this.packet.imageUrls[0] : null;
      });
    }
    if (this.packet.linkedProductsIds) {
      for (const _productId of this.packet.linkedProductsIds) {
        const linkedProduct = await app.config.globalProperties.$firestore
          .collection('products')
          .doc(_productId)
          .get();
        const price = linkedProduct.data().price;
        this.totalPrice = this.totalPrice + price;
      }
    }
    if (this.user) {
      const itemsRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .get();
      if (itemsRef) {
        this.listExists = true;
      }
      itemsRef.docs.forEach(async item => {
        const itemOnList = await app.config.globalProperties.$firestore
          .collection('blogshops')
          .doc(this.user.uid)
          .collection('lists')
          .doc(item.id)
          .collection('items')
          .doc(this.packetId)
          .get();
        if (itemOnList.exists) {
          this.packetOnList = true;
          this.selectedList = item.id;
        }
      });
    }

    if (this.user) {
      const favoriteItem = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('favorites')
        .doc(this.packetId)
        .get();
      if (favoriteItem && favoriteItem.exists) {
        this.favoritePacket = true;
      }
    }
    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.packet.userId)
      .get();
    this.blogshopDoc = blogshopRef.data();
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  public goToLogin(packetId) {
    this.$router.push({ name: 'Login', params: { id: packetId, before: 'PacketPage' } });
  }
  public goToNewIdea() {
    this.$router.push({ name: 'NewIdea' });
  }
}
