export const firebaseConfig = {
    apiKey: "AIzaSyB0Ed3N9ISyqp-36py5mLSGWgVZuU-iJAw",
    authDomain: "blogolino-prod.firebaseapp.com",
    projectId: "blogolino-prod",
    storageBucket: "blogolino-prod.appspot.com",
    messagingSenderId: "11045813941",
    appId: "1:11045813941:web:e3efc59abc794d856688fc",
    measurementId: "G-CH6DH7K3R5"
  };
  export const algoliaConfig = {
    appId: "91YT25NAME",
    apiKey: "c17089634ae009a48b0fb122c7518745",
  };