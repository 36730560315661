<template>
  <div class="p-d-flex p-flex-column">
    <router-link to="/superadmin/dashboard" class="link">
      Home
    </router-link>
    <router-link to="/superadmin/dashboard-warnings" class="link">
      Warnings
    </router-link>
    <router-link to="/superadmin/dashboard-users" class="link">
      User
    </router-link>
    <router-link to="/superadmin/dashboard-blogshops" class="link">
      Blogshops
    </router-link>
    <router-link to="/superadmin/dashboard-products" class="link">
      Produkte
    </router-link>
    <router-link to="/superadmin/dashboard-tips" class="link">
      Tipps
    </router-link>
    <router-link to="/superadmin/dashboard-packets" class="link">
      Pakete
    </router-link>
    <router-link to="/superadmin/dashboard-partners" class="link">
      Partner
    </router-link>
    <router-link to="/superadmin/dashboard-messages" class="link">
      Nachrichten
    </router-link>
    <router-link to="/superadmin/dashboard-newsletter" class="link">
      Newsletter
    </router-link>
    <router-link to="/superadmin/dashboard-feedback" class="link">
      Feedback
    </router-link>
    <router-link to="/superadmin/dashboard-bonus" class="link">
      Bonusprogramm
    </router-link>
  </div>
</template>

<style scoped>
.link {
  margin-left: 30px;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  letter-spacing: -0.25px;
  padding-top: 30px;
  padding-left: 20px;
  cursor: pointer;
  text-decoration: none;
}
</style>
