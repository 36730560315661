
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue } from 'vue-class-component';
import { useStore } from '@/store';
import 'firebase/auth';
import * as blogolinoData from '@/blogolinoData.json';

export default class SidebarFilter extends Vue {
  public visibleRight = false;
  public store = useStore();

  public ages: any = [];
  public showSort = false;
  public showAge = false;
  public showCategory = false;
  public selectedCategory;
  public selectedSubcategory;
  public selectedPrice;
  public categories: any = [];
  public currentCategory;
  public subcategories: any = [];
  public showProductsSubcategory = false;
  public showProducts = false;
  public showBrowse = false;
  public showType = false;
  public showAll = false;
  public showTips = false;
  public showPackets = false;
  public showPriceRange = false;
  public tips: any = [];
  public packets: any = [];
  public query;
  public type;
  public selectedCode = '';
  public typeSelected = '';
  public categorySelected = '';
  public subcategorySelected = '';
  public selectedAge;
  public minPrice = 0;
  public maxPrice = 0;
  public sortItem = '';
  public sort;

  public async created() {
    this.query = this.$router.currentRoute.value.query.query;

    this.type = this.$router.currentRoute.value.query.type;
    if (this.type) {
      this.typeSelected = this.type;
    }
    this.sort = this.$router.currentRoute.value.query.sortItems;
    if (this.sort) {
      this.sortItem = this.sort;
    }
    this.selectedAge = this.$router.currentRoute.value.query.age;
    if (this.selectedAge) {
      this.selectedCode = this.selectedAge;
    }

    this.selectedCategory = this.$router.currentRoute.value.query.category;
    if (this.selectedCategory) {
      this.categorySelected = this.selectedCategory;
    }
    this.selectedSubcategory = this.$router.currentRoute.value.query.subcategory;
    if (this.selectedSubcategory) {
      this.subcategorySelected = this.selectedSubcategory;
    }
    if (this.$router.currentRoute.value.query.minPrice) {
      this.minPrice = +this.$router.currentRoute.value.query.minPrice;
    }
    if (this.$router.currentRoute.value.query.maxPrice) {
      this.maxPrice = +this.$router.currentRoute.value.query.maxPrice;
    }
    this.selectedSubcategory = this.$router.currentRoute.value.query.subcategory;
    this.ages = blogolinoData.ages;
    this.categories = blogolinoData.categories;
    this.tips = blogolinoData.tips;
    this.packets = blogolinoData.packets;
  }
  public async reset() {
    this.showSort = false;
    this.showAge = false;
    this.showProducts = false;
    this.showProductsSubcategory = false;
    this.showBrowse = false;
    this.showAll = false;
    this.showTips = false;
    this.showPackets = false;
    this.showType = false;
    this.showPriceRange = false;
  }
  public reload() {
    this.query = this.$router.currentRoute.value.query.query;
    this.type = this.$router.currentRoute.value.query.type;
    this.sort = this.$router.currentRoute.value.query.sortItems;
    if (this.sort) {
      this.sortItem = this.sort;
    }

    if (this.type) {
      this.typeSelected = this.type;
    }

    this.selectedAge = this.$router.currentRoute.value.query.age;
    if (this.selectedAge) {
      this.selectedCode = this.selectedAge;
    }
    this.selectedCategory = this.$router.currentRoute.value.query.category;
    if (this.selectedCategory) {
      this.categorySelected = this.selectedCategory;
    }
    this.selectedSubcategory = this.$router.currentRoute.value.query.subcategory;
    if (this.selectedSubcategory) {
      this.subcategorySelected = this.selectedSubcategory;
    }

    if (this.$router.currentRoute.value.query.maxPrice) {
      this.maxPrice = +this.$router.currentRoute.value.query.maxPrice;
    }
    if (this.$router.currentRoute.value.query.minPrice) {
      this.minPrice = +this.$router.currentRoute.value.query.minPrice;
    }
  }

  public toggleSort() {
    this.showSort = !this.showSort;
  }
  public toggleAge() {
    this.showAge = !this.showAge;
  }
  public toggleType() {
    this.showType = !this.showType;
  }
  public toggleCategory() {
    this.showProducts = !this.showProducts;
  }
  public togglePrice() {
    this.showPriceRange = !this.showPriceRange;
  }
  public openCategory(category, type?, age?) {
    this.typeSelected = type;
    this.categorySelected = category;

    if (category.code === 'ALL') {
      this.$router.push({ name: 'Search', query: { category: category.code, type: type, age: age, minPrice: 0, maxPrice: 0 } });
      this.visibleRight = false;
    } else {
      this.$router.push({ name: 'Search', query: { category: category.code, type: type, age: age, minPrice: 0, maxPrice: 0 } });
      this.visibleRight = false;
    }
  }
  public showProductsSubcategories(category, age?) {
    this.typeSelected = 'products';
    this.categorySelected = category.code;
    if (category.code === 'ALL') {
      this.$router.push({ name: 'Search', query: { category: category.code, type: 'products', age: age } });
      this.visibleRight = false;
    } else {
      this.subcategories = category.subcategories;
      this.currentCategory = category;
      this.showProducts = false;
      this.showProductsSubcategory = true;
    }
  }
  public openSubcategory(subcategory, type?, age?) {
    this.subcategorySelected = subcategory;
    this.typeSelected = type;
    this.$router.push({
      name: 'Search',
      query: { category: this.currentCategory.code, subcategory: subcategory.code, type: type, age: age }
    });
    this.visibleRight = false;
  }
  public goToProducts() {
    this.showProductsSubcategory = false;
    this.showProducts = true;
  }
  get searchFilter(): any {
    console.log(this.store.state.searchFilter);
    return this.store.state.searchFilter;
  }

  public showSelectedAge(selectedAge) {
    let age = '';

    for (const item of blogolinoData.ages) {
      if (selectedAge === item.code) {
        age = age + ' ' + item.name + ',';
      }
    }

    return age.slice(0, -1);
  }
  public showAllItems(minPrice?, maxPrice?, code?) {
    this.searchFilter.type = null;
    this.typeSelected = '';

    this.$router.push({ name: 'Search', query: { age: code, minPrice: minPrice, maxPrice: maxPrice } });
    this.showAll = true;
    console.log(this.showAll);
    this.visibleRight = false;
  }

  public showSelectedCategory(selectedCategory) {
    let category = '';
    if (this.type == 'products') {
      for (const item of blogolinoData.categories) {
        if (selectedCategory === item.code) {
          category = category + ' ' + item.name + ',';
        }
      }
    } else if (this.type == 'tips') {
      for (const item of blogolinoData.tips) {
        if (selectedCategory === item.code) {
          category = category + ' ' + item.name + ',';
        }
      }
    } else if (this.type == 'packets') {
      for (const item of blogolinoData.packets) {
        if (selectedCategory === item.code) {
          category = category + ' ' + item.name + ',';
        }
      }
    }
    return category.slice(0, -1);
  }
  public showSelectedSubcategory(selectedCategory, selectedSubcategory) {
    const categories = blogolinoData.categories;
    for (const category of categories) {
      if (selectedCategory === category.code) {
        if (selectedSubcategory) {
          const subcategories = category.subcategories ?? [];
          for (const subcategory of subcategories) {
            if (selectedSubcategory === subcategory.code) {
              return subcategory.name;
            }
          }
        }
      }
    }
  }
  public sortItems(typeSort) {
    this.sortItem = typeSort;
    console.log(this.sortItem);

    this.$router.push({
      name: 'Search',
      query: { sortItems: typeSort }
    });
    this.visibleRight = false;
  }

  public getType(typeSelected) {
    if (typeSelected == 'products') {
      return 'Produkte';
    } else if (typeSelected == 'tips') {
      return 'Tipps';
    }
    if (typeSelected == 'packets') {
      return 'Pakete';
    }
    return 'beliebig';
  }

  public openAge(code, type?, category?, subcategory?) {
    this.selectedCode = code;
    console.log(this.type);
    this.$router.push({ name: 'Search', query: { age: code, type: type, category: category, subcategory: subcategory } });
    if (!this.type || this.type.toLowerCase() === 'all') {
      this.showAll = true;
    }
    console.log(this.showAll);
    this.visibleRight = false;
  }
  public toggleTips() {
    this.showTips = !this.showTips;
  }
  public togglePackets() {
    this.showPackets = !this.showPackets;
  }
  public toggleBrowse() {
    this.showBrowse = !this.showBrowse;
  }
  public toggleAll() {
    this.showAll = !this.showAll;
  }
  public deleteFilter() {
    this.searchFilter.selectedAge = null;
    this.searchFilter.selectedCategory = null;
    this.minPrice = 0;
    this.maxPrice = 0;
    this.type = '';
    this.searchFilter.type = null;
    this.visibleRight = false;
    this.selectedCode = '';
    this.typeSelected = '';
    this.categorySelected = '';
    this.subcategorySelected = '';
    this.sortItem = '';

    this.$router.push({
      name: 'Search',
      query: {
        query: this.query,
        type: this.type,
        selectedAge: this.selectedAge,
        selectedCategory: this.selectedCategory,
        selectedSubcategory: this.selectedSubcategory,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        typeSort: this.sortItem
      }
    });
  }
  public savePriceRange(minPrice, maxPrice, code?, type?, category?) {
    if (!minPrice) {
      this.minPrice = 0;
    } else {
      this.minPrice == minPrice;
    }
    if (!maxPrice) {
      this.maxPrice = 0;
    } else {
      this.maxPrice == maxPrice;
    }
    if (minPrice && maxPrice && minPrice > maxPrice) {
      const oldMinPrice = minPrice;
      this.minPrice = maxPrice;
      this.maxPrice = oldMinPrice;
    }
    if (type == 'products' && !category) {
      category = 'ALL';
      this.currentCategory = 'ALL';
    }
    if (type == 'tips' || type == 'packets') {
      category = 'ALL';
      this.currentCategory = 'ALL';
      this.typeSelected = 'products';
    }
    console.log(minPrice, this.minPrice, maxPrice, this.maxPrice);
    this.$router.push({ name: 'Search', query: { minPrice: this.minPrice, maxPrice: this.maxPrice, age: code, type: 'products', category: category } });
    this.visibleRight = false;
  }
}
