
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue } from 'vue-class-component';
import app from '@/main';
import { useStore } from '@/store';

export default class AddToList extends Vue {
  public selectedList: any = null;
  public store = useStore();
  public lists: any = [];
  public listHeader = '';
  public itemId = '';
  public itemType;

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }

  public async created() {
    const itemId = String(this.$router.currentRoute.value.params.itemId);
    const type = String(this.$router.currentRoute.value.params.type);
    if (itemId) {
      this.itemId = itemId;
      this.itemType = type;
    }
    const listRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .get();
    listRef.docs.forEach(ref => {
      const item = ref.data();
      item.id = ref.id;
      this.lists.push(item);
    });
    console.log(this.lists);
  }
  public async addToList() {
    const newListItem: any = {
      type: this.itemType,
      createdAt: new Date()
    };
    console.log(this.selectedList);
    if (!this.selectedList) {
      return;
    }
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .doc(this.selectedList)
      .collection('items')
      .doc(this.itemId)
      .set(newListItem, { merge: true });
    this.$router.push({ name: 'ProductPage', params: { id: this.itemId } });
    this.$toast.add({ severity: 'success', summary: 'Produkt hinzugefügt', detail: 'Das Produkt wurde deiner Liste hinzugefügt', life: 3000 });
  }
  public async addToNewList() {
    this.$router.push({ name: 'AddNewList', params: { itemId: this.itemId, type: this.itemType } });
  }
}
