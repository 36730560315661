
import { Options, Vue } from 'vue-class-component';
import app from '@/main';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src
import { FilterMatchMode } from 'primevue/api';

@Options({
  components: {
    DashboardMenu
  }
})
export default class DashboardPackets extends Vue {
  public packetList: [unknown?] = [];
  // eslint-disable-next-line
  public filters: any = null;

  initFilters() {
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
  }
  public openPacketPage(data) {
    console.log(data);
    this.$router.push({ name: 'PacketPage', params: { id: data.id } });
  }

  async created() {
    this.initFilters();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const packets = await app.config.globalProperties.$firestore.collection('packets').get();
    packets.forEach(item => {
      const packetItem = item.data();
      packetItem.id = item.id;
      packetItem.createdAt = item.data().createdAt
        ? item
            .data()
            .createdAt.toDate()
            .toLocaleDateString('en-CA', options)
        : null;
      this.packetList.push(packetItem);
    });
  }
}
