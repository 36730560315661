
import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';
import ChatGrid from '@/components/ChatGrid.vue';

@Options({
  components: {
    ChatGrid
  }
})
export default class Messages extends Vue {
  public store = useStore();

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  public goToWriteMessage() {
    this.$router.push({
      name: 'WriteSupportMessage'
    });
  }
}
