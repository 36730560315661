
import { Options, Vue } from 'vue-class-component';
import app from '@/main';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src
import { FilterMatchMode } from 'primevue/api';

@Options({
  components: {
    DashboardMenu
  }
})
export default class DashboardBlogshops extends Vue {
  public blogshopList: [unknown?] = [];
  // eslint-disable-next-line
  public filters: any = null;

  public openSettingsBlogshop(data) {
    console.log(data);
    this.$router.push({ name: 'SettingsBlogshop', params: { id: data.id } });
  }

  async created() {
    this.initFilters();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const blogshops = await app.config.globalProperties.$firestore.collection('blogshops').get();
    blogshops.forEach(item => {
      const blogshopItem = item.data();
      blogshopItem.id = item.id;
      blogshopItem.createdAt = item.data().createdAt
        ? item
            .data()
            .createdAt.toDate()
            .toLocaleDateString('en-CA', options)
        : null;
      this.blogshopList.push(blogshopItem);
    });
  }
  initFilters() {
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
  }
}
