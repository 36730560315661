
import { Vue, prop } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';
// Define the props by using Vue's canonical way.
class Props {
  blogshop = prop({
    type: Object,
    required: true
  });
}

export default class BlogshopReview extends Vue.with(Props) {
  public store = useStore();
  // eslint-disable-next-line
  public images: [unknown?] = [];
  public imageSrc = null;
  public favoriteBlogshop = false;
  // eslint-disable-next-line
  public blogshopDoc: any = {};
  public blogshopCreated = '';
  public city = '';
  public userId;
  public blogshopExists;

  public goToBlogshopPage() {
    this.$router.push({ name: 'Blogshop', params: { id: this.blogshopDoc.id } });
  }

  public async likeBlogshop(blogshopId) {
    // eslint-disable-next-line
    if (!this.user.uid) {
      return;
    }
    this.favoriteBlogshop = true;
    const item = {
      type: 'blogshop',
      createdAt: new Date()
    };
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(blogshopId)
      .set(item);

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(1);
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(blogshopId)
      .update({ countFollowers: increment });
  }
  public async dislikeBlogshop(blogshopId) {
    this.favoriteBlogshop = false;

    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(blogshopId)
      .delete();

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(-1);
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(blogshopId)
      .update({ countFollowers: increment });
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }

  public async created() {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    this.userId = this.$router.currentRoute.value.params.id;
    // If the user is not set, then use current userId
    if (this.user && !this.userId) {
      this.userId = this.user.uid;
    }

    this.blogshopDoc = this.blogshop;
    this.blogshopExists = this.blogshop.blogshopExists;
    if (this.blogshopExists == true) {
      this.blogshopCreated = this.blogshop.createdAt.toDate().toLocaleDateString('de-DE', options);
    }
    if (this.user) {
      const favoriteItem = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('favorites')
        .doc(this.blogshop.id)
        .get();
      if (favoriteItem && favoriteItem.exists) {
        this.favoriteBlogshop = true;
      }
    }
  }
}
