
import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';
import * as blogolinoData from '@/blogolinoData.json';
import SettingsMenu from '@/components/SettingsMenu.vue'; // @ is an alias to /src
import { Field, Form } from 'vee-validate';

@Options({
  components: {
    SettingsMenu,
    Field,
    Form
  }
})
export default class SettingsProfile extends Vue {
  public store = useStore();
  public username = '';
  public city = '';

  public familyConstellation = '';
  public numberChildren = '';
  // eslint-disable-next-line
  public birthdayChilds: any = [];
  public petSpecies = '';
  public shortText = '';
  public longText = '';
  public userId;
  public imageSrc = null;
  public files: [File?] = [];
  public imageURLs: [string?] = [];
  public validationErrors = {};
  public blogshopExists = false;
  public submitted = false;

  // eslint-disable-next-line
  public constellations: any = [];
  // eslint-disable-next-line
  public numbers: any = [];
  // eslint-disable-next-line
  public species: any = [];

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  public selectedFile(event) {
    event.files.forEach(file => {
      this.files.push(file);
    });
  }
  public async uploadImages(productId) {
    const storageRef = app.config.globalProperties.$storage.ref();
    const result = await Promise.all(
      this.files.map(async file => {
        /*** lastModified: 1585076337000
        lastModifiedDate: Tue Mar 24 2020 19:58:57 GMT+0100 (Central European Standard Time) {}
        name: "Pro_aurom_bote.jpg"
        objectURL: "blob:http://localhost:8080/7a861899-2f35-4385-9d3e-4855a8176308"
        size: 145310
        type: "image/jpeg"
        */
        if (!file) {
          return;
        }
        const fileName = this.uuidv4();
        const productImagesRef = storageRef.child(`images/${productId}/${fileName}`);
        const metadata = {
          contentType: file.type
        };
        const snapshot = await productImagesRef.put(file, metadata);

        const downloadURL = await snapshot.ref.getDownloadURL();
        this.imageURLs.push(downloadURL);
      })
    );
    return result;
  }
  public uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  async created() {
    this.loadSettingsProfile();
  }
  public async loadSettingsProfile() {
    this.constellations = blogolinoData.familyConstellation;
    this.numbers = blogolinoData.numberChildren;
    this.species = blogolinoData.petSpecies;

    const userRef = await app.config.globalProperties.$firestore
      .collection('users')
      .doc(this.user.uid)
      .get();
    if (userRef.exists) {
      const userData = userRef.data();
      this.username = userData.username;
      this.city = userData.city;
    }
    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .get();
    if (blogshopRef.exists) {
      const blogshop = blogshopRef.data();
      this.shortText = blogshop.shortText;
      this.longText = blogshop.longText;
      this.familyConstellation = blogshop.familyConstellation;
      this.numberChildren = blogshop.numberChildren;
      this.petSpecies = blogshop.petSpecies;
      this.blogshopExists = blogshop.blogshopExists;
      if (blogshop.birthdayChilds) {
        this.birthdayChilds = ['0'].concat(blogshop.birthdayChilds);
      }

      this.imageSrc = blogshop?.imageUrls ? blogshop.imageUrls[0] : null;
    }
    console.log(this.imageSrc);
  }
  public async deleteProfilePicture() {
    const imageUrls = app.config.globalProperties.$firebase.firestore.FieldValue.delete();
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .update({ imageUrls });
    this.imageSrc = null;
    this.loadSettingsProfile();
  }

  public async saveProfileChanges() {
    this.submitted = true;
    if (!this.city || !this.username) {
      this.validationErrors['city'] = !this.city;
      this.validationErrors['username'] = !this.username;
      return;
    }
    const userDocument = {
      city: this.city,
      username: this.username
    };

    Object.keys(userDocument).forEach(key => (userDocument[key] === undefined ? delete userDocument[key] : {}));

    await app.config.globalProperties.$firestore
      .collection('users')
      .doc(this.user.uid)
      .set(userDocument, { merge: true });
    this.store.commit('setUserDocument', userDocument);
    // eslint-disable-next-line
    const blogshopDocument: any = {
      shortText: this.shortText,
      longText: this.longText,
      familyConstellation: this.familyConstellation,
      numberChildren: this.numberChildren,
      petSpecies: this.petSpecies,
      name: this.username
    };
    if (this.birthdayChilds.length > 0) {
      blogshopDocument.birthdayChilds = [...this.birthdayChilds];
      blogshopDocument.birthdayChilds.splice(0, 1);
    }

    // Remove empty fields, don't save them in the database.
    Object.keys(blogshopDocument).forEach(key => (blogshopDocument[key] === undefined ? delete blogshopDocument[key] : {}));

    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .set(blogshopDocument, { merge: true });
    if (this.files && this.files.length > 0) {
      await this.uploadImages(this.user.uid);
      console.log(blogshopDocument);
      app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .set({ imageUrls: this.imageURLs }, { merge: true });
    }
    this.loadSettingsProfile();

    this.$toast.add({ severity: 'success', summary: 'Profil gespeichert', detail: 'Dein Profil wurde erfolgreich gespeichert', life: 3000 });
  }
}
