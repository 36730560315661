
import { Vue, prop } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';
import { useConfirm } from 'primevue/useconfirm';

// Define the props by using Vue's canonical way.
class Props {
  packet = prop({
    type: Object,
    required: true
  });
}

export default class PacketReview extends Vue.with(Props) {
  public store = useStore();
  // eslint-disable-next-line
  public images: [unknown?] = [];
  public imageSrc = null;
  public favoritePacket = false;
  public packetWarned = false;
  public packetLocked = false;
  public confirm;
  public packetOnList = false;
  public selectedList;
  public blogshopDoc = {};
  public totalPrice = 0;

  public userWarningOff = false;

  public goToPacketPage() {
    if (this.packet.locked == true) {
      return;
    }
    this.$router.push({ name: 'PacketPage', params: { id: this.packet.id } });
  }
  public async likePacket(packetId) {
    // eslint-disable-next-line
    if (!this.user.uid) {
      return;
    }
    this.favoritePacket = true;
    const item = {
      type: 'packet',
      createdAt: new Date()
    };
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(packetId)
      .set(item);

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(1);
    await app.config.globalProperties.$firestore
      .collection('packets')
      .doc(this.packet.id)
      .update({ countFavorite: increment });
  }
  public async dislikePacket(packetId) {
    this.favoritePacket = false;

    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(packetId)
      .delete();

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(-1);
    await app.config.globalProperties.$firestore
      .collection('packets')
      .doc(this.packet.id)
      .update({ countFavorite: increment });
  }
  public confirmDeleteFromList(packetId) {
    this.confirm.require({
      message: 'Möchtest du das Paket von deiner Liste entfernen?',
      header: 'Paket entfernen',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.deletePacketFromList(packetId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public async deletePacketFromList(packetId) {
    this.packetOnList = false;

    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .doc(this.selectedList)
      .collection('items')
      .doc(packetId)
      .delete();
    this.$toast.add({
      severity: 'success',
      summary: 'Paket von Liste entfernt',
      detail: 'Das Paket wurde von deiner Liste entfernt!',
      life: 3000
    });
  }

  public async created() {
    this.confirm = useConfirm();
    if (this.packet) {
      this.imageSrc = this.packet?.imageUrls ? this.packet.imageUrls[0] : null;
    }
    if (this.packet.locked == true) {
      this.packetLocked = true;
    }
    if (this.user) {
      const favoriteItem = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('favorites')
        .doc(this.packet.id)
        .get();
      if (favoriteItem && favoriteItem.exists) {
        this.favoritePacket = true;
      }
    }
    const warningItem = await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(this.packet.id)
      .get();

    if (this.packet.linkedProductsIds) {
      for (const _productId of this.packet.linkedProductsIds) {
        const linkedProduct = await app.config.globalProperties.$firestore
          .collection('products')
          .doc(_productId)
          .get();
        const price = linkedProduct.data().price;
        this.totalPrice = this.totalPrice + price;
      }
    }

    if (warningItem && warningItem.exists) {
      this.packetWarned = true;
    }
    if (warningItem && warningItem.exists && warningItem.data().userWarningOff === true) {
      this.userWarningOff = true;
    }
    if (this.user) {
      const itemsRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .get();
      itemsRef.docs.forEach(async item => {
        const itemOnList = await app.config.globalProperties.$firestore
          .collection('blogshops')
          .doc(this.user.uid)
          .collection('lists')
          .doc(item.id)
          .collection('items')
          .doc(this.packet.id)
          .get();
        if (itemOnList.exists) {
          this.packetOnList = true;
          this.selectedList = item.id;
        }
      });
    }
    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.packet.userId)
      .get();
    this.blogshopDoc = blogshopRef.data();
  }
  public showPrice(price) {
    return price.toFixed(2).replace('.', ',');
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
}
