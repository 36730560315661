
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue } from 'vue-class-component';
import { useStore } from '@/store';
import 'firebase/auth';
import app from '@/main';
import * as blogolinoData from '@/blogolinoData.json';

export default class SidebarMenu extends Vue {
  public visibleRight = false;
  public store = useStore();
  public showProducts = false;
  public showTips = false;
  public showPackets = false;
  public showBrowse = false;
  public showBrowseAll = false;
  public showBrowseProducts = false;
  public showBrowseTips = false;
  public showBrowsePackets = false;
  public showProductsSubcategories = false;
  public categories: any = [];
  public currentCategory;
  public subcategories: any = [];
  public tips: any = [];
  public packets: any = [];
  public ages: any = [];

  public async created() {
    this.categories = blogolinoData.categories;
    this.ages = blogolinoData.ages;
    this.tips = blogolinoData.tips;
    this.packets = blogolinoData.packets;
  }
  public async reset() {
    this.currentCategory = null;
    this.showTips = false;
    this.showPackets = false;
    this.showBrowse = false;
    this.showBrowseAll = false;
    this.showBrowseProducts = false;
    this.showBrowseTips = false;
    this.showBrowsePackets = false;
    this.showProductsSubcategories = false;
  }

  get user() {
    return this.store.state.user;
  }

  public openSubcategory(subcategory, type?) {
    this.$router.push({
      name: 'Search',
      query: { category: this.currentCategory.code, subcategory: subcategory.code, type: type }
    });
    this.visibleRight = false;
  }
  public openCategory(category, type?) {
    this.$router.push({ name: 'Search', query: { category: category.code, type: type } });
    this.visibleRight = false;
  }
  public openAge(age, type?) {
    this.$router.push({ name: 'Search', query: { age: age.code, type: type } });
    this.visibleRight = false;
  }
  public openBlogshops() {
    this.$router.push({ name: 'Search', query: { type: 'blogshops' } });
    this.visibleRight = false;
  }
  public showSubcategory(category) {
    if (category.code === 'ALL') {
      this.$router.push({ name: 'Search', query: { category: category.code, type: 'products' } });
      this.visibleRight = false;
    } else {
      this.subcategories = category.subcategories;
      this.currentCategory = category;
      this.showProducts = false;
      this.showProductsSubcategories = true;
    }
  }
  public toggleProducts() {
    this.showProducts = !this.showProducts;
  }
  public goToProducts() {
    this.showProductsSubcategories = false;
    this.showProducts = true;
  }
  public toggleTips() {
    this.showTips = !this.showTips;
  }
  public togglePackets() {
    this.showPackets = !this.showPackets;
  }
  public toggleBrowse() {
    this.showBrowse = !this.showBrowse;
  }
  public toggleBrowseAll() {
    this.showBrowseAll = !this.showBrowseAll;
  }
  public toggleBrowseProducts() {
    this.showBrowseProducts = !this.showBrowseProducts;
  }
  public toggleBrowseTips() {
    this.showBrowseTips = !this.showBrowseTips;
  }
  public toggleBrowsePackets() {
    this.showBrowsePackets = !this.showBrowsePackets;
  }

  public logout() {
    app.config.globalProperties.$firebase.auth().signOut();
    this.store.commit('setUser', null);
    this.$router.push({ name: 'Home' });
    this.visibleRight = false;
  }
}
