import firebase from 'firebase/app';
import 'firebase/auth';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Profile from '../views/Profile.vue';
import Search from '../views/Search.vue';
import Blogshop from '../views/Blogshop.vue';
import CreateBlogshop from '../views/CreateBlogshop.vue';
import NewIdea from '../views/NewIdea.vue';
import NewProduct from '../views/NewProduct.vue';
import ProductNotFound from '../views/ProductNotFound.vue';
import NewProductLink from '../views/NewProductLink.vue';
import ProductPage from '../views/ProductPage.vue';
import NewTip from '../views/NewTip.vue';
import TipPage from '../views/TipPage.vue';
import NewPacket from '../views/NewPacket.vue';
import PacketPage from '../views/PacketPage.vue';
import Impressum from '../views/Impressum.vue';
import AboutBlogolino from '../views/AboutBlogolino.vue';
import FAQ from '../views/Faq.vue';
import Messages from '../views/Messages.vue';
import WriteMessage from '../views/WriteMessage.vue';
import Dashboard from '../views/Dashboard.vue';
import DashboardWarnings from '../views/DashboardWarnings.vue';
import DashboardUsers from '../views/DashboardUsers.vue';
import DashboardProducts from '../views/DashboardProducts.vue';
import DashboardTips from '../views/DashboardTips.vue';
import DashboardPackets from '../views/DashboardPackets.vue';
import DashboardPartners from '../views/DashboardPartners.vue';
import DashboardMessages from '../views/DashboardMessages.vue';
import DashboardNewsletter from '../views/DashboardNewsletter.vue';
import DashboardBlogshops from '../views/DashboardBlogshops.vue';
import DashboardFeedback from '../views/DashboardFeedback.vue';
import DashboardBonus from '../views/DashboardBonus.vue';
import SettingsAccount from '../views/SettingsAccount.vue';
import SettingsBlogshop from '../views/SettingsBlogshop.vue';
import SettingsProfile from '../views/SettingsProfile.vue';
import SettingsPrivacy from '../views/SettingsPrivacy.vue';
import SettingsMail from '../views/SettingsMail.vue';
import AddToList from '../views/AddToList.vue';
import AddNewList from '../views/AddNewList.vue';
import ShareList from '../views/ShareList.vue';
import WriteSupportMessage from '../views/WriteSupportMessage.vue';
import Partner from '../views/Partner.vue';
import AGBs from '../views/AGBs.vue';
import Feedback from '../views/Feedback.vue';
import Verification from '../views/Verification.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/profile',
    name: 'MeinProfile',
    component: Profile
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/blogshop',
    name: 'MeinBlogshop',
    component: Blogshop
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/blogshop/:id',
    name: 'Blogshop',
    component: Blogshop
  },

  {
    path: '/createblogshop',
    name: 'CreateBlogshop',
    component: CreateBlogshop
  },

  {
    path: '/newidea',
    name: 'NewIdea',
    component: NewIdea
  },
  {
    path: '/newproduct',
    name: 'NewProduct',
    component: NewProduct
  },
  {
    path: '/product-edit/:id',
    name: 'EditProduct',
    component: NewProductLink
  },
  {
    path: '/product-not-found',
    name: 'ProductNotFound',
    component: ProductNotFound
  },
  {
    path: '/newproductlink',
    name: 'NewProductLink',
    component: NewProductLink
  },
  {
    path: '/product/:id',
    name: 'ProductPage',
    component: ProductPage
  },
  {
    path: '/newtip',
    name: 'NewTip',
    component: NewTip
  },
  {
    path: '/tip-edit/:id',
    name: 'EditTip',
    component: NewTip
  },
  {
    path: '/tip/:id',
    name: 'TipPage',
    component: TipPage
  },
  {
    path: '/newpacket',
    name: 'NewPacket',
    component: NewPacket
  },
  {
    path: '/packet-edit/:id',
    name: 'EditPacket',
    component: NewPacket
  },
  {
    path: '/packet/:id',
    name: 'PacketPage',
    component: PacketPage
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/aboutblogolino',
    name: 'AboutBlogolino',
    component: AboutBlogolino
  },

  {
    path: '/settingsaccount',
    name: 'SettingsAccount',
    component: SettingsAccount
  },
  {
    path: '/settingsblogshop',
    name: 'SettingsBlogshop',
    component: SettingsBlogshop
  },
  {
    path: '/settingsprofile',
    name: 'SettingsProfile',
    component: SettingsProfile
  },
  {
    path: '/settingsprivacy',
    name: 'SettingsPrivacy',
    component: SettingsPrivacy
  },
  {
    path: '/settingsmail',
    name: 'SettingsMail',
    component: SettingsMail
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages
  },
  {
    path: '/writemessage/:receiverId',
    name: 'WriteMessage',
    component: WriteMessage
  },
  {
    path: '/superadmin/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/superadmin/dashboard-warnings',
    name: 'DashboardWarnings',
    component: DashboardWarnings
  },
  {
    path: '/superadmin/dashboard-users',
    name: 'DashboardUsers',
    component: DashboardUsers
  },
  {
    path: '/superadmin/dashboard-blogshops',
    name: 'DashboardBlogshops',
    component: DashboardBlogshops
  },
  {
    path: '/superadmin/dashboard-products',
    name: 'DashboardProducts',
    component: DashboardProducts
  },
  {
    path: '/superadmin/dashboard-tips',
    name: 'DashboardTips',
    component: DashboardTips
  },
  {
    path: '/superadmin/dashboard-packets',
    name: 'DashboardPackets',
    component: DashboardPackets
  },
  {
    path: '/superadmin/dashboard-partners',
    name: 'DashboardPartners',
    component: DashboardPartners
  },
  {
    path: '/superadmin/dashboard-messages',
    name: 'DashboardMessages',
    component: DashboardMessages
  },
  {
    path: '/superadmin/dashboard-newsletter',
    name: 'DashboardNewsletter',
    component: DashboardNewsletter
  },
  {
    path: '/superadmin/dashboard-feedback',
    name: 'DashboardFeedback',
    component: DashboardFeedback
  },
  {
    path: '/superadmin/dashboard-bonus',
    name: 'DashboardBonus',
    component: DashboardBonus
  },
  {
    path: '/addtolist',
    name: 'AddToList',
    component: AddToList
  },
  {
    path: '/addnewlist',
    name: 'AddNewList',
    component: AddNewList
  },
  {
    path: '/list-edit/:id',
    name: 'EditList',
    component: AddNewList
  },
  {
    path: '/sharelist/:id/:listId',
    name: 'ShareList',
    component: ShareList
  },
  {
    path: '/writesupportmessage',
    name: 'WriteSupportMessage',
    component: WriteSupportMessage
  },
  {
    path: '/partner',
    name: 'Partner',
    component: Partner
  },
  {
    path: '/agbs',
    name: 'AGBs',
    component: AGBs
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
  },
  {
    path: '/feedback/:id',
    name: 'FeedbackFilled',
    component: Feedback
  },
  {
    path: '/verification',
    name: 'Verification',
    component: Verification
  },

  {
    path: '/about',
    name: 'About',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
];
/*
const scrollBehavior: RouterScrollBehavior = () => {
  return {x: 0, y: 0}
}*/

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //scrollBehavior
  scrollBehavior() {
    return { top: 0 };
  }
});

router.beforeEach(function(to, from, next) {
  if (to.name !== 'Verification' && firebase.auth().currentUser && !firebase.auth().currentUser?.emailVerified) {
    router.push({ name: 'Verification' });
    return;
  }
  next();
});

export default router;
