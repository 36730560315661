<template>
  <img id="family-picture" src="@/assets/family.png" class="center" style="width: 90%; max-width: 400px" />
  <div class="text-bold" style="margin: 20px">Bitte bestätige deinen E-Mail-Account, indem du auf den Link in der E-Mail klickst, die wir dir gerade geschickt haben.</div>
  <div style="margin: 20px">
    Du hast keine Mail erhalten? Schau zuerst in deinen Spam Ordner. Sonst versuche es erneut oder kontaktiere unseren Support unter info@blogolino.de
  </div>
</template>

<style>
.link {
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
}
</style>
