
/* eslint-disable @typescript-eslint/no-explicit-any*/
import _ from 'lodash';
import { Component } from 'vue';
import { Vue, Options } from 'vue-class-component';
import app from '@/main';
import { useStore } from '@/store';
import * as blogolinoData from '@/blogolinoData.json';
import Editor from 'primevue/editor';
// Import the editor default configuration
import { getEditorDefaults } from 'pintura';
// Import the component from `vue-pintura`
// pintura
/* eslint-disable */
import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  locale_en_gb,
  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  plugin_sticker,
  plugin_sticker_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  // filepond
  openEditor,
  processImage,
  createDefaultImageOrienter,
  legacyDataToImageState
} from 'pintura';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEditor from 'filepond-plugin-image-editor';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate, plugin_sticker);

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImageEditor, FilePondPluginFilePoster) as Component;

@Options({
  components: {
    FilePond,
    Editor
  }
})
export default class NewProductLink extends Vue {
  public error = false;
  public waitingWhileSaving = false;
  public myFiles: [any?] = [];
  public selectedCategory = null;
  public selectedSubcategory = null;
  public editorDefaults = getEditorDefaults();
  // eslint-disable-next-line
  public categories: any = [];
  public ages: any = [];
  public selectedAge: any = [];
  public title = '';
  public shopName = '';
  public price = 0;
  public longText = '';
  public keyPoint1 = '';
  public keyPoint2 = '';
  public keyPoint3 = '';
  public files: [File?] = [];
  public imageURLs: [string?] = [];
  public store = useStore();
  public productId;
  public productIdExists = false;
  public userId = '';
  public asin = '';
  public ean = '';
  public productLink = '';
  public validationErrors = {};
  public blogshopDoc: any = {};
  public countFavorite = 0;
  public submitted = false;
  public images: [unknown?] = [];
  public imageSrc = null;
  public myEditor = {
    // map legacy data objects to new imageState objects
    legacyDataToImageState: legacyDataToImageState,

    // used to create the editor, receives editor configuration, should return an editor instance
    createEditor: openEditor,

    // Required, used for reading the image data
    imageReader: [createDefaultImageReader],

    // optionally. can leave out when not generating a preview thumbnail and/or output image
    imageWriter: [
      createDefaultImageWriter,
      {
        // We'll resize images to fit a 512 × 512 square
        targetSize: {
          width: 800,
          height: 800
        }
      }
    ],

    // used to generate poster images, runs an editor in the background
    imageProcessor: processImage,

    // editor options
    editorOptions: {
      stickers: [
        '/images/Avatar_1.png',
        '/images/Avatar_2.png',
        '/images/Avatar_3.png',
        '/images/Avatar_4.png',
        '/images/Avatar_5.png',
        '/images/Avatar_6.png',
        '/images/Avatar_7.png',
        '/images/Sonne.png',
        '/images/Sonne_gelb1.png',
        '/images/Sonne_gelb2.png',
        '/images/Blume2.png',
        '/images/Blume2_bunt.png',
        '/images/Blume2_bunt2.png',
        '/images/Blumen.png',
        '/images/Blumen_bunt.png',
        '/images/Blumen_bunt2.png',
        '/images/Herz.png',
        '/images/Herz_bunt1.png',
        '/images/Herz_bunt2.png',
        '/images/Herz2.png',
        '/images/Herz2_bunt1.png',
        '/images/Herz2_bunt2.png',
        '/images/Herz3.png',
        '/images/Herz3_bunt1.png',
        '/images/Herz3_bunt2.png',
        '/images/Herz4.png',
        '/images/Herz4_bunt1.png',
        '/images/Herz4_bunt2.png'
      ],
      stickerEnableSelectImage: false,
      imageOrienter: createDefaultImageOrienter(),
      utils: ['crop', 'filter', 'finetune', 'annotate', 'sticker'],
      ...plugin_finetune_defaults,
      ...plugin_filter_defaults,
      ...markup_editor_defaults,
      locale: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...plugin_filter_locale_en_gb,
        ...plugin_annotate_locale_en_gb,
        ...plugin_sticker_locale_en_gb,
        ...markup_editor_locale_en_gb
      }
    }
  };
  public setGallery(fileId) {
    const elements = document.getElementsByClassName('gallery-image');
    for (let element of elements) {
      element.classList.remove('gallery-image');
    }
    const firstFileElement = document.getElementById('filepond--item-' + fileId);
    firstFileElement?.classList.add('gallery-image');
  }
  public swapArray(arr, indexA, indexB) {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
  }
  public onreorderfiles(files) {
    const firstFile = files[0];
    this.setGallery(firstFile.id);
    const sorted = _.sortBy(this.myFiles, x => _.findIndex(files, y => x.id === y.id));
    files.forEach((item, i) => {
      const index = _.findIndex(files, y => item.id === y.id);
      this.swapArray(this.myFiles, index, i);
    });
  }
  public addFile(event) {
    setTimeout(() => {
      this.setGallery(event.id);
    }, 600);
    this.myFiles.push(event.file);
  }

  public async imageEditorAfterWriteImage(res) {
    res.src.new = res.dest;
    return res.dest;
  }

  public async created() {
    this.loadNewProductLink();
    setTimeout(() => {
      if (document.getElementsByClassName('filepond--browser')[0]) {
        document.getElementsByClassName('filepond--browser')[0].removeAttribute('capture');
      }
    }, 500);
  }
  public async loadNewProductLink() {
    const productId = this.$router.currentRoute.value.params.id;
    if (productId) {
      this.productIdExists = true;
      this.productId = productId;
      const productRef = await app.config.globalProperties.$firestore
        .collection('products')
        .doc(productId)
        .get();
      if (productRef.exists && !this.$router.currentRoute.value?.params?.amazonProduct) {
        const product = productRef.data();
        this.title = product.title;
        this.price = product.price;
        this.longText = product.longText;
        this.keyPoint1 = product.keyPoint1;
        this.keyPoint2 = product.keyPoint2;
        this.keyPoint3 = product.keyPoint3;
        this.shopName = product.shopName;
        this.selectedCategory = product.selectedCategory;
        this.selectedSubcategory = product.selectedSubcategory;
        this.selectedAge = product.selectedAge;
        this.productLink = product.productLink;
        this.countFavorite = product.countFavorite;

        product?.imageUrls?.forEach(url => {
          // eslint-disable-next-line no-unreachable
          const imageItem = {
            itemImageSrc: url,
            thumbnailImageSrc: url,
            alt: 'Beschreibung'
          };
          this.images.push(imageItem);
          this.imageSrc = product.imageUrls[0] ? product.imageUrls[0] : null;
        });
      } else if (productRef.exists && this.$router.currentRoute.value?.params?.amazonProduct) {
        const productA = JSON.parse(this.$router.currentRoute.value?.params?.amazonProduct as string);
        this.productLink = productA.url;
        this.asin = productA.asin;
        this.ean = productA.ean;
        this.shopName = 'Amazon';
        this.price = productA.price.current_price;
        if (this.price > 299) {
          this.price = this.price / 100;
        }

        const product = productRef.data();
        this.longText = product.longText;
        this.keyPoint1 = product.keyPoint1;
        this.keyPoint2 = product.keyPoint2;
        this.keyPoint3 = product.keyPoint3;
        this.selectedCategory = product.selectedCategory;
        this.selectedSubcategory = product.selectedSubcategory;
        this.selectedAge = product.selectedAge;
        this.countFavorite = product.countFavorite;

        product?.imageUrls?.forEach(url => {
          // eslint-disable-next-line no-unreachable
          const imageItem = {
            itemImageSrc: url,
            thumbnailImageSrc: url,
            alt: 'Beschreibung'
          };
          this.images.push(imageItem);
          this.imageSrc = product.imageUrls[0] ? product.imageUrls[0] : null;
        });
      }
    } else if (this.$router.currentRoute.value?.params?.amazonProduct) {
      // eslint-disable-next-line
      const product = JSON.parse(this.$router.currentRoute.value?.params?.amazonProduct as string);
      this.productLink = product.url;
      this.asin = product.asin;

      this.shopName = 'Amazon';
      this.price = product.price.current_price;
      if (this.price > 299) {
        this.price = this.price / 100;
      }
    }

    this.categories = blogolinoData.categories;
    this.ages = blogolinoData.ages;

    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .get();
    this.blogshopDoc = blogshopRef.data();
  }

  public selectedFiles(event) {
    event.files.forEach(file => {
      this.files.push(file);
    });
  }

  public checkUrl() {
    this.productLink = this.productLink.replace('http://', 'https://');
    const prefix = 'https://';
    if (this.productLink.substr(0, prefix.length) !== prefix) {
      this.productLink = prefix + this.productLink;
    }
    this.productLink = this.productLink.replace('//ww.', '//www.');
  }

  public agesSelectChanged(event) {
    if (event.value.includes('FJA')) {
      this.selectedAge = ['FJA'];
    } else {
      this.selectedAge = event.value;
    }
  }

  validateForm() {
    if (!this.productLink.trim()) this.validationErrors['link'] = true;
    else delete this.validationErrors['link'];

    return !Object.keys(this.validationErrors).length;
  }
  public async uploadImages(productId) {
    const storageRef = app.config.globalProperties.$storage.ref();
    // @ts-ignore
    this.myFiles = this.myFiles.reverse();
    const result = await Promise.all(
      // @ts-ignore
      this.$refs.pond.getFiles().map(async item => {
        let file;
        file = item.file;
        const fileName = this.uuidv4();
        const productImagesRef = storageRef.child(`images/${productId}/${fileName}`);
        const metadata = {
          contentType: file.new.type
        };
        // eslint-disable-next-line
        const blob = await fetch(item.getMetadata().poster).then(r => r.blob());
        const snapshot = await productImagesRef.put(file.new, metadata);
        const downloadURL = await snapshot.ref.getDownloadURL();
        //scope.imageURLs.push(downloadURL);
        item.imageURL = downloadURL;
      })
    );
    this.imageURLs = [];
    // @ts-ignore
    for (const item of this.$refs.pond.getFiles()) {
      this.imageURLs.push(item.imageURL);
    }
    return result;
  }

  public uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  public getSubCategories(code) {
    for (const category of blogolinoData.categories) {
      if (category.code === code) {
        return category.subcategories;
      }
    }
  }
  public async renewImages() {
    const imageUrls = app.config.globalProperties.$firebase.firestore.FieldValue.delete();
    await app.config.globalProperties.$firestore
      .collection('products')
      .doc(this.productId)
      .update({ imageUrls });
    this.imageSrc = null;
    this.loadNewProductLink();
  }

  public async saveProduct() {
    this.submitted = true;
    this.waitingWhileSaving = true;
    if (
      !this.price ||
      !this.keyPoint1 ||
      !this.keyPoint2 ||
      !this.productLink ||
      !this.title ||
      !this.selectedCategory ||
      (this.selectedCategory != 'ALL' && this.selectedCategory != 'WEI' && !this.selectedSubcategory) ||
      this.selectedAge.length === 0
    ) {
      this.error = true;
      this.validationErrors['productLink'] = !this.productLink;
      this.validationErrors['title'] = !this.title;
      this.validationErrors['selectedCategory'] = !this.selectedCategory;
      this.validationErrors['selectedSubcategory'] = !this.selectedSubcategory;
      this.validationErrors['keyPoint1'] = !this.keyPoint1;
      this.validationErrors['keyPoint2'] = !this.keyPoint2;
      this.validationErrors['price'] = !this.price;

      this.validationErrors['selectedAge'] = this.selectedAge.length === 0;
      return;
    }
    if (this.selectedCategory == 'BUE') {
      this.ean = this.asin;
    }
    const newProduct: any = {
      title: this.title,
      shopName: this.shopName,
      selectedCategory: this.selectedCategory,
      selectedSubcategory: this.selectedSubcategory,
      selectedAge: this.selectedAge,
      price: this.price,
      keyPoint1: this.keyPoint1,
      keyPoint2: this.keyPoint2,
      keyPoint3: this.keyPoint3,
      longText: this.longText,
      asin: this.asin,
      ean: this.ean,
      userId: this.user.uid,
      productLink: this.productLink,
      createdAt: new Date(),
      countFavorite: this.countFavorite
    };

    Object.keys(newProduct).forEach(key => (newProduct[key] === undefined || newProduct[key] === '' ? delete newProduct[key] : {}));

    if (newProduct.selectedCategory && newProduct.selectedCategory.subcategories) {
      delete newProduct.selectedCategory.subcategories;
    }

    if (this.productId) {
      await app.config.globalProperties.$firestore
        .collection('products')
        .doc(this.productId)
        .set(newProduct, { merge: true });
      if (this.myFiles && this.myFiles.length > 0) {
        await this.uploadImages(this.productId);
        app.config.globalProperties.$firestore
          .collection('products')
          .doc(this.productId)
          .set({ imageUrls: this.imageURLs }, { merge: true });
      }

      this.$router.push({ name: 'ProductPage', params: { id: this.productId } });
    } else {
      app.config.globalProperties.$firestore
        .collection('products')
        .add(newProduct)
        .then(async docRef => {
          if (this.myFiles && this.myFiles.length > 0) {
            await this.uploadImages(docRef.id);
            console.log(docRef.id);
            app.config.globalProperties.$firestore
              .collection('products')
              .doc(docRef.id)
              .set({ imageUrls: this.imageURLs }, { merge: true });
          }

          const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(1);
          await app.config.globalProperties.$firestore
            .collection('blogshops')
            .doc(this.user.uid)
            .update({ countProducts: increment });

          this.$router.push({ name: 'ProductPage', params: { id: docRef.id } });
          this.$toast.add({
            severity: 'success',
            summary: 'Produkt gespeichert',
            detail: 'Dein Produkt wurde erfolgreich gespeichert',
            life: 3000
          });
        })
        .catch(function(error) {
          console.error('Error adding document: ', error);
        });
    }
  }
}
