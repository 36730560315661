<template>
  <div class="text-bold">Impressum</div>
  <div>
    <img style="max-width: 350px;margin-left: 0px;margin-top:20px;" src="@/assets/peiliUg.png" id="impressum-foto" />
    <div v-if="false">
      <div class="text-bold-sm" style="margin-top: 50px">Peili UG (haftungsbeschränkt)</div>

      <div class="text-normal" style="margin-top: 10px">Straßbergerstraße 47</div>
      <div class="text-normal">80809 München</div>
      <div class="text-normal">Deutschland</div>

      <div class="text-normal" style="margin-top: 10px">Mail: info@blogolino.de</div>

      <div class="text-normal" style="margin-top: 10px">Geschäftsführer: Philipp Seeser</div>

      <div class="text-normal" style="margin-top: 10px">Handelsregister: Amtsgericht München, HRB 249907</div>

      <div class="text-normal">Umsatzsteuer-Identifikationsnummer(n): DE326357501</div>

      <div class="text-normal" style="margin-top: 10px">Verantwortlich gemäß § 18 MStV:</div>
      <div class="text-normal">Franziska Seeser</div>
      <div class="text-normal">Straßbergerstraße 47</div>
      <div class="text-normal">80809 München</div>
    </div>

    <div class="text-normal" style="margin-top: 30px">
      Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden https://ec.europa.eu/consumers/odr/ [https://ec.europa.eu/consumers/odr/]. Zur Teilnahme an einem
      Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
    </div>

    <div class="text-normal" style="margin-top: 30px">Impressum erstellt mit dem Trusted Shops [https://legal.trustedshops.com/] Rechtstexter in Kooperation mit FÖHLISCH Rechtsanwälte [https://foehlisch.com].</div>
  </div>
</template>
