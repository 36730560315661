
import { Vue } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';

Vue.registerHooks(['beforeRouteEnter']);

export default class SidebarProfile extends Vue {
  public store = useStore();
  public blogshopDocument;
  public visibleRight = false;
  public showBlogShop = false;
  public unreadMessage = false;
  public messageSubscriber;

  beforeUnmount() {
    if (this.messageSubscriber) {
      this.messageSubscriber();
    }
  }

  async created() {
    if (this.user) {
      const blogshopRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .get();
      if (blogshopRef && blogshopRef.exists) {
        this.blogshopDocument = blogshopRef.data();
        if (this.blogshopDocument.blogshopExists) {
          this.blogshopDocument.id = blogshopRef.id;
          this.showBlogShop = true;
        }
      }
      const queryChats = app.config.globalProperties.$firestore.collection('chats').where('unreadMessage', 'array-contains', this.user.uid);
      this.messageSubscriber = queryChats.onSnapshot(chatRef => {
        this.unreadMessage = false;
        chatRef.docs.forEach(ref => {
          if (ref.data().unreadMessage == this.user.uid) {
            this.unreadMessage = true;
          }
        });
      });
    }
  }

  public beforeRouteEnter(to, from, next) {
    next();
  }
  public beforeRouteLeave(to, from, next) {
    next();
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  public logout() {
    app.config.globalProperties.$firebase.auth().signOut();
    this.store.commit('setUser', null);
    this.$router.push({ name: 'Home' });
    this.visibleRight = false;
  }
}
