
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue } from 'vue-class-component';
import { useStore } from '@/store';
import 'firebase/auth';
import * as blogolinoData from '@/blogolinoData.json';

export default class SidebarCategory extends Vue {
  public visibleRight = false;
  public store = useStore();
  public showProducts = false;
  public showTips = false;
  public showPackets = false;
  public showProductsSubcategories = false;
  public categories: any = [];
  public currentCategory;
  public subcategories: any = [];
  public tips: any = [];
  public packets: any = [];

  public async created() {
    this.categories = blogolinoData.categories;
    this.tips = blogolinoData.tips;
    this.packets = blogolinoData.packets;
  }
  public async reset() {
    this.currentCategory = null;
    this.showTips = false;
    this.showPackets = false;
    this.showProductsSubcategories = false;
  }

  get user() {
    return this.store.state.user;
  }

  public openSubcategory(subcategory, type?) {
    this.$router.push({
      name: 'Search',
      query: { category: this.currentCategory.code, subcategory: subcategory.code, type: type }
    });
    this.visibleRight = false;
  }
  public openCategory(category, type?) {
    this.$router.push({ name: 'Search', query: { category: category.code, type: type } });
    this.visibleRight = false;
  }
  public showSubcategory(category) {
    if (category.code === 'ALL') {
      this.$router.push({ name: 'Search', query: { category: category.code, type: 'products' } });
      this.visibleRight = false;
    } else {
      this.subcategories = category.subcategories;
      this.currentCategory = category;
      this.showProducts = false;
      this.showProductsSubcategories = true;
    }
  }
  public toggleProducts() {
    this.showProducts = !this.showProducts;
  }
  public goToProducts() {
    this.showProductsSubcategories = false;
    this.showProducts = true;
  }
  public toggleTips() {
    this.showTips = !this.showTips;
  }
  public togglePackets() {
    this.showPackets = !this.showPackets;
  }
}
