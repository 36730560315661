
import { Vue } from 'vue-class-component';
import { useStore } from '@/store';

export default class Footer extends Vue {
  public store = useStore();

  public contactSupport() {
    this.$router.push({
      name: 'WriteMessage',
      params: { receiverId: 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2' },
      query: { itemId: 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2', type: 'blogshops' }
    });
  }
  public goToFacebook() {
    const url = 'https://www.facebook.com/blogolino';
    window.open(url, '_blank');
  }
  public goToTwitter() {
    const url = 'https://www.twitter.com/blogolino_de';
    window.open(url, '_blank');
  }
  public goToPinterest() {
    const url = 'https://www.pinterest.com/blogolino';
    window.open(url, '_blank');
  }
  public goToInstagram() {
    const url = 'https://www.instagram.com/blogolino.de';
    window.open(url, '_blank');
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
}
