
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';
import ProductGrid from '@/components/ProductGrid.vue'; // @ is an alias to /src
import app from '@/main';

@Options({
  components: {
    ProductGrid
  }
})
export default class ShareList extends Vue {
  public store = useStore();
  public imageSrc = null;
  public myList: [unknown?] = [];
  public listId;
  public listOwner;
  public blogshopDoc: any = {};
  public listDoc: any = {};

  async created() {
    const listId = this.$router.currentRoute.value.params.listId;
    if (listId) {
      this.listId = listId;
    }
    const listOwner = this.$router.currentRoute.value.params.id;
    if (listOwner) {
      this.listOwner = listOwner;
    }
    console.log(listId, listOwner);
    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(listOwner)
      .get();
    if (blogshopRef && blogshopRef.exists) {
      this.blogshopDoc = blogshopRef.data();
      // eslint-disable-next-line
      this.blogshopDoc.id = blogshopRef.id;
      this.imageSrc = this.blogshopDoc?.imageUrls ? this.blogshopDoc.imageUrls[0] : null;
      console.log(this.imageSrc);
    }
    if (listId) {
      const listDoc = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(listOwner)
        .collection('lists')
        .doc(listId)
        .get();
      this.listDoc = listDoc.data();
    }
  }

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
}
