
import { Options, Vue } from 'vue-class-component';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src
import ChatGrid from '@/components/ChatGrid.vue';

@Options({
  components: {
    DashboardMenu,
    ChatGrid
  }
})
export default class DashboardMessages extends Vue {}
