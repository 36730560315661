
import { Options, Vue } from 'vue-class-component';
import app from '@/main';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src
import { FilterMatchMode } from 'primevue/api';

@Options({
  components: {
    DashboardMenu
  }
})
export default class DashboardTips extends Vue {
  public tipList: [unknown?] = [];
  // eslint-disable-next-line
  public filters: any = null;

  initFilters() {
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
  }

  public openTipPage(data) {
    console.log(data);
    this.$router.push({ name: 'TipPage', params: { id: data.id } });
  }
  async created() {
    this.initFilters();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const tips = await app.config.globalProperties.$firestore.collection('tips').get();
    tips.forEach(item => {
      const tipItem = item.data();
      tipItem.id = item.id;
      tipItem.createdAt = item.data().createdAt
        ? item
            .data()
            .createdAt.toDate()
            .toLocaleDateString('en-CA', options)
        : null;
      this.tipList.push(tipItem);
    });
  }
}
