
import { Options, Vue } from 'vue-class-component';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src
import app from '@/main';
import Chart from 'primevue/chart';

@Options({
  components: {
    DashboardMenu,
    Chart
  }
})
export default class Dashboard extends Vue {
  public userList: [unknown?] = [];

  public async created() {
    const users = await app.config.globalProperties.$firestore.collection('users').get();
    users.forEach(item => {
      const userItem = item.data();
      userItem.id = item.id;
      this.userList.push(userItem);
    });
  }
}
