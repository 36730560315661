
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import ProductGrid from '@/components/ProductGrid.vue'; // @ is an alias to /src
import app from '@/main';

@Options({
  components: {
    ProductGrid
  }
})
export default class Home extends Vue {
  public store = useStore();
  public blogshopDocument;
  public showBlogShop = false;
  public userName = '';

  async created() {
    if (this.user) {
      const blogshopRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .get();
      if (blogshopRef && blogshopRef.exists) {
        this.blogshopDocument = blogshopRef.data();
        this.userName = this.blogshopDocument.name;
        if (this.blogshopDocument.blogshopExists) {
          this.blogshopDocument.id = blogshopRef.id;
          this.showBlogShop = true;
        }
      }
    }
  }
  public goToSearch(type) {
    this.$router.push({ name: 'Search', query: { type: type } });
  }

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
}
