
import { Vue, Options } from 'vue-class-component';
import SettingsMenu from '@/components/SettingsMenu.vue'; // @ is an alias to /src

@Options({
  components: {
    SettingsMenu
  }
})
export default class SettingsPrivacy extends Vue {}
