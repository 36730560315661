
import { Options, Vue } from 'vue-class-component';
import app from '@/main';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src
import { FilterMatchMode } from 'primevue/api';

@Options({
  components: {
    DashboardMenu
  }
})
export default class DashboardUsers extends Vue {
  public userList: [unknown?] = [];
  // eslint-disable-next-line
  public filters: any = null;

  async created() {
    this.initFilters();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const users = await app.config.globalProperties.$firestore.collection('users').get();
    users.forEach(item => {
      const userItem = item.data();
      userItem.id = item.id;
      userItem.createdAt = item.data().createdAt
        ? item
            .data()
            .createdAt.toDate()
            .toLocaleDateString('en-CA', options)
        : null;
      this.userList.push(userItem);
    });
  }

  public openSettingsProfile(data) {
    console.log(data);
    this.$router.push({ name: 'SettingsProfile', params: { id: data.id } });
  }

  initFilters() {
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
  }
}
