import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/auth';
/* eslint-disable @typescript-eslint/no-explicit-any*/

export interface State {
  count: number;
  user?: firebase.auth.UserCredential;
  blogshopDocument: unknown;
  userDocument: unknown;
  unreadMessages: number;
  claims?: any;
  searchFilter: unknown;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    count: 0,
    user: undefined,
    claims: null,
    unreadMessages: 0,
    userDocument: null,
    blogshopDocument: null,
    searchFilter: {}
  },
  mutations: {
    setUser(state, user) {
      // mutate state
      state.user = user;
    },
    setUserDocument(state, userDocument) {
      // mutate state
      state.userDocument = userDocument;
    },
    setUnreadMessages(state, numberOfMessage) {
      // mutate state
      state.unreadMessages = numberOfMessage;
    },
    setCustomClaims(state, claims) {
      // mutate state
      state.claims = claims;
    },
    setBlogshopDocument(state, blogshopDocument) {
      // mutate state
      state.blogshopDocument = blogshopDocument;
    },
    setSearchFilter(state, filter) {
      // mutate state
      state.searchFilter = { ...filter };
    }
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getClaims(state) {
      return state.claims;
    },
    getUnreadMessages(state) {
      return state.unreadMessages;
    },
    getSearchFilter(state) {
      return state.searchFilter;
    },
    getUserDocument(state) {
      return state.userDocument;
    },
    getBlogshopDocument(state) {
      return state.blogshopDocument;
    }
  }
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}
