
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue } from 'vue-class-component';
import app from '@/main';
import { useStore } from '@/store';

export default class AddToList extends Vue {
  public selectedList: any = null;
  public store = useStore();
  public lists: any = [];
  public listHeader = '';
  public itemIdExists = false;
  public itemId = '';
  public listIdExists = false;
  public listId;
  public itemType;

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }

  public async created() {
    const type = this.$router.currentRoute.value.params.type;
    const itemId = this.$router.currentRoute.value.params.itemId;

    if (itemId) {
      this.itemIdExists = true;
      this.itemId = String(itemId);
      this.itemType = type;
    }
    const listId = this.$router.currentRoute.value.params.listId;
    if (listId) {
      this.listIdExists = true;
      this.listId = String(listId);
      const listRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .doc(listId)
        .get();

      if (listRef.exists) {
        const list = listRef.data();
        this.listHeader = list.listHeader;
      }
    }
    console.log(this.listIdExists, this.itemIdExists);
  }
  public async saveItemToList(itemId) {
    const newListHeader: any = {
      listHeader: this.listHeader,
      createdAt: new Date()
    };
    const newListItem: any = {
      type: this.itemType,
      createdAt: new Date()
    };
    const headerRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .add(newListHeader)
      .catch(function(error) {
        console.error('Error adding document: ', error);
      });

    app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .doc(headerRef.id)
      .collection('items')
      .doc(itemId)
      .set(newListItem, { merge: true });
    this.$router.push({ name: 'ProductPage', params: { id: this.itemId } });
    this.$toast.add({ severity: 'success', summary: 'Produkt hinzugefügt', detail: 'Das Produkt wurde deiner Liste hinzugefügt', life: 3000 });
  }

  public async saveList() {
    // eslint-disable-next-line
    const newListHeader: any = {
      listHeader: this.listHeader,
      createdAt: new Date()
    };
    if (this.listId) {
      await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .doc(this.listId)
        .set(newListHeader, { merge: true });
      this.$router.push({ name: 'Profile', params: { id: this.user.uid, listActive: 1 } });
      this.$toast.add({ severity: 'success', summary: 'Liste geändert', detail: 'Die Liste wurde erfolgreich geändert', life: 3000 });
    } else {
      app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .add(newListHeader)
        .catch(function(error) {
          console.error('Error adding document: ', error);
        });
      this.$router.push({ name: 'Profile', params: { id: this.user.uid, listActive: 1 } });
      this.$toast.add({ severity: 'success', summary: 'Liste anlegen', detail: 'Die Liste wurde erfolgreich angelegt', life: 3000 });
    }
  }
}
