
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';

import ProductGrid from '@/components/ProductGrid.vue'; // @ is an alias to /src
import app from '@/main';
import { useConfirm } from 'primevue/useconfirm';

@Options({
  components: {
    ProductGrid
  },
  watch: {
    $route() {
      console.log('changed');
      this.loadProfile();
    }
  }
})
export default class Profile extends Vue {
  public store = useStore();
  public showBlogShop = false;
  public listHeader = '';
  public myList: [unknown?] = [];
  public favoriteProductsEmpty = true;
  public favoriteTipsEmpty = true;
  public favoritePacketsEmpty = true;
  public favoriteBlogshopsEmpty = true;
  public favoriteExists = false;
  public listExists = false;
  public listItemExists = false;
  public ariaSelected;
  public confirm;
  public imageSrc = null;
  public active = 0;
  public blogshopDocument: any = null;

  async created() {
    this.confirm = useConfirm();
    this.loadProfile();
  }
  public async confirmDeleteList(headerId) {
    this.confirm.require({
      message: 'Bist du sicher, dass du die Liste löschen möchtest? Es werden dann auch alle gemerkten Produkte gelöscht',
      header: 'Liste löschen',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.deleteList(headerId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }

  public changeActiveIndex() {
    this.active = 5;
  }
  public async deleteList(listId) {
    // Delete all items in the list first
    const snapshot = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .doc(listId)
      .collection('items')
      .get();

    await Promise.all(snapshot.docs.map(doc => doc.ref.delete()));
    // Delewte entire list
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .doc(listId)
      .delete();

    this.loadProfile();
    this.$toast.add({ severity: 'success', summary: 'Liste gelöscht', detail: 'Deine Liste wurde erfolgreich gelöscht', life: 3000 });
  }
  public editList(listId) {
    this.$router.push({ name: 'AddNewList', params: { listId: listId } });
  }
  public async loadProfile() {
    const activeList = this.$router.currentRoute.value.params.listActive;
    if (activeList) {
      this.changeActiveIndex();
    }

    if (this.user) {
      const blogshopRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .get();
      if (blogshopRef && blogshopRef.exists) {
        this.blogshopDocument = blogshopRef.data();
        if (this.blogshopDocument.blogshopExists) {
          this.blogshopDocument.id = blogshopRef.id;
          this.showBlogShop = true;
          this.imageSrc = this.blogshopDocument?.imageUrls ? this.blogshopDocument.imageUrls[0] : null;
          console.log(this.imageSrc);
        }
      }
      const listRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .get();

      if (listRef) {
        this.listExists = true;

        this.myList = [];
        listRef.docs.forEach(item => {
          const listItem = item.data();
          listItem.id = item.id;
          this.myList.push(listItem);
          const itemRef = app.config.globalProperties.$firestore
            .collection('blogshops')
            .doc(this.user.uid)
            .collection('lists')
            .doc(listItem.id)
            .collection('items')
            .get();
          if (itemRef) {
            this.listItemExists = true;
          }
        });
      }
      const favoriteRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('favorites')
        .get();

      if (favoriteRef && blogshopRef.exists) {
        favoriteRef.docs.forEach(document => {
          if (document.data().type === 'product') {
            this.favoriteProductsEmpty = false;
          }
          if (document.data().type === 'tip') {
            this.favoriteTipsEmpty = false;
          }
          if (document.data().type === 'packet') {
            this.favoritePacketsEmpty = false;
          }
          if (document.data().type === 'blogshop') {
            this.favoriteBlogshopsEmpty = false;
          }
          if (this.favoriteProductsEmpty == true || this.favoritePacketsEmpty == true || this.favoriteBlogshopsEmpty == true) {
            this.favoriteExists = true;
          }
        });
      }
    }
  }
  public copyShareLink(listId) {
    const userId = this.user.uid;

    const link = location.protocol + '//' + location.host + '/sharelist/' + userId + '/' + listId;
    // eslint-disable-next-line
    const testingCodeToCopy: any = document.querySelector('#copy-clipboard-input')!;
    testingCodeToCopy.value = link;
    testingCodeToCopy.setAttribute('type', 'text');
    testingCodeToCopy.select();

    try {
      document.execCommand('copy');
      this.$toast.add({
        severity: 'success',
        summary: 'Link kopiert',
        detail: 'Der Link wurde in deine Zwischenablage kopiert',
        life: 3000
      });
      testingCodeToCopy.setAttribute('type', 'hidden');
    } catch (err) {
      alert('Oops, unable to copy');
    }
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  get userDocument() {
    return this.store.state.userDocument;
  }
  public async createNewList() {
    // eslint-disable-next-line
    const newListHeader: any = {
      listHeader: this.listHeader,
      createdAt: new Date()
    };
    app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .add(newListHeader)
      .catch(function(error) {
        console.error('Error adding document: ', error);
      });
    this.loadProfile();
  }
}
