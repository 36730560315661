
import { Vue } from 'vue-class-component';
import { useStore } from '@/store';

export default class NewIdea extends Vue {
  public store = useStore();
  get user() {
    return this.store.state.user;
  }
  get userDocument() {
    return this.store.state.userDocument;
  }
}
