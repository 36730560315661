
import { Options, Vue } from 'vue-class-component';
import Footer from '@/components/Footer.vue'; // @ is an alias to /src
import Verification from '@/views/Verification.vue'; // @ is an alias to /src
import SidebarMenu from '@/components/SidebarMenu.vue'; // @ is an alias to /src
import SearchSidebar from '@/components/SearchSidebar.vue'; // @ is an alias to /src
import SidebarProfile from '@/components/SidebarProfile.vue'; // @ is an alias to /src
import { useStore } from '@/store';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';

@Options({
  components: {
    Footer,
    Verification,
    Toast,
    SidebarMenu,
    ConfirmDialog,
    SearchSidebar,
    SidebarProfile
  },
  watch: {
    $route() {
      this.checkBodyWidth();
    }
  }
})
export default class App extends Vue {
  public displayConsent = false;
  public isDev = false;

  public created() {
    this.isDev = process.env.VUE_APP_FIREBASE_PROJECT === 'blogolino-dev';
    const cookieAccepted = localStorage.getItem('cookie-accepted');
    if (!cookieAccepted) {
      this.displayConsent = true;
    }
  }


  public acceptCookie() {
    localStorage.setItem('cookie-accepted', 'true');
    this.displayConsent = false;
  }

  get hideMenu() {
    return (
      this.$route.name === 'Login' ||
      this.$route.name === 'Register' ||
      this.$route.name === 'CreateBlogshop' ||
      this.$route.name === 'NewIdea' ||
      this.$route.name === 'NewProduct' ||
      this.$route.name === 'NewProduct2' ||
      this.$route.name === 'ProductNotFound' ||
      this.$route.name === 'NewProductLink' ||
      this.$route.name === 'NewTip' ||
      this.$route.name === 'NewPacket' ||
      this.$route.name === 'AddToList' ||
      this.$route.name === 'AddNewList' ||
      this.$route.name === 'NewFAQ'
    );
  }
  public checkBodyWidth() {
    const routeName = this.$route?.path as string;
    if (routeName.includes('superadmin')) {
      document.body.style['max-width'] = '100%';
    } else {
      document.body.style['max-width'] = '700px';
    }
  }

  public goBack() {
    this.$router.go(-1);
  }
  get hideSearch() {
    return this.$route.name === 'Search';
  }
  public store = useStore();
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  get isSuperAdmin() {
    if (this.store.state.claims) {
      return this.store.state.claims.superadmin;
    } else {
      return false;
    }
  }

  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  public beforeRouteEnter(to, from, next) {
    this.checkBodyWidth();
    next();
  }
}
