
import { useStore } from '@/store';
import { Vue, prop } from 'vue-class-component';
import app from '@/main';

class Props {
  menu = prop({
    type: String,
    required: true
  });
}

export default class SettingsMenu extends Vue.with(Props) {
  public store = useStore();
  public blogshopExists = '';

  public async created() {
    console.log(this.menu);
    if (this.user) {
      const blogShopRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .get();
      this.blogshopExists = blogShopRef.data().blogshopExists;
    }
  }

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
}
