
import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';
import * as blogolinoData from '@/blogolinoData.json';
import { useConfirm } from 'primevue/useconfirm';
import ProductGrid from '@/components/ProductGrid.vue'; // @ is an alias to /src

@Options({
  components: {
    ProductGrid
  }
})
export default class ProductPage extends Vue {
  public store = useStore();
  public productId;
  // eslint-disable-next-line
  public product: any = null;
  public images: [unknown?] = [];
  public blogshopDoc = {};
  public imageSrc = null;
  public confirm;
  public favoriteProduct = false;
  public productWarned = false;
  public productLocked = false;
  public userWarningOff = false;
  public listExists = false;
  public productOnList = false;
  public selectedList;
  public productInTip = false;
  public productInPacket = false;
  public itemList: [unknown?] = [];

  public openBlogshop() {
    this.$router.push({ name: 'Blogshop', params: { id: this.product.userId } });
  }
  public confirmDeleteFromList(productId) {
    this.confirm.require({
      message: 'Möchtest du das Produkt von deiner Liste entfernen?',
      header: 'Produkt entfernen',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.deleteProductFromList(productId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public async deleteProductFromList(productId) {
    this.productOnList = false;
    console.log(productId);
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .doc(this.selectedList)
      .collection('items')
      .doc(productId)
      .delete();
  }
  public confirmProductWarning(productId) {
    this.confirm.require({
      message: 'Möchtest du das Produkt melden, weil es verbotene Inhalte hat?',
      header: 'Produkt melden',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.productWarning(productId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  getCategoryName(selectedCategory) {
    for (const category of blogolinoData.categories) {
      if (selectedCategory === category.code) {
        return category.name;
      }
    }
  }

  public async productWarning(productId) {
    this.productWarned = true;
    const item = {
      type: 'product',
      whoSetWarning: this.user.uid,
      createdAt: new Date(),
      userWarningOff: false
    };
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(productId)
      .set(item);

    this.$toast.add({
      severity: 'success',
      summary: 'Produkt gemeldet',
      detail: 'Das Produkt wurde dem Support gemeldet!',
      life: 3000
    });
    this.$router.push({ name: 'ProductPage', params: { id: productId } });
  }
  public confirmProductLock(productId) {
    this.confirm.require({
      message: 'Möchtest du das Produkt sperren?',
      header: 'Produkt sperren',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.productLock(productId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public productLock(productId) {
    this.productLocked = true;
    // eslint-disable-next-line
    const newProduct: any = {
      locked: this.productLocked
    };
    app.config.globalProperties.$firestore
      .collection('products')
      .doc(productId)
      .set(newProduct, { merge: true });
  }
  public confirmProductWarningOff(productId) {
    this.confirm.require({
      message: 'Möchtest du das Produkt wieder entsperren?',
      header: 'Produkt entsperren',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.productWarningOff(productId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public async productWarningOff(productId) {
    this.productWarned = false;
    this.productLocked = false;
    const locked = app.config.globalProperties.$firebase.firestore.FieldValue.delete();
    await app.config.globalProperties.$firestore
      .collection('products')
      .doc(productId)
      .update({ locked });
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(productId)
      .delete();
    this.$toast.add({
      severity: 'success',
      summary: 'Produkt entsperrt',
      detail: 'Das Produkt wurde wieder entsperrt!',
      life: 3000
    });
    this.$router.push({ name: 'ProductPage', params: { id: productId } });
  }
  public async confirmProductWarningOffOnlyForUser(productId) {
    this.confirm.require({
      message: 'Möchtest du das Produkt wieder für den User entsperren?',
      header: 'Produkt für User entsperren',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.productWarningOffOnlyForUser(productId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public async productWarningOffOnlyForUser(productId) {
    this.userWarningOff = true;
    const productDocument = {
      userWarningOff: true
    };
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(productId)
      .set(productDocument, { merge: true });
    this.$toast.add({
      severity: 'success',
      summary: 'Produkt für User entsperrt',
      detail: 'Das Produkt wurde für den User wieder entsperrt!',
      life: 3000
    });
  }
  public async confirmProductFreeze(productId) {
    this.userWarningOff = false;
    const productDocument = {
      userWarningOff: false
    };
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(productId)
      .set(productDocument, { merge: true });
    this.$toast.add({
      severity: 'success',
      summary: 'Produkt wieder gesperrt',
      detail: 'Das Produkt wurde wieder gesperrt!',
      life: 3000
    });
  }

  public copyShareLink() {
    const link = location.protocol + '//' + location.host + '/product/' + this.productId;
    // eslint-disable-next-line
    const testingCodeToCopy: any = document.querySelector('#copy-clipboard-input')!;
    testingCodeToCopy.value = link;
    testingCodeToCopy.setAttribute('type', 'text');
    testingCodeToCopy.select();

    try {
      document.execCommand('copy');
      this.$toast.add({
        severity: 'success',
        summary: 'Link kopiert',
        detail: 'Der Link wurde in deine Zwischenablage kopiert',
        life: 3000
      });
      testingCodeToCopy.setAttribute('type', 'hidden');
    } catch (err) {
      alert('Oops, unable to copy');
    }
  }

  public sendMessage(userId, productId) {
    console.log(userId, productId);
    this.$router.push({
      name: 'WriteMessage',
      params: { receiverId: userId },
      query: { itemId: productId, type: 'products' }
    });
  }
  public addToList(productId) {
    if (this.listExists == true) {
      this.$router.push({
        name: 'AddToList',
        params: { itemId: productId, type: 'product' }
      });
    } else {
      this.$router.push({
        name: 'AddNewList',
        params: { itemId: productId, type: 'product' }
      });
    }
  }
  public async likeProduct(productId) {
    // eslint-disable-next-line
    if (!this.user.uid) {
      return;
    }
    this.favoriteProduct = true;
    const item = {
      type: 'product',
      createdAt: new Date()
    };
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(productId)
      .set(item);

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(1);
    await app.config.globalProperties.$firestore
      .collection('products')
      .doc(this.productId)
      .update({ countFavorite: increment });
  }
  public async dislikeProduct(productId) {
    this.favoriteProduct = false;

    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(productId)
      .delete();
    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(-1);
    await app.config.globalProperties.$firestore
      .collection('products')
      .doc(this.productId)
      .update({ countFavorite: increment });
  }

  public showSelectedAge(selectedAge) {
    let age = '';
    for (const element of selectedAge) {
      for (const item of blogolinoData.ages) {
        if (element === item.code) {
          age = age + ' ' + item.name + ',';
        }
      }
    }

    return age.slice(0, -1);
  }
  public showPrice(price) {
    return price.toFixed(2).replace('.', ',');
  }

  public async confirmDeleteProduct(productId) {
    this.confirm.require({
      message: 'Bist du sicher, dass du das Produkt löschen möchtest?',
      header: 'Produkt löschen',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.deleteProduct(productId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }

  public async deleteProduct(productId) {
    await app.config.globalProperties.$firestore
      .collection('products')
      .doc(productId)
      .delete();
    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(-1);
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .update({ countProducts: increment });

    this.$router.push({ name: 'MeinBlogshop' });
    this.$toast.add({
      severity: 'success',
      summary: 'Produkt gelöscht',
      detail: 'Dein Produkt wurde erfolgreich gelöscht',
      life: 3000
    });
  }

  public goToShop() {
    const url = this.product.productLink;
    window.open(url, '_blank', '');
  }

  public async created() {
    this.confirm = useConfirm();
    this.loadProduct();
  }

  public async loadProduct() {
    this.productId = this.$router.currentRoute.value.params.id;
    const warningItem = await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(this.productId)
      .get();

    if (warningItem && warningItem.exists) {
      this.productWarned = true;
    }
    if (warningItem && warningItem.exists && warningItem.data().userWarningOff === true) {
      this.userWarningOff = true;
    }

    const productRef = await app.config.globalProperties.$firestore
      .collection('products')
      .doc(this.productId)
      .get();
    if (productRef.exists) {
      this.product = productRef.data();
      if (this.product.locked == true) {
        this.productLocked = true;
      }
      this.product?.imageUrls?.forEach(url => {
        // eslint-disable-next-line no-unreachable
        const imageItem = {
          itemImageSrc: url,
          thumbnailImageSrc: url,
          alt: 'Beschreibung'
        };
        this.images.push(imageItem);
        this.imageSrc = this.product.imageUrls[0] ? this.product.imageUrls[0] : null;
      });
    }
    if (this.user) {
      const itemsRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .get();
      if (itemsRef) {
        this.listExists = true;
      }

      itemsRef.docs.forEach(async item => {
        const itemOnList = await app.config.globalProperties.$firestore
          .collection('blogshops')
          .doc(this.user.uid)
          .collection('lists')
          .doc(item.id)
          .collection('items')
          .doc(this.productId)
          .get();
        if (itemOnList.exists) {
          this.productOnList = true;
          this.selectedList = item.id;
        }
      });
    }

    if (this.user) {
      const favoriteItem = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('favorites')
        .doc(this.productId)
        .get();
      if (favoriteItem && favoriteItem.exists) {
        this.favoriteProduct = true;
      }
    }
    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.product.userId)
      .get();
    this.blogshopDoc = blogshopRef.data();

    const queryTips = await app.config.globalProperties.$firestore.collection('tips').where('selectedProduct1', '==', this.productId);
    const tipsRef = await queryTips.get();
    tipsRef.docs.forEach(async ref => {
      const item = ref.data();
      item.type = 'tip';
      item.id = ref.id;
      this.itemList.push(item.id);
      this.productInTip = true;
    });
    const queryTips2 = await app.config.globalProperties.$firestore.collection('tips').where('selectedProduct2', '==', this.productId);
    const tipsRef2 = await queryTips2.get();
    tipsRef2.docs.forEach(async ref => {
      const item = ref.data();
      item.type = 'tip';
      item.id = ref.id;
      this.itemList.push(item.id);
      this.productInTip = true;
    });
    const queryPackets = await app.config.globalProperties.$firestore.collection('packets').where('linkedProductsIds', 'array-contains', this.productId);
    const packetsRef = await queryPackets.get();
    packetsRef.docs.forEach(async ref => {
      const item = ref.data();
      item.type = 'packet';
      item.id = ref.id;
      this.itemList.push(item.id);
      this.productInPacket = true;
    });
    console.log(this.itemList);
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  public editItem(productId) {
    this.$router.push({ name: 'EditProduct', params: { id: productId } });
  }
  public goToLogin(productId) {
    this.$router.push({ name: 'Login', params: { id: productId, before: 'ProductPage' } });
  }
  public goToNewIdea() {
    this.$router.push({ name: 'NewIdea' });
  }
}
