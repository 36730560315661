
/* eslint-disable */
import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';
import ProductGrid from '@/components/ProductGrid.vue'; // @ is an alias to /src
import app from '@/main';
import * as blogolinoData from '@/blogolinoData.json';

@Options({
  components: {
    ProductGrid
  },
  watch: {
    $route() {
      if (this.$route.href.includes('blogshop')) {
        this.loadBlogshop();
      }
    }
  }
})
export default class Blogshop extends Vue {
  public store = useStore();
  public blogshopDoc: any = {};
  public blogshopUser;
  public userId;
  public favoriteBlogshop = false;
  public aboutMe = false;
  public imageSrc = null;
  public selectedProduct: any = null;
  public productList: [any?] = [];
  public filteredProducts: any[] = [];
  public product: any = {};
  public selectedPacket: any = null;
  public packetList: [any?] = [];
  public filteredPackets: any[] = [];
  public packet: any = {};
  public selectedTip: any = null;
  public tipList: [any?] = [];
  public filteredTips: any[] = [];
  public tip: any = {};

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  public copyShareLink() {
    const link = location.protocol + '//' + location.host + '/blogshop/' + this.blogshopDoc.id;
    // eslint-disable-next-line
    const testingCodeToCopy: any = document.querySelector('#copy-clipboard-input')!;
    testingCodeToCopy.value = link;
    testingCodeToCopy.setAttribute('type', 'text');
    testingCodeToCopy.select();

    try {
      document.execCommand('copy');
      this.$toast.add({ severity: 'success', summary: 'Link kopiert', detail: 'Der Link wurde in deine Zwischenablage kopiert', life: 3000 });
      testingCodeToCopy.setAttribute('type', 'hidden');
    } catch (err) {
      alert('Oops, unable to copy');
    }
  }
  async created() {
    this.loadBlogshop();
  }
  async loadBlogshop() {
    this.userId = this.$router.currentRoute.value.params.id;
    // If the user is not set, then use current userId
    if (!this.userId) {
      this.userId = this.user.uid;
    }
    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.userId)
      .get();
    this.blogshopDoc = blogshopRef.data();
    // eslint-disable-next-line
    this.blogshopDoc.id = blogshopRef.id;
    this.imageSrc = this.blogshopDoc?.imageUrls ? this.blogshopDoc.imageUrls[0] : null;

    const productsRef = await app.config.globalProperties.$firestore
      .collection('products')
      .where('userId', '==', this.user.uid)
      .get();
    productsRef.docs.forEach(productRef => {
      const product = productRef.data();
      product.id = productRef.id;
      this.productList.push(product);
      this.filteredProducts.push(product);
    });
    const packetsRef = await app.config.globalProperties.$firestore
      .collection('packets')
      .where('userId', '==', this.user.uid)
      .get();
    packetsRef.docs.forEach(packetRef => {
      const packet = packetRef.data();
      packet.id = packetRef.id;
      this.packetList.push(packet);
      this.filteredPackets.push(packet);
    });
    const tipsRef = await app.config.globalProperties.$firestore
      .collection('tips')
      .where('userId', '==', this.user.uid)
      .get();
    tipsRef.docs.forEach(tipRef => {
      const tip = tipRef.data();
      tip.id = tipRef.id;
      this.tipList.push(tip);
      this.filteredTips.push(tip);
    });

    if (this.user) {
      const favoriteItem = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('favorites')
        .doc(this.blogshopDoc.id)
        .get();
      if (favoriteItem && favoriteItem.exists) {
        this.favoriteBlogshop = true;
      }
    }
  }
  public goToProductPage() {
    this.$router.push({ name: 'ProductPage', params: { id: this.selectedProduct.id } });
  }
  public goToPacketPage() {
    this.$router.push({ name: 'PacketPage', params: { id: this.selectedPacket.id } });
  }
  public goToTipPage() {
    this.$router.push({ name: 'TipPage', params: { id: this.selectedTip.id } });
  }
  public reload() {}
  public sendMessage(userId) {
    this.$router.push({
      name: 'WriteMessage',
      params: { receiverId: userId },
      query: { itemId: userId, type: 'blogshops' }
    });
  }
  public async likeBlogshop(blogshopId) {
    // eslint-disable-next-line
    if (!this.user.uid) {
      return;
    }
    this.favoriteBlogshop = true;
    const item = {
      type: 'blogshop',
      createdAt: new Date()
    };
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(blogshopId)
      .set(item);
  }
  public async dislikeBlogshop(blogshopId) {
    this.favoriteBlogshop = false;

    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(blogshopId)
      .delete();
  }
  public goToAboutMePage() {
    this.aboutMe = true;
  }
  public backToBlogshop() {
    this.aboutMe = false;
  }
  public goToLogin(blogshopId) {
    this.$router.push({ name: 'Login', params: { id: blogshopId, before: 'Blogshop' } });
  }
  public showFamilyConstellation(familyConstellation) {
    for (const constellation of blogolinoData.familyConstellation) {
      if (familyConstellation === constellation.code) {
        return constellation.name;
      }
    }
  }
  public showPetSpecies(petSpecies) {
    for (const constellation of blogolinoData.petSpecies) {
      if (petSpecies === constellation.code) {
        return constellation.name;
      }
    }
  }
  public showNumberChildren(numberChildren) {
    for (const constellation of blogolinoData.numberChildren) {
      if (numberChildren === constellation.code) {
        return constellation.name;
      }
    }
  }
  public searchProduct(event) {
    setTimeout(() => {
      if (!event.query.trim().length) {
        if (this.productList && this.productList.length > 0) {
          this.filteredProducts = [...this.productList];
        }
      } else {
        this.filteredProducts = this.productList.filter(product => {
          return product.title.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    }, 250);
  }
  public searchPacket(event) {
    setTimeout(() => {
      if (!event.query.trim().length) {
        if (this.packetList && this.packetList.length > 0) {
          this.filteredPackets = [...this.packetList];
        }
      } else {
        this.filteredPackets = this.packetList.filter(packet => {
          return packet.title.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    }, 250);
  }
  public searchTip(event) {
    setTimeout(() => {
      if (!event.query.trim().length) {
        if (this.tipList && this.tipList.length > 0) {
          this.filteredTips = [...this.tipList];
        }
      } else {
        this.filteredTips = this.tipList.filter(tip => {
          return tip.title.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    }, 250);
  }
}
