
/* eslint-disable @typescript-eslint/no-explicit-any*/
import _ from 'lodash';
import { Component } from 'vue';
import { Vue, Options } from 'vue-class-component';
import app from '@/main';
import { useStore } from '@/store';
import * as blogolinoData from '@/blogolinoData.json';
import Editor from 'primevue/editor';

// Import the editor default configuration
import { getEditorDefaults } from 'pintura';
// Import the component from `vue-pintura`
// pintura
/* eslint-disable */
import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  locale_en_gb,
  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  plugin_sticker,
  plugin_sticker_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  // filepond
  openEditor,
  processImage,
  createDefaultImageOrienter,
  legacyDataToImageState
} from 'pintura';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEditor from 'filepond-plugin-image-editor';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate, plugin_sticker);

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImageEditor, FilePondPluginFilePoster) as Component;

@Options({
  components: {
    FilePond,
    Editor
  }
})
export default class NewPacket extends Vue {
  public myFiles: [any?] = [];
  public editorDefaults = getEditorDefaults();
  public error = false;
  public waitingWhileSaving = false;
  public picture = '';
  public title = '';
  public longText = '';
  public keyPoint1 = '';
  public keyPoint2 = '';
  public keyPoint3 = '';
  public files: [File?] = [];
  public imageURLs: [string?] = [];
  public store = useStore();
  public productId = '123';
  public userId = '';
  public link = '';
  public packets: any = [];
  public ages: any = [];
  public selectedAge: any = [];
  public selectedCategory = null;
  public submitted = false;
  public validationErrors = {};
  public countFavorite = 0;
  public myEditor = {
    // map legacy data objects to new imageState objects
    legacyDataToImageState: legacyDataToImageState,

    // used to create the editor, receives editor configuration, should return an editor instance
    createEditor: openEditor,

    // Required, used for reading the image data
    imageReader: [createDefaultImageReader],

    // optionally. can leave out when not generating a preview thumbnail and/or output image
    imageWriter: [
      createDefaultImageWriter,
      {
        // We'll resize images to fit a 512 × 512 square
        targetSize: {
          width: 800,
          height: 800
        }
      }
    ],

    // used to generate poster images, runs an editor in the background
    imageProcessor: processImage,

    // editor options
    editorOptions: {
      stickers: [
        '/images/Avatar_1.png',
        '/images/Avatar_2.png',
        '/images/Avatar_3.png',
        '/images/Avatar_4.png',
        '/images/Avatar_5.png',
        '/images/Avatar_6.png',
        '/images/Avatar_7.png',
        '/images/Sonne.png',
        '/images/Sonne_gelb1.png',
        '/images/Sonne_gelb2.png',
        '/images/Blume2.png',
        '/images/Blume2_bunt.png',
        '/images/Blume2_bunt2.png',
        '/images/Blumen.png',
        '/images/Blumen_bunt.png',
        '/images/Blumen_bunt2.png',
        '/images/Herz.png',
        '/images/Herz_bunt1.png',
        '/images/Herz_bunt2.png',
        '/images/Herz2.png',
        '/images/Herz2_bunt1.png',
        '/images/Herz2_bunt2.png',
        '/images/Herz3.png',
        '/images/Herz3_bunt1.png',
        '/images/Herz3_bunt2.png',
        '/images/Herz4.png',
        '/images/Herz4_bunt1.png',
        '/images/Herz4_bunt2.png'
      ],
      stickerEnableSelectImage: false,
      imageOrienter: createDefaultImageOrienter(),
      utils: ['crop', 'filter', 'finetune', 'annotate', 'sticker'],
      ...plugin_finetune_defaults,
      ...plugin_filter_defaults,
      ...markup_editor_defaults,
      locale: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...plugin_filter_locale_en_gb,
        ...plugin_annotate_locale_en_gb,
        ...plugin_sticker_locale_en_gb,
        ...markup_editor_locale_en_gb
      }
    }
  };
  public setGallery(fileId) {
    const elements = document.getElementsByClassName('gallery-image');
    for (let element of elements) {
      element.classList.remove('gallery-image');
    }
    const firstFileElement = document.getElementById('filepond--item-' + fileId);
    firstFileElement?.classList.add('gallery-image');
  }
  public swapArray(arr, indexA, indexB) {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
  }
  public onreorderfiles(files) {
    const firstFile = files[0];
    this.setGallery(firstFile.id);
    const sorted = _.sortBy(this.myFiles, x => _.findIndex(files, y => x.id === y.id));
  }
  public addFile(event) {
    setTimeout(() => {
      this.setGallery(event.id);
    }, 600);

    this.myFiles.push(event.file);
  }
  public async imageEditorAfterWriteImage(res) {
    res.src.new = res.dest;
    return res.dest;
  }

  public submitLink() {
    const linkIsValid = this.validURL(this.link);
    if (!linkIsValid) {
      // ToDo
      alert('Link geht nicht');
      return;
    }
    this.$router.push({ name: 'NewPacketLink', params: { link: this.link } });
  }
  public validURL(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  // eslint-disable-next-line
  public selectedFiles(event) {
    event.files.forEach(file => {
      this.files.push(file);
    });
  }
  public async uploadImages(packetId) {
    const storageRef = app.config.globalProperties.$storage.ref();
    // @ts-ignore
    this.myFiles = this.myFiles.reverse();
    const result = await Promise.all(
      // @ts-ignore
      this.$refs.pond.getFiles().map(async item => {
        let file;
        file = item.file;
        const fileName = this.uuidv4();
        const productImagesRef = storageRef.child(`images/${packetId}/${fileName}`);
        const metadata = {
          contentType: file.new.type
        };
        // eslint-disable-next-line
        const blob = await fetch(item.getMetadata().poster).then(r => r.blob());
        const snapshot = await productImagesRef.put(file.new, metadata);
        const downloadURL = await snapshot.ref.getDownloadURL();
        //scope.imageURLs.push(downloadURL);
        item.imageURL = downloadURL;
      })
    );
    this.imageURLs = [];
    // @ts-ignore
    for (const item of this.$refs.pond.getFiles()) {
      this.imageURLs.push(item.imageURL);
    }
    return result;
  }
  public uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public blogshopDoc = {};
  public productList: [any?] = [];
  public productService = '';
  public selectedProducts: any = [];
  public filteredProducts: unknown[] = [];
  public product: any = null;
  public tipList: [any?] = [];
  public tipService = '';
  public selectedTips: any = [];
  public filteredTips: unknown[] = [];
  public tip: any = null;
  public packetIdExists = false;
  public packetId;
  public images: [unknown?] = [];
  public imageSrc = null;

  public async created() {
    this.loadNewPacket();
    setTimeout(() => {
      document.getElementsByClassName('filepond--browser')[0].removeAttribute('capture');
    }, 500);
  }
  public handleFilePondInit() {
    console.log('FilePond has initialized');
    // FilePond instance methods are available on `this.$refs.pond`
  }
  public async loadNewPacket() {
    const packetId = this.$router.currentRoute.value.params.id;
    if (packetId) {
      this.packetIdExists = true;
      this.packetId = packetId;
      const packetRef = await app.config.globalProperties.$firestore
        .collection('packets')
        .doc(packetId)
        .get();
      if (packetRef.exists) {
        const packet = packetRef.data();
        this.title = packet.title;
        this.longText = packet.longText;
        this.keyPoint1 = packet.keyPoint1;
        this.keyPoint2 = packet.keyPoint2;
        this.keyPoint3 = packet.keyPoint3;
        this.selectedCategory = packet.selectedCategory;
        this.selectedAge = packet.selectedAge;
        this.countFavorite = packet.countFavorite;
        this.link = packet.link;

        if (packet.linkedProductsIds && packet.linkedProductsIds.length > 0) {
          for (const productId of packet.linkedProductsIds) {
            const linkedProductRef = await app.config.globalProperties.$firestore
              .collection('products')
              .doc(productId)
              .get();
            const linkedProduct = linkedProductRef.data();
            linkedProduct.id = linkedProductRef.id;
            this.selectedProducts.push(linkedProduct);
          }
        }
        if (packet.linkedTipsIds && packet.linkedTipsIds.length > 0) {
          for (const tipId of packet.linkedTipsIds) {
            const linkedTipRef = await app.config.globalProperties.$firestore
              .collection('tips')
              .doc(tipId)
              .get();
            const linkedTip = linkedTipRef.data();
            linkedTip.id = linkedTipRef.id;
            this.selectedTips.push(linkedTip);
          }
        }

        packet?.imageUrls?.forEach(url => {
          // eslint-disable-next-line no-unreachable
          const imageItem = {
            itemImageSrc: url,
            thumbnailImageSrc: url,
            alt: 'Beschreibung'
          };
          this.images.push(imageItem);
          this.imageSrc = packet.imageUrls[0] ? packet.imageUrls[0] : null;
          console.log(this.images, this.imageSrc);
        });
      }
    }
    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .get();
    this.blogshopDoc = blogshopRef.data();
    const productsRef = await app.config.globalProperties.$firestore
      .collection('products')
      .where('userId', '==', this.user.uid)
      .get();
    productsRef.docs.forEach(productRef => {
      const product = productRef.data();
      product.id = productRef.id;
      this.productList.push(product);
      this.filteredProducts.push(product);
    });
    const tipsRef = await app.config.globalProperties.$firestore
      .collection('tips')
      .where('userId', '==', this.user.uid)
      .get();
    tipsRef.docs.forEach(tipRef => {
      const tip = tipRef.data();
      tip.id = tipRef.id;
      this.tipList.push(tip);
      this.filteredTips.push(tip);
    });
    this.packets = blogolinoData.packets;
    this.ages = blogolinoData.ages;
  }

  public searchProduct(event) {
    setTimeout(() => {
      if (!event.query.trim().length) {
        if (this.productList && this.productList.length > 0) {
          this.filteredProducts = [...this.productList];
        }
      } else {
        this.filteredProducts = this.productList.filter(product => {
          return product.title.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    }, 250);
  }
  public searchTip(event) {
    setTimeout(() => {
      if (!event.query.trim().length) {
        if (this.tipList && this.tipList.length > 0) {
          this.filteredTips = [...this.tipList];
        }
      } else {
        this.filteredTips = this.tipList.filter(tip => {
          return tip.title.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    }, 250);
  }
  public agesSelectChanged(event) {
    if (event.value.includes('FJA')) {
      this.selectedAge = ['FJA'];
    } else {
      this.selectedAge = event.value;
    }
  }
  public async renewImages() {
    const imageUrls = app.config.globalProperties.$firebase.firestore.FieldValue.delete();
    await app.config.globalProperties.$firestore
      .collection('packets')
      .doc(this.packetId)
      .update({ imageUrls });
    this.imageSrc = null;
    this.loadNewPacket();
  }

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  public async savePacket() {
    this.submitted = true;
    this.waitingWhileSaving = true;
    if (!this.keyPoint1 || !this.keyPoint2 || !this.title || !this.selectedCategory || this.selectedAge.length === 0) {
      this.error = true;
      this.validationErrors['title'] = !this.title;
      this.validationErrors['selectedCategory'] = !this.selectedCategory;
      this.validationErrors['keyPoint1'] = !this.keyPoint1;
      this.validationErrors['keyPoint2'] = !this.keyPoint2;
      this.validationErrors['selectedAge'] = this.selectedAge.length === 0;
      return;
    }
    const newPacket: any = {
      picture: this.picture,
      title: this.title,
      selectedCategory: this.selectedCategory,
      selectedAge: this.selectedAge,
      keyPoint1: this.keyPoint1,
      keyPoint2: this.keyPoint2,
      keyPoint3: this.keyPoint3,
      longText: this.longText,
      userId: this.user.uid,
      link: this.link,
      createdAt: new Date(),
      countFavorite: this.countFavorite
    };
    if (this.selectedProducts) {
      const selectedProductsIds = this.selectedProducts.map(({ id }) => id);
      newPacket.linkedProductsIds = selectedProductsIds;
    }
    if (this.selectedTips) {
      const selectedTipsIds = this.selectedTips.map(({ id }) => id);
      newPacket.linkedTipsIds = selectedTipsIds;
    }

    Object.keys(newPacket).forEach(key => (newPacket[key] === undefined || newPacket[key] === '' ? delete newPacket[key] : {}));

    if (this.packetId) {
      await app.config.globalProperties.$firestore
        .collection('packets')
        .doc(this.packetId)
        .set(newPacket, { merge: true });
      if (this.myFiles && this.myFiles.length > 0) {
        await this.uploadImages(this.packetId);
        console.log(this.packetId);
        app.config.globalProperties.$firestore
          .collection('packets')
          .doc(this.packetId)
          .set({ imageUrls: this.imageURLs }, { merge: true });
      }
      this.$router.push({ name: 'PacketPage', params: { id: this.packetId } });
    } else {
      app.config.globalProperties.$firestore
        .collection('packets')
        .add(newPacket)
        .then(async docRef => {
          if (this.myFiles && this.myFiles.length > 0) {
            await this.uploadImages(docRef.id);
            app.config.globalProperties.$firestore
              .collection('packets')
              .doc(docRef.id)
              .set({ imageUrls: this.imageURLs }, { merge: true });
          }
          const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(1);
          await app.config.globalProperties.$firestore
            .collection('blogshops')
            .doc(this.user.uid)
            .update({ countPackets: increment });
          this.$router.push({ name: 'PacketPage', params: { id: docRef.id } });
          this.$toast.add({ severity: 'success', summary: 'Paket gespeichert', detail: 'Dein Paket wurde erfolgreich gespeichert', life: 3000 });
        })
        .catch(function(error) {
          console.error('Error adding document: ', error);
        });
    }
  }
}
