
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue } from 'vue-class-component';
import app from '@/main';
import _ from 'lodash';

export default class NewProduct extends Vue {
  public filteredProducts: any[] = [];
  public productList: [any?] = [];
  public selectedProduct: any = null;
  public querySearch = '';
  public searchInProgress = false;
  public productId;

  public created() {
    this.productId = this.$router.currentRoute.value.params.id;
  }

  public async searchProduct() {
    _.debounce(() => {
      this.searchProductAPI();
    }, 300);
  }

  public editProduct() {
    this.$router.push({ name: 'NewProductLink', params: { id: this.productId } });
  }
  public selectProduct(item) {
    if (!this.productId) {
      this.$router.push({
        name: 'NewProductLink',
        params: { amazonProduct: JSON.stringify(item) }
      });
    }
    if (this.productId) {
      this.$router.push({
        name: 'NewProductLink',
        params: { amazonProduct: JSON.stringify(item), id: this.productId }
      });
    }
  }
  public async searchProductAPI() {
    if (this.querySearch.trim().length < 4) {
      this.filteredProducts = [
        {
          thumbnail: 'https://m.media-amazon.com/images/I/71HEM-wXy3L._AC_UL320_.jpg',
          title: 'Marabu 0303000000085 - Kids Fingerfarbe Set mit 6 leuchtenden Farben á 35 ml, parabenfrei, vegan, laktosefrei, glutenfrei,...'
        }
      ];
    } else {
      this.searchInProgress = true;
      this.filteredProducts = [];
      const queryProducts = app.config.globalProperties.$functions.httpsCallable('queryProducts');
      const result = await queryProducts({ query: this.querySearch.toLowerCase() });
      console.log('result', result);
      // Read result of the Cloud Function.
      const filteredProducts: [any?] = [];
      if (result.data?.result) {
        for (const item of result.data?.result) {
          filteredProducts.push(item);
        }
        this.filteredProducts = filteredProducts;
        this.searchInProgress = false;
      }
    }
  }
  public contactSupport() {
    this.$router.push({
      name: 'WriteMessage',
      params: { receiverId: 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2' },
      query: { itemId: 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2', type: 'blogshops' }
    });
  }
  // eslint
}
