
import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';
import Password from 'primevue/password';
import app from '@/main';
import { useConfirm } from 'primevue/useconfirm';
import SettingsMenu from '@/components/SettingsMenu.vue'; // @ is an alias to /src
import 'firebase/auth';

@Options({
  components: {
    Password,
    SettingsMenu
  }
})
export default class SettingsAccount extends Vue {
  public store = useStore();
  public password = '';
  public passwordNew = '';
  public email = '';
  public confirm;
  public memberSince = '';
  public emailNew = '';

  public selectedReason = null;
  public reasons = [
    { name: 'Aktivitäten', code: 'AKT' },
    { name: 'Baby', code: 'BAB' },
    { name: 'Do it yourself', code: 'DIY' },
    { name: 'Lifehacks', code: 'LIF' },
    { name: 'Rezepte', code: 'REZ' },
    { name: 'Sinnloses', code: 'SIN' },
    { name: 'Veranstaltungen', code: 'VER' },
    { name: 'Zuhause', code: 'ZUH' },
    { name: 'Weiteres', code: 'WEI' }
  ];

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  async created() {
    this.confirm = useConfirm();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    this.memberSince = this.userDocument.createdAt.toDate().toLocaleDateString('de-DE', options);
  }

  public async confirmCloseAccount() {
    this.confirm.require({
      message: 'Bist du sicher, dass du deinen Account löschen möchtest? Die Daten werden unwiederbringlich gelöscht.',
      header: 'Konto schließen',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.closeAccount();
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }

  public async changePassword() {
    const credential = app.config.globalProperties.$firebase.auth.EmailAuthProvider.credential(this.user.email, this.password);
    await this.user.reauthenticateWithCredential(credential);
    await this.user.updatePassword(this.passwordNew);
    this.$toast.add({ severity: 'success', summary: 'Passwort geändert', detail: 'Dein Passwort wurde erfolgreich geändert', life: 3000 });
  }
  public async changeMail() {
    const credential = app.config.globalProperties.$firebase.auth.EmailAuthProvider.credential(this.user.email, this.password);
    await this.user.reauthenticateWithCredential(credential);
    await this.user.updateEmail(this.emailNew);
    this.$toast.add({ severity: 'success', summary: 'E-Mail geändert', detail: 'Deine E-Mailadresse wurde erfolgreich geändert', life: 3000 });
  }

  public async closeAccount() {
    await app.config.globalProperties.$firestore;
    this.user.delete().then(function() {
      // User deleted.
    });
    app.config.globalProperties.$firebase.auth().signOut();
    this.store.commit('setUser', null);
    this.$toast.add({ severity: 'success', summary: 'Konto gelöscht', detail: 'Dein Konto wurde unwiederbringlich gelöscht', life: 3000 });
    this.$router.push({ name: 'Home' });
  }
  public contactSupport() {
    this.$router.push({
      name: 'WriteMessage',
      params: { receiverId: 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2' },
      query: { itemId: 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2', type: 'blogshops' }
    });
  }
}
