<template>
  <div class="text-bold">Allgemeine Geschäftsbedingungen</div>

  <div class="text-normal" style="margin-top:10px">
    Die folgenden Nutzungsbedingungen (AGB) regeln die Nutzung der blogolino.de-Webseite (im folgenden Dienst genannt). Wenn du ein blogolino-Konto erstellst oder als Besucher Inhalte liest, stimmst du diesen
    Nutzungsbedingungen zu. Bitte lies die Bedingungen aufmerksam durch und kontaktiere unseren Support, falls du Rückfragen haben solltest.
  </div>
  <div class="text-bold" style="margin-top:30px">1. Unsere Verpflichtungen</div>
  <div class="text-normal" style="margin-top:10px">
    Blogolino ist eine Online-Community, auf der Eltern, weitere Familienmitglieder wie Omas, Opas, Tanten, Onkel, etc., sowie Erzieher und Lehrer Inhalte empfehlen können. Die Nutzung unseres Dienstes ist kostenlos. Wir
    nutzen angemessene Kenntnisse und setzen die erforderliche Sorgfalt dabei ein, dir unseren Dienst bereitzustellen und für eine sichere und fehlerfreie Umgebung zu sorgen. Wir garantieren nicht, dass unser Dienst
    kontinuierlich, ohne Verzögerungen oder Mängel zur Verfügung stehen wird. Außerdem behält sich der Dienst vor, angemessene Werbung zu schalten oder Produkte über Affiliate-Links zu verknüpfen. Wir möchten, dass unser
    Dienst so offen und inklusiv wie möglich, aber dennoch ungefährlich und sicher sowie rechtskonform ist. Aus diesem Grund ist es erforderlich, dass du dich zur Einhaltung einiger weniger Einschränkungen verpflichtest,
    um Teil der blogolino-Community sein zu können.
  </div>

  <div class="text-bold" style="margin-top:30px">2. Deine Verpflichtungen</div>
  <div class="text-normal" style="margin-top:10px">
    Um unsere Plattform so sicher wie möglich zu gestalten, verpflichtest du dich folgende Bedingungen einzuhalten:
  </div>
  <div class="text-normal" style="margin-top:10px">
    Das Mindestalter beträgt 18 Jahre.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Dir darf nach geltendem Recht nicht der Empfang eines Teils unseres Dienstes untersagt sein.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Dein Konto darf nicht bereits zuvor wegen eines Verstoßes gegen unsere Nutzungsbedingungen oder Richtlinien deaktiviert worden sein.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Insbesondere ist es unzulässig, Inhalte mit folgenden Bestandteilen zu posten:
  </div>
  <div class="text-normal" style="margin-top:10px">
    - Inhalte, die in den Teilen B und D der Liste nach § 18 des Jugendschutzgesetzes aufgeführt werden
  </div>
  <div class="text-normal" style="margin-top:10px">
    - Inhalte, die Kinder oder Jugendliche in unnatürlich geschlechtsbetonter Körperhaltung darstellen
  </div>
  <div class="text-normal" style="margin-top:10px">
    - Unzulässig sind auch Inhalte, die als Anleitung zu einer in § 126 Abs. 1 des Strafgesetzbuches genannten rechtswidrigen Tat dienen könnten
  </div>
  <div class="text-normal" style="margin-top:10px">
    - Inhalte die gegen die Menschenwürde verstoßen
  </div>
  <div class="text-normal" style="margin-top:10px">
    - Inhalte, die zum Hass gegen Bevölkerungsgruppen oder gegen eine nationale, rassische, religiöse oder durch ihr Volkstum bestimmte Gruppe aufstacheln, zu Gewalt- oder Willkürmaßnahmen gegen sie auffordern oder deren
    Menschenwürde angreifen
  </div>
  <div class="text-normal" style="margin-top:10px">
    - Inhalte, die den Krieg verherrlichen oder in § 6 Abs. 1 und § 7 Abs. 1 des Völkerstrafgesetzbuches bezeichneten Handlungen , die unter der Herrschaft des Nationalsozialismus begangene wurden in einer Weise
    schildern, die geeignet ist, den öffentlichen Frieden zu stören, leugnen oder zu verharmlosen
  </div>
  <div class="text-normal" style="margin-top:10px">
    - Kennzeichen verfassungswidriger Organisationen im Sinne des § 86a des Strafgesetzbuches, deren Inhalt gegen die freiheitliche demokratische Grundordnung oder den Gedanken der Völkerverständigung gerichtet ist
  </div>

  <div class="text-bold" style="margin-top:30px">3. Verwendung unseres Dienstes</div>
  <div class="text-normal" style="margin-top:10px">
    Hier sind einige Informationen über die zulässige Verwendung unserer Plattform:
  </div>
  <div class="text-normal" style="margin-top:10px">
    Du darfst dich nicht als jemand anderes ausgeben oder ungenaue bzw. falsche Informationen bereitstellen.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Du musst uns korrekte und aktuelle Informationen bereitstellen. Das bedeutet möglicherweise auch, dass wir dich auffordern, uns personenbezogene Daten (wie Namen, Adresse, etc.) bereitzustellen.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Du darfst kein Konto für jemand anderen erstellen, sofern du nicht dessen ausdrückliche Einwilligung eingeholt hast.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Du darfst nichts Rechtswidriges, Irreführendes oder Betrügerisches oder etwas für einen illegalen oder unberechtigten Zweck tun.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Du darfst nicht gegen diese Nutzungsbedingungen oder unsere Richtlinien verstoßen.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Du darfst nicht beabsichtigten, den Betrieb des Dienstes zu stören oder zu beeinträchtigen. Dies beinhaltet auch das Einreichen wissentlich falscher oder unbegründeter Meldungen.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Du darfst nicht auf unbefugte Art und Weise Konten erstellen oder auf Informationen zugreifen bzw. diese zu erheben.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Du darfst nicht von unserem Dienst erhaltene Daten verkaufen, lizenzieren oder kaufen. Insbesondere ist es verboten Konten, Anmeldedaten oder Passwörter zu erfragen.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Du darfst nicht ohne Erlaubnis private oder vertrauliche Informationen einer anderen Person posten oder etwas tun, das die Rechte einer anderen Person verletzt, einschließlich geistiger Eigentumsrechte (z. B.
    Urheberrechtsverletzung, Markenverletzung, gefälschte oder raubkopierte Waren).
  </div>
  <div class="text-normal" style="margin-top:10px">
    Du darfst unsere Produkte und ihre Komponenten nicht verändern oder übersetzen, keine abgeleiteten Werke davon erstellen und sie auch nicht nachbauen.
  </div>
  <div class="text-bold" style="margin-top:30px">4. Berechtigungen</div>
  <div class="text-bold">
    Mit dem Posten deiner Inhalte beansprucht blogolino nicht das Eigentum an den geposteten Inhalten, sondern du gewährst uns eine Lizenz. Somit ändert sich nichts in Bezug auf deine Rechte an deinen Inhalten. Du kannst
    deine Inhalte nach Belieben mit anderen teilen, wo immer du das möchtest.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Mit dem Posten deiner Inhalte gewährst du uns und unseren Nutzern eine nicht ausschließliche, gebührenfreie, nicht übertragbare, nicht unterlizenzierbare, weltweite Lizenz, deinen Nutzerinhalt auf blogolino zu
    verwenden, zu speichern, anzuzeigen, zu reproduzieren, aufzuheben, zu ändern, abgeleitete Arbeiten davon zu erstellen, ihn vorzuführen und zu verteilen. Du allein bist für alle Aktivitäten, die unter deinen Anmelde-
    oder Zugangsdaten stattfinden, verantwortlich. Du sicherst zu, dass du alle erforderlichen Rechte bezüglich der Inhalte, die du postest oder teilst, besitzt oder eingeholt hast.
  </div>
  <div class="text-bold" style="margin-top:30px">5. Entfernung von Inhalten/ Deaktivierung des Kontos</div>
  <div class="text-bold">
    Solltest du auf blogolino Inhalte teilen, die unserer Ansicht nach gegen diese Nutzungsbedingungen oder unsere Richtlinien verstoßen, oder wenn wir von Rechts wegen dazu verpflichtet sind, sind wir berechtigt diese
    zu entfernen.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Zudem können wir dir die die vollständige oder teilweise Bereitstellung unseres Dienstes unverzüglich verwehren oder einstellen. Insbesondere gilt dies, wenn du für unseren Dienst oder unsere Nutzer eine Gefahr oder
    ein rechtliches Risiko darstellst, wenn du wiederholt die geistigen Eigentumsrechte anderer verletzt, wenn du gegen diese Nutzungsbedingungen oder unsere Richtlinien verstößt oder wenn wir von Rechts wegen dazu
    verpflichtet sind. Sollten wir Inhalte von dir löschen oder dein Konto sperren, werden wir dich über deine angegebene E-Mail oder Benachrichtungen auf unserem Dienst informieren. Du kannst gerne unseren Support
    kontaktieren, wenn dein Konto irrtümlich gekündigt worden ist oder du dein Konto deaktivieren oder dauerhaft löschen möchtest.
  </div>

  <div class="text-bold" style="margin-top:10px">
    6. Haftungsbeschränkung
  </div>
  <div class="text-bold" style="margin-top:10px">
    Unser Dienst und alle Inhalte auf blogolino werden ohne Gewähr und ohne ausdrückliche oder implizierte Garantien bereitgestellt.
  </div>
  <div class="text-normal" style="margin-top:10px">
    Wir übernehmen keine Verantwortung oder Haftung für Nutzerinhalte, die du, ein anderer Nutzer oder ein Dritter über unseren Dienst postet oder überträgt. Du nimmst zur Kenntnis, dass du eventuell mit Nutzerinhalten
    konfrontiert werden kannst, die falsch, anstößigoder anderweitig nicht für deine Zwecke geeignet sind. Wir werden regelmäßig, gehen jedoch keinerlei derartige Verpflichtung ein, Inhalte von Texten, Fotos und Grafiken
    auf die Einhaltung von gesetzlichen und rechtlichen Regelungen prüfen und diese, wenn nötig, teilweise oder komplett löschen. Wir haftet nicht für wirtschaftliche, körperliche oder immaterielle Schäden, die sich aus
    der Nutzung unseres Dienstes ergeben, soweit der Schaden nicht nachweislich auf Vorsatz oder grober Fahrlässigkeit des Betreibers beruht. Auf unserem Dienst können externe Seiten verlinkt werden. Wir erklären hiermit
    ausdrücklich, dass wir keinerlei Einfluss auf die Gestaltung und die Inhalte der gelinkten Seiten haben. Deshalb distanzieren wir uns hiermit ausdrücklich von allen Inhalten aller von unserem Dienst aus gelinkten
    Seiten und machen uns ihre Inhalte nicht zu eigen. Eine Haftung für fremde Inhalte ist ausgeschlossen.
  </div>

  <div class="text-bold" style="margin-top:10px">
    7. Unsere Vereinbarung
  </div>
  <div class="text-normal" style="margin-top:10px">
    Die übrigen Bestimmungen dieser Nutzungsbedingungen bleiben wirksam, selbst wenn einzelne Aspekte dieser Vereinbarung nicht durchsetzbar sind. Ein Versäumnis unsererseits, Bestimmungen dieser Vereinbarung
    durchzusetzen, stellt keinen Rechtsverzicht dar.
  </div>

  <div class="text-bold" style="margin-top:10px">
    Aktualisierung dieser Nutzungsbedingungen
  </div>
  <div class="text-normal" style="margin-top:10px">
    Es kann vorkommen, dass wir die Nutzungsbediungen ändern bzw. anpassen müssen. Du wirst rechtzeitig (mind. 30 Tage im Vorraus) benachrichtig (z.B pe E-Mail oder über Benachrichtigungen über blogolino.de), bevor wir
    Änderungen der allgemeinen Geschäftsbeziehungen vornehmen. Diese stehen dir dann zur Überprüfung zur Verfügung. Wenn du unseren Dienst danach weiterhin nutzen möchtest, bist du an die neuen AGB gebunden. Anderenfalls
    kannst du die Löschung deines Kontos beauftragen.
  </div>
</template>
