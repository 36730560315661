
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue } from 'vue-class-component';
import app from '@/main';
import { useStore } from '@/store';

export default class WriteMessage extends Vue {
  public store = useStore();
  // eslint-disable-next-line
  public receiver: any = {};
  public reference;
  public messageContent = '';
  public itemType = '';
  public item;
  public itemLoaded = false;
  public messageLoaded = false;
  public itemList: [any?] = [];
  public message;
  public chatId;
  public itemIsBlogshop = false;
  public itemId;
  public itemIsSupport = false;
  public topic;
  public supportMessage = false;
  public type;
  public toAll = false;
  public messageAbout;

  public async created() {
    const receiverId = this.$router.currentRoute.value.params.receiverId;
    if (receiverId == 'Alle') {
      this.toAll = true;
    }
    if ((receiverId || this.user.uid) == 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2') {
      this.supportMessage = true;
    }

    const itemId = this.$router.currentRoute.value.query.itemId;
    this.itemId = itemId;

    const type = String(this.$router.currentRoute.value.query.type);
    if (type) {
      this.type = type;
    }

    const chatId = this.$router.currentRoute.value.query.chatId;

    if (this.toAll == false) {
      const blogshopRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(receiverId)
        .get();
      this.receiver = blogshopRef.data();
      this.receiver.id = blogshopRef.id;
    }
    if (chatId) {
      this.chatId = chatId;
      const chatRef = await app.config.globalProperties.$firestore
        .collection('chats')
        .doc(this.chatId)
        .get();
      this.messageAbout = chatRef.data().messageAbout;

      if (chatRef.data().unreadMessage == this.user.uid) {
        await app.config.globalProperties.$firestore
          .collection('chats')
          .doc(this.chatId)
          .update({ unreadMessage: [] });
      }

      const queryMessages = app.config.globalProperties.$firestore
        .collection('chats')
        .doc(chatId)
        .collection('messages');
      const messagesRef = await queryMessages.orderBy('createdAt').get();

      messagesRef.docs.forEach(async ref => {
        const item = ref.data();
        console.log(ref.data());
        item.id = ref.id;
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        item.date = item.createdAt.toDate().toLocaleDateString('de-DE', options);
        item.hours = item.createdAt.toDate().getHours();
        item.minutes = item.createdAt.toDate().getMinutes();
        if (item.minutes < 10) {
          item.minutes = '0' + item.minutes;
        }

        this.itemList.push(item);
      });
    }
    if (type == 'blogshops' && receiverId != 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2') {
      this.itemIsBlogshop = true;
    }
    if (receiverId == 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2') {
      this.itemIsSupport = true;
    }

    if (itemId) {
      this.itemType = type;
      const itemRef = await app.config.globalProperties.$firestore
        .collection(this.itemType)
        .doc(itemId)
        .get();
      this.item = itemRef.data();
      this.item.id = itemRef.id;
      this.itemLoaded = true;
    }
    this.messageLoaded = true;
  }

  public async sendMessage() {
    if (this.chatId) {
      await app.config.globalProperties.$firestore
        .collection('chats')
        .doc(this.chatId)
        .update({ latestMessage: new Date(), unreadMessage: [this.receiver.id] });

      const newMessage = {
        messageContent: this.messageContent,
        userId: this.user.uid,
        createdAt: new Date()
      };
      await app.config.globalProperties.$firestore
        .collection('chats')
        .doc(this.chatId)
        .collection('messages')
        .add(newMessage);
      this.$router.push({ name: 'Messages' });

      this.$toast.add({ severity: 'success', summary: 'Nachricht gesendet', detail: 'Deine Nachricht wurde erfolgreich gesendet', life: 3000 });
    } else {
      const newChat = {
        userIds: [this.receiver.id, this.user?.uid],
        itemId: this.item?.id,
        itemType: this.itemType,
        latestMessage: new Date(),
        unreadMessage: [this.receiver.id],
        supportMessage: this.supportMessage,

        createdAt: new Date()
      };
      Object.keys(newChat).forEach(key => (newChat[key] === undefined ? delete newChat[key] : {}));

      const newMessage = {
        messageContent: this.messageContent,
        userId: this.user?.uid,
        createdAt: new Date()
      };
      const messageRef = await app.config.globalProperties.$firestore.collection('chats').add(newChat);
      await app.config.globalProperties.$firestore
        .collection('chats')
        .doc(messageRef.id)
        .collection('messages')
        .add(newMessage);
      if (this.type == 'products') {
        this.$router.push({ name: 'ProductPage', params: { id: this.itemId } });
      }
      if (this.type == 'tips') {
        this.$router.push({ name: 'TipPage', params: { id: this.itemId } });
      }
      if (this.type == 'packets') {
        this.$router.push({ name: 'PacketPage', params: { id: this.itemId } });
      }
      if (this.type == 'blogshops') {
        if (this.itemIsSupport == true) {
          this.$router.go(-1);
        } else {
          this.$router.push({ name: 'Blogshop', params: { id: this.itemId } });
        }
      }

      this.$toast.add({ severity: 'success', summary: 'Nachricht gesendet', detail: 'Deine Nachricht wurde erfolgreich gesendet', life: 3000 });
    }
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
}
