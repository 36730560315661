
import { Vue } from 'vue-class-component';
import { useStore } from '@/store';

export default class ProductNotFound extends Vue {
  public link = '';
  public store = useStore();

  public submitLink() {
    const linkIsValid = this.validURL(this.link);
    if (!linkIsValid) {
      // ToDo
      alert('Link geht nicht');
      return;
    }
    this.$router.push({ name: 'NewProductLink', params: { link: this.link } });
  }
  public validURL(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }
  public contactSupport() {
    this.$router.push({
      name: 'WriteMessage',
      params: { receiverId: 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2' },
      query: { itemId: 'mZ6E4OXgZLMTb7pj0EtbfaCodNl2', type: 'blogshops' }
    });
  }
  // eslint
}
