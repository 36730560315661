
import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';
import SettingsMenu from '@/components/SettingsMenu.vue'; // @ is an alias to /src

@Options({
  components: {
    SettingsMenu
  }
})
export default class SettingsBlogshop extends Vue {
  public store = useStore();
  public absenceText = '';
  public blogshopDoc;
  public blogshopCreated = '';
  public city = '';

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }

  public async created() {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    console.log('this.userDocument', this.userDocument);
    const blogShopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .get();
    this.blogshopDoc = blogShopRef.data();
    this.blogshopCreated = this.blogshopDoc.createdAt.toDate().toLocaleDateString('de-DE', options);
    this.city = this.blogshopDoc.city;
  }
  public async saveBlogshopChanges() {
    const blogshopDocument = {
      absenceText: this.absenceText
    };
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .set(blogshopDocument, { merge: true });
  }
}
