
import { Options, Vue } from 'vue-class-component';
import app from '@/main';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src
import { FilterMatchMode } from 'primevue/api';

@Options({
  components: {
    DashboardMenu
  }
})
export default class DashboardProducts extends Vue {
  public warningList: [unknown?] = [];
  // eslint-disable-next-line
  public filters: any = null;

  public openProductPage(data) {
    console.log(data);
    if (data.type == 'product') {
      this.$router.push({ name: 'ProductPage', params: { id: data.id } });
    } else if (data.type == 'tip') {
      this.$router.push({ name: 'TipPage', params: { id: data.id } });
    } else if (data.type == 'packet') {
      this.$router.push({ name: 'PacketPage', params: { id: data.id } });
    } else if (data.type == 'blogshop') {
      this.$router.push({ name: 'BlogshopPage', params: { id: data.id } });
    }
  }
  initFilters() {
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
  }

  async created() {
    this.initFilters();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const warnings = await app.config.globalProperties.$firestore.collection('warnings').get();
    warnings.forEach(item => {
      const warningItem = item.data();
      warningItem.id = item.id;
      warningItem.createdAt = item.data().createdAt
        ? item
            .data()
            .createdAt.toDate()
            .toLocaleDateString('de-DE', options)
        : null;
      this.warningList.push(warningItem);
    });
  }
}
