
import { Vue } from 'vue-class-component';

export default class SearchSidebar extends Vue {
  public display = false;
  public query = '';
  public selectedCategory;
  public selectedSubcategory;
  public minPrice = 0;
  public maxPrice = 0;
  public type;

  submitQuery() {
    this.$router.push({ name: 'Search', query: { query: this.query } });
    this.display = false;
  }
}
