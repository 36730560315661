
import { Options, Vue } from 'vue-class-component';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src
import * as faq from '@/faq.json';

@Options({
  components: {
    DashboardMenu
  }
})
export default class FAQ extends Vue {
  // eslint-disable-next-line
  public categories: any = [];
  // eslint-disable-next-line
  public currentCategory;
  public showFAQ = true;
  public showFAQQuestions = false;
  // eslint-disable-next-line
  public questions: any = [];

  public async created() {
    this.categories = faq.FAQcategories;
  }

  public openLink(path) {
    this.$router.push({ path: path });
  }

  public openCategory(category) {
    this.questions = category.questions;
    this.showFAQ = false;
    this.showFAQQuestions = true;
    this.currentCategory = category.name;
  }
  public goToShop(link) {
    const url = link;
    window.open(url, '_blank');
  }

  public goToFAQCategories() {
    this.showFAQQuestions = false;
    this.showFAQ = true;
  }
}
