
import { Vue } from 'vue-class-component';



export default class Partner extends Vue {
  // eslint-disable-next-line
  public place: any = [];
  // eslint-disable-next-line
  public markers: any = [];

src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBqAWw5Ah-Uzp2PpsF6rv0UG_RuILaGpfk&libraries=places&callback=initMap&v=weekly"
      defer

public async created() {
    this.markers = [
        {
          position: {
            lat: 51.093048, lng: 6.842120
          },
          
        }
      ]
  }

data() {
    return {
      center: { lat: 51.093048, lng: 6.84212 },
      
    };
  }

public openInfoWindow() {
  const url = 'https://blogolino.de/';
  window.open(url, '_blank');
}
public setPlace(Autocomplete) {
  this.place = Autocomplete

  console.log(this.place.geometry.location.lat())
            }
    

}
