
import { Options, Vue } from 'vue-class-component';
import Password from 'primevue/password';
import app from '@/main';
import { useStore } from '@/store';
import firebase from 'firebase/app';
import 'firebase/auth';

@Options({
  components: {
    Password
  }
})
export default class Register extends Vue {
  public email = '';
  public username = '';
  public password = '';
  public store = useStore();
  public validationErrors = {};
  public submitted = false;
  public privacy = false;
  public agb = false;
  public newsletter = false;
  public usernameTaken = false;

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }

  public async register() {
    this.submitted = true;
    // Schneller machen
    // const nameRef = await app.config.globalProperties.$firestore.collection('blogshops').where('username', '=', 'blogshopName').get();
    const nameRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .where('name', '==', this.username)
      .get();
    this.usernameTaken = false;
    for (const ref of nameRef.docs) {
      const item = ref.data();
      if (this.username === item.name) {
        this.usernameTaken = true;
      }
    }
    console.log(this.validationErrors['username']);

    if (!this.username || !this.email || !this.password || !this.privacy || !this.agb || this.usernameTaken === true) {
      this.validationErrors['username'] = !this.username || this.usernameTaken;
      this.validationErrors['email'] = !this.email;
      this.validationErrors['password'] = !this.password;
      this.validationErrors['privacy'] = !this.privacy;
      this.validationErrors['agb'] = !this.agb;
      return;
    }

    const userDocument = {
      username: this.username,
      email: this.email,
      createdAt: new Date(),
      privacy: this.privacy,
      agb: this.agb,
      newsletter: this.newsletter
    };
    const blogshopDocument = {
      name: this.username,
      blogshopExists: true,
      countProducts: 0,
      countTips: 0,
      countPackets: 0,
      countFollowers: 0
    };

    // eslint-disable-next-line no-unreachable
    app.config.globalProperties.$firebase
      .auth()
      .createUserWithEmailAndPassword(this.email, this.password)
      .then(async (result: firebase.auth.UserCredential) => {
        await result.user?.updateProfile({
          displayName: this.username
        });
        const actionCodeSettings = {
          url: location.protocol + '//' + location.host
        };
        await result.user?.sendEmailVerification(actionCodeSettings);
        this.store.commit('setUser', result.user);
        this.$router.push({ name: 'Verification' });
        await app.config.globalProperties.$firestore
          .collection('users')
          .doc(this.user.uid)
          .set(userDocument, { merge: true });
        this.store.commit('setUserDocument', userDocument);
        await app.config.globalProperties.$firestore
          .collection('blogshops')
          .doc(this.user.uid)
          .set(blogshopDocument, { merge: true });
        this.store.commit('setBlogshopDocument', blogshopDocument);
      })
      // eslint-disable-next-line
      .catch((error: any) => {
        if (error.code && error.code === 'auth/invalid-email') {
          this.validationErrors['email'] = true;
        }
        if (error.code && error.code === 'auth/email-already-in-use') {
          this.validationErrors['email'] = true;
          console.log(error.code);
        }
        console.log(error);
      });
  }
}
