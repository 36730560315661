
import { Options, Vue } from 'vue-class-component';
import app from '@/main';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src
import { FilterMatchMode } from 'primevue/api';

@Options({
  components: {
    DashboardMenu
  }
})
export default class DashboardProducts extends Vue {
  public productList: [unknown?] = [];
  // eslint-disable-next-line
  public filters: any = null;

  public openProductPage(data) {
    console.log(data);
    this.$router.push({ name: 'ProductPage', params: { id: data.id } });
  }
  initFilters() {
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
  }

  async created() {
    this.initFilters();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const products = await app.config.globalProperties.$firestore.collection('products').get();
    products.forEach(item => {
      const productItem = item.data();
      productItem.id = item.id;
      productItem.createdAt = item.data().createdAt
        ? item
            .data()
            .createdAt.toDate()
            .toLocaleDateString('en-CA', options)
        : null;
      this.productList.push(productItem);
    });
  }
}
