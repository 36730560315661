
import { Vue, Options, prop } from 'vue-class-component';
import { useStore } from '@/store';
import ProductReview from '@/components/ProductReview.vue'; // @ is an alias to /src
import TipReview from '@/components/TipReview.vue'; // @ is an alias to /src
import PacketReview from '@/components/PacketReview.vue'; // @ is an alias to /src
import BlogshopReview from '@/components/BlogshopReview.vue'; // @ is an alias to /src
import app from '@/main';
import algoliasearch from 'algoliasearch';
import * as blogolinoData from '@/blogolinoData.json';
import firebase from 'firebase/app';




// Define the props by using Vue's canonical way.
class Props {
  userId = prop({
    type: String,
    required: false
  });

  productIds = prop({
    type: Array,
    required: false
  });
  productId = prop({
    type: String,
    required: false
  });
  tipIds = prop({
    type: Array,
    required: false
  });
  packetIds = prop({
    type: Array,
    required: false
  });
  query = prop({
    type: String,
    required: false
  });
  hideMyItems = prop({
    type: Boolean,
    required: false
  });
  selectedAge = prop({
    type: String,
    required: false
  });
  selectedCategory = prop({
    type: String,
    required: false
  });
  selectedSubcategory = prop({
    type: String,
    required: false
  });
  type = prop({
    type: String,
    required: false
  });
  typeSort = prop({
    type: String,
    required: false
  });
  minPrice = prop({
    type: Number,
    required: false
  });
  maxPrice = prop({
    type: Number,
    required: false
  });
  listId = prop({
    type: String,
    required: false
  });
  listOwner = prop({
    type: String,
    required: false
  });
  showProducts = prop({
    type: Boolean,
    required: false
  });
  showPackets = prop({
    type: Boolean,
    required: false
  });
  showTips = prop({
    type: Boolean,
    required: false
  });
  showTipsRandomly = prop({
    type: Boolean,
    required: false
  });
  showBlogshops = prop({
    type: Boolean,
    required: false
  });
  showNowarnings = prop({
    type: Boolean,
    required: false
  });
  showFavorites = prop({
    type: Boolean,
    required: false
  });
  showSelectedProducts = prop({
    type: Boolean,
    required: false
  });
  showList = prop({
    type: String,
    required: false
  });

  showAll = prop({
    type: Boolean,
    required: false
  });
  show12 = prop({
    type: Boolean,
    required: false
  });
}
@Options({
  components: {
    ProductReview,
    TipReview,
    PacketReview,
    BlogshopReview
  }
})
export default class ProductGrid extends Vue.with(Props) {
  public store = useStore();
  public itemList: [unknown?] = [];
  public itemWarned = false;
  public userWarningOff = false;
  public algoliaClient = algoliasearch(app.config.globalProperties.$algoliaConfig.appId, app.config.globalProperties.$algoliaConfig.apiKey);

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }

  async created() {
    let queryProducts = app.config.globalProperties.$firestore.collection('products');
    let queryPackets = app.config.globalProperties.$firestore.collection('packets');
    let queryTips = app.config.globalProperties.$firestore.collection('tips');
    let queryBlogshops = app.config.globalProperties.$firestore.collection('blogshops');

    if (this.userId) {
      queryProducts = queryProducts.where('userId', '==', this.userId);
      queryPackets = queryPackets.where('userId', '==', this.userId);
      queryTips = queryTips.where('userId', '==', this.userId);
      queryBlogshops = queryBlogshops.where('userId', '==', this.userId);
    }
    if (this.showNowarnings) {
      queryProducts = queryProducts.where('locked', '!=', 'true');
      queryPackets = queryPackets.where('locked', '!=', 'true');
      queryTips = queryTips.where('locked', '!=', 'true');
      queryBlogshops = queryBlogshops.where('locked', '!=', 'true');
    }

    if (this.hideMyItems && this.user?.uid) {
      queryProducts = queryProducts.where('userId', '!=', this.user.uid);
      queryPackets = queryPackets.where('userId', '!=', this.user.uid);
      queryTips = queryTips.where('userId', '!=', this.user.uid);
    }
    if (!this.query) {
      if (
        this.selectedCategory &&
        this.selectedCategory !== 'ALL' &&
        (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') &&
        this.selectedAge &&
        this.selectedAge !== 'ALL' &&
        !this.minPrice &&
        !this.maxPrice
      ) {
        queryProducts = queryProducts.where('selectedCategory', '==', this.selectedCategory).where('selectedAge', 'array-contains', this.selectedAge);
        queryPackets = queryPackets.where('selectedCategory', '==', this.selectedCategory).where('selectedAge', 'array-contains', this.selectedAge);
        queryTips = queryTips.where('selectedCategory', '==', this.selectedCategory).where('selectedAge', 'array-contains', this.selectedAge);
      }
      if (this.selectedSubcategory && this.selectedSubcategory !== 'ALL' && this.selectedAge && this.selectedAge !== 'ALL' && !this.minPrice && !this.maxPrice) {
        queryProducts = queryProducts
          .where('selectedCategory', '==', this.selectedCategory)
          .where('selectedSubcategory', '==', this.selectedSubcategory)
          .where('selectedAge', 'array-contains', this.selectedAge);
      }

      if (
        this.selectedCategory &&
        this.selectedCategory !== 'ALL' &&
        (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        !this.minPrice &&
        !this.maxPrice
      ) {
        queryProducts = queryProducts.where('selectedCategory', '==', this.selectedCategory);
        queryPackets = queryPackets.where('selectedCategory', '==', this.selectedCategory);
        queryTips = queryTips.where('selectedCategory', '==', this.selectedCategory);
      }
      if (this.selectedSubcategory && this.selectedSubcategory !== 'ALL' && (!this.selectedAge || this.selectedAge == 'ALL') && !this.minPrice && !this.maxPrice) {
        queryProducts = queryProducts.where('selectedCategory', '==', this.selectedCategory).where('selectedSubcategory', '==', this.selectedSubcategory);
      }
      if (this.selectedAge && this.selectedAge !== 'ALL' && (!this.selectedCategory || this.selectedCategory === 'ALL') && !this.minPrice && !this.maxPrice) {
        queryProducts = queryProducts.where('selectedAge', 'array-contains', this.selectedAge);
        queryPackets = queryPackets.where('selectedAge', 'array-contains', this.selectedAge);
        queryTips = queryTips.where('selectedAge', 'array-contains', this.selectedAge);
      }

      if (this.minPrice && this.maxPrice) {
        if (((!this.selectedCategory || this.selectedCategory == 'ALL') && !this.selectedAge) || this.selectedAge == 'ALL') {
          queryProducts = queryProducts.where('price', '>=', this.minPrice).where('price', '<=', this.maxPrice);
        } else if ((this.selectedCategory != 'ALL' && (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') && !this.selectedAge) || this.selectedAge == 'ALL') {
          queryProducts = queryProducts
            .where('price', '>=', this.minPrice)
            .where('price', '<=', this.maxPrice)
            .where('selectedCategory', '==', this.selectedCategory);
        } else if ((this.selectedCategory != 'ALL' && this.selectedSubcategory && this.selectedSubcategory !== 'ALL' && !this.selectedAge) || this.selectedAge == 'ALL') {
          queryProducts = queryProducts
            .where('price', '>=', this.minPrice)
            .where('price', '<=', this.maxPrice)
            .where('selectedCategory', '==', this.selectedCategory)
            .where('selectedSubcategory', '==', this.selectedSubcategory);
        } else if ((!this.selectedCategory || this.selectedCategory == 'ALL') && this.selectedAge && this.selectedAge !== 'ALL') {
          queryProducts = queryProducts
            .where('price', '>=', this.minPrice)
            .where('price', '<=', this.maxPrice)
            .where('selectedAge', 'array-contains', this.selectedAge);
        } else if (this.selectedCategory != 'ALL' && (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') && this.selectedAge && this.selectedAge !== 'ALL') {
          queryProducts = queryProducts
            .where('price', '>=', this.minPrice)
            .where('price', '<=', this.maxPrice)
            .where('selectedCategory', '==', this.selectedCategory)
            .where('selectedAge', 'array-contains', this.selectedAge);
        } else if (this.selectedCategory != 'ALL' && this.selectedSubcategory && this.selectedSubcategory !== 'ALL' && this.selectedAge && this.selectedAge !== 'ALL') {
          queryProducts = queryProducts
            .where('price', '>=', this.minPrice)
            .where('price', '<=', this.maxPrice)
            .where('selectedCategory', '==', this.selectedCategory)
            .where('selectedSubcategory', '==', this.selectedSubcategory)
            .where('selectedAge', 'array-contains', this.selectedAge);
        }
      }
      if (this.minPrice && !this.maxPrice) {
        if (((!this.selectedCategory || this.selectedCategory == 'ALL') && !this.selectedAge) || this.selectedAge == 'ALL') {
          queryProducts = queryProducts.where('price', '>=', this.minPrice);
        } else if ((this.selectedCategory != 'ALL' && (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') && !this.selectedAge) || this.selectedAge == 'ALL') {
          queryProducts = queryProducts.where('price', '>=', this.minPrice).where('selectedCategory', '==', this.selectedCategory);
        } else if ((this.selectedCategory != 'ALL' && this.selectedSubcategory && this.selectedSubcategory !== 'ALL' && !this.selectedAge) || this.selectedAge == 'ALL') {
          queryProducts = queryProducts
            .where('price', '>=', this.minPrice)
            .where('selectedCategory', '==', this.selectedCategory)
            .where('selectedSubcategory', '==', this.selectedSubcategory);
        } else if ((!this.selectedCategory || this.selectedCategory == 'ALL') && this.selectedAge && this.selectedAge !== 'ALL') {
          queryProducts = queryProducts.where('price', '>=', this.minPrice).where('selectedAge', 'array-contains', this.selectedAge);
        } else if (this.selectedCategory != 'ALL' && (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') && this.selectedAge && this.selectedAge !== 'ALL') {
          queryProducts = queryProducts
            .where('price', '>=', this.minPrice)
            .where('selectedCategory', '==', this.selectedCategory)
            .where('selectedAge', 'array-contains', this.selectedAge);
        } else if (this.selectedCategory != 'ALL' && this.selectedSubcategory && this.selectedSubcategory !== 'ALL' && this.selectedAge && this.selectedAge !== 'ALL') {
          queryProducts = queryProducts
            .where('price', '>=', this.minPrice)
            .where('selectedCategory', '==', this.selectedCategory)
            .where('selectedSubcategory', '==', this.selectedSubcategory)
            .where('selectedAge', 'array-contains', this.selectedAge);
        }
      }
      if (!this.minPrice && this.maxPrice) {
        queryProducts = queryProducts.where('price', '<=', this.maxPrice);
        if ((!this.selectedCategory || this.selectedCategory == 'ALL') && (!this.selectedAge || this.selectedAge == 'ALL')) {
          queryProducts = queryProducts.where('price', '<=', this.maxPrice);
        } else if ((this.selectedCategory != 'ALL' && (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') && !this.selectedAge) || this.selectedAge == 'ALL') {
          queryProducts = queryProducts.where('price', '<=', this.maxPrice).where('selectedCategory', '==', this.selectedCategory);
        } else if ((this.selectedCategory != 'ALL' && this.selectedSubcategory && this.selectedSubcategory !== 'ALL' && !this.selectedAge) || this.selectedAge == 'ALL') {
          queryProducts = queryProducts
            .where('price', '<=', this.maxPrice)
            .where('selectedCategory', '==', this.selectedCategory)
            .where('selectedSubcategory', '==', this.selectedSubcategory);
        } else if ((!this.selectedCategory || this.selectedCategory == 'ALL') && this.selectedAge && this.selectedAge !== 'ALL') {
          queryProducts = queryProducts.where('price', '<=', this.maxPrice).where('selectedAge', 'array-contains', this.selectedAge);
        } else if (this.selectedCategory != 'ALL' && (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') && this.selectedAge && this.selectedAge !== 'ALL') {
          queryProducts = queryProducts
            .where('price', '<=', this.maxPrice)
            .where('selectedCategory', '==', this.selectedCategory)
            .where('selectedAge', 'array-contains', this.selectedAge);
        } else if (this.selectedCategory != 'ALL' && this.selectedSubcategory && this.selectedSubcategory !== 'ALL' && this.selectedAge && this.selectedAge !== 'ALL') {
          queryProducts = queryProducts
            .where('price', '<=', this.maxPrice)
            .where('selectedCategory', '==', this.selectedCategory)
            .where('selectedSubcategory', '==', this.selectedSubcategory)
            .where('selectedAge', 'array-contains', this.selectedAge);
        }
      }
    }
    if (this.showList) {
      //const listRef = await app.config.globalProperties.$firestore.collection('blogshops').doc(this.user.uid).collection('lists').doc(this.showList).get();
      const itemsRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .doc(this.showList)
        .collection('items')
        .get();
      itemsRef.docs.forEach(async itemRef => {
        const ref = await app.config.globalProperties.$firestore
          .collection(itemRef.data().type + 's')
          .doc(itemRef.id)
          .get();

        if (!itemRef.exists) {
          return;
        }
        const item = ref.data();
        item.id = ref.id;
        item.type = itemRef.data().type;
        this.itemList.push(item);
      });
    } else if (this.listId && this.listOwner) {
      const itemsRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.listOwner)
        .collection('lists')
        .doc(this.listId)
        .collection('items')
        .get();
      itemsRef.docs.forEach(async itemRef => {
        const ref = await app.config.globalProperties.$firestore
          .collection(itemRef.data().type + 's')
          .doc(itemRef.id)
          .get();
        const item = ref.data();
        item.id = ref.id;
        item.type = itemRef.data().type;
        this.itemList.push(item);
      });
    } else if (this.productIds) {
      this.productIds.forEach(async productId => {
        const ref = await app.config.globalProperties.$firestore
          .collection('products')
          .doc(productId)
          .get();
        const item = ref.data();
        item.type = 'product';
        item.id = ref.id;
        this.itemList.push(item);
      });
    } else if (this.productId) {
      if (this.type === 'product') {
        const ref = await app.config.globalProperties.$firestore
          .collection('products')
          .doc(this.productId)
          .get();
        const item = ref.data();
        item.type = 'product';
        item.id = ref.id;
        console.log(item);
        this.itemList.push(item);
      } else if (this.type === 'packet') {
        const ref = await app.config.globalProperties.$firestore
          .collection('packets')
          .doc(this.productId)
          .get();
        const item = ref.data();
        item.type = 'packet';
        item.id = ref.id;
        this.itemList.push(item);
      }
    } else if (this.tipIds) {
      this.tipIds.forEach(async tipId => {
        const ref = await app.config.globalProperties.$firestore
          .collection('tips')
          .doc(tipId)
          .get();
        const item = ref.data();
        item.type = 'tip';
        item.id = ref.id;
        this.itemList.push(item);
      });
    } else if (this.packetIds) {
      this.packetIds.forEach(async packetId => {
        const ref = await app.config.globalProperties.$firestore
          .collection('packets')
          .doc(packetId)
          .get();
        const item = ref.data();
        item.type = 'packet';
        item.id = ref.id;
        this.itemList.push(item);
      });
    } else if (this.query) {
      let searchQuery = this.query;
      // search for blogshops with this.query == name
      let queryBlogshops = app.config.globalProperties.$firestore.collection('blogshops');
      queryBlogshops = queryBlogshops.where('name', 'in', [this.query, this.query.charAt(0).toUpperCase() + this.query.slice(1)]);
      const blogshopsRef = await queryBlogshops.get();
      blogshopsRef.docs.forEach(ref => {
        const item = ref.data();
        item.type = 'blogshop';
        item.id = ref.id;
        this.itemList.push(item);
      });

      if (
        ((!this.showProducts && !this.showTips && !this.showPackets) || this.showProducts) &&
        (!this.selectedCategory || this.selectedCategory == 'ALL') &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        (!this.minPrice || this.minPrice == 0) &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        let categoryFilter;
        let subCategoryFilter;
        for (const category of blogolinoData.categories) {
          if (category.name.toLowerCase().includes(this.query.toLowerCase()) === true) {
            categoryFilter = category.code;
          } else if (category.subcategories) {
            for (const subcategory of category.subcategories) {
              if (subcategory.name.toLowerCase().includes(this.query.toLowerCase()) === true) {
                subCategoryFilter = subcategory.code;
              }
            }
          }
        }
        if (categoryFilter) {
          searchQuery = categoryFilter;
        } else if (subCategoryFilter) {
          searchQuery = subCategoryFilter;
        }

        const index = this.algoliaClient.initIndex('products');
        index.search(searchQuery).then(async responses => {
          // Response from Algolia:
          // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
          for (const hit of responses.hits) {
            // eslint-disable-next-line
            const algoliaItem = Object.assign({}, hit) as any;
            const ref = await app.config.globalProperties.$firestore
              .collection('products')
              .doc(algoliaItem.objectID)
              .get();
            if (!ref.exists) {
              continue;
            }
            const item = ref.data();
            item.type = 'product';
            item.id = ref.id;
            this.itemList.push(item);
          }
        });
      }
      if (
        ((!this.showProducts && !this.showTips && !this.showPackets) || this.showTips) &&
        (!this.selectedCategory || this.selectedCategory == 'ALL') &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        (!this.minPrice || this.minPrice == 0) &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        let categoryTipFilter;
        for (const category of blogolinoData.tips) {
          if (category.name.toLowerCase().includes(this.query.toLowerCase()) === true) {
            categoryTipFilter = category.code;
          }
        }
        if (categoryTipFilter) {
          searchQuery = categoryTipFilter;
        }

        const indexTips = this.algoliaClient.initIndex('tips');
        indexTips.search(searchQuery).then(async responses => {
          // Response from Algolia:
          // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
          for (const hit of responses.hits) {
            // eslint-disable-next-line
            const algoliaItem = Object.assign({}, hit) as any;
            const ref = await app.config.globalProperties.$firestore
              .collection('tips')
              .doc(algoliaItem.objectID)
              .get();
            if (!ref.exists) {
              continue;
            }
            const item = ref.data();
            item.type = 'tip';
            item.id = ref.id;
            this.itemList.push(item);
          }
        });
      }
      if (
        ((!this.showProducts && !this.showTips && !this.showPackets) || this.showPackets) &&
        (!this.selectedCategory || this.selectedCategory == 'ALL') &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        (!this.minPrice || this.minPrice == 0) &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        let categoryPacketFilter;
        for (const category of blogolinoData.categories) {
          if (category.name.toLowerCase().includes(this.query.toLowerCase()) === true) {
            categoryPacketFilter = category.code;
          }
        }
        if (categoryPacketFilter) {
          searchQuery = categoryPacketFilter;
        }

        const indexPackets = this.algoliaClient.initIndex('packets');
        indexPackets.search(searchQuery).then(async responses => {
          // Response from Algolia:
          // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
          for (const hit of responses.hits) {
            // eslint-disable-next-line
            const algoliaItem = Object.assign({}, hit) as any;
            const ref = await app.config.globalProperties.$firestore
              .collection('packets')
              .doc(algoliaItem.objectID)
              .get();
            if (!ref.exists) {
              continue;
            }
            const item = ref.data();
            item.type = 'packet';
            item.id = ref.id;
            this.itemList.push(item);
          }
        });
      }
      if (
        this.showProducts &&
        this.selectedCategory &&
        this.selectedCategory != 'ALL' &&
        (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        (!this.minPrice || this.minPrice == 0) &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        this.showProducts &&
        this.selectedCategory &&
        this.selectedCategory != 'ALL' &&
        (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') &&
        this.selectedAge &&
        (!this.minPrice || this.minPrice == 0) &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedAge: ' + this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (this.showTips && this.selectedCategory && this.selectedCategory != 'ALL' && (!this.selectedAge || this.selectedAge == 'ALL')) {
        const index = this.algoliaClient.initIndex('tips');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('tips')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'tip';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (this.showTips && this.selectedCategory && this.selectedCategory != 'ALL' && this.selectedAge) {
        const index = this.algoliaClient.initIndex('tips');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedAge: ' + this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('tips')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'tip';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (this.showPackets && this.selectedCategory && this.selectedCategory != 'ALL' && (!this.selectedAge || this.selectedAge == 'ALL')) {
        const index = this.algoliaClient.initIndex('packets');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('packets')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'packet';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (this.showPackets && this.selectedCategory && this.selectedCategory != 'ALL' && this.selectedAge) {
        const index = this.algoliaClient.initIndex('packets');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedAge: ' + this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('packets')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'packet';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        ((!this.showProducts && !this.showTips && !this.showPackets) || this.showProducts) &&
        this.selectedAge &&
        (!this.selectedCategory || this.selectedCategory == 'ALL') &&
        (!this.minPrice || this.minPrice == 0) &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedAge: ' + this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        ((!this.showProducts && !this.showTips && !this.showPackets) || this.showProducts) &&
        (!this.selectedCategory || this.selectedCategory == 'ALL') &&
        this.selectedAge &&
        this.minPrice &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedAge: ' + this.selectedAge + ' AND price >= ' + this.minPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        ((!this.showProducts && !this.showTips && !this.showPackets) || this.showProducts) &&
        (!this.selectedCategory || this.selectedCategory == 'ALL') &&
        this.selectedAge &&
        this.maxPrice &&
        (!this.minPrice || this.minPrice == 0)
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedAge: ' + this.selectedAge + ' AND price <= ' + this.maxPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (((!this.showProducts && !this.showTips && !this.showPackets) || this.showProducts) && (!this.selectedCategory || this.selectedCategory == 'ALL') && this.selectedAge && this.maxPrice && this.minPrice) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedAge: ' + this.selectedAge + ' AND price <= ' + this.maxPrice + ' AND price >= ' + this.minPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (((!this.showProducts && !this.showTips && !this.showPackets) || this.showTips) && (!this.selectedCategory || this.selectedCategory == 'ALL') && this.selectedAge) {
        const index = this.algoliaClient.initIndex('tips');

        index
          .search(searchQuery, {
            filters: 'selectedAge: ' + this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('tips')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'tip';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (((!this.showProducts && !this.showTips && !this.showPackets) || this.showPackets) && (!this.selectedCategory || this.selectedCategory == 'ALL') && this.selectedAge) {
        const index = this.algoliaClient.initIndex('packets');

        index
          .search(searchQuery, {
            filters: 'selectedAge: ' + this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('packets')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'packet';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        ((!this.showProducts && !this.showTips && !this.showPackets) || this.showProducts) &&
        this.minPrice &&
        (!this.maxPrice || this.maxPrice == 0) &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        (!this.selectedCategory || this.selectedCategory == 'ALL')
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'price >= ' + this.minPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        ((!this.showProducts && !this.showTips && !this.showPackets) || this.showProducts) &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        (!this.selectedCategory || this.selectedCategory == 'ALL') &&
        this.maxPrice &&
        (!this.minPrice || this.minPrice == 0)
      ) {
        const index = this.algoliaClient.initIndex('products');
        index
          .search(searchQuery, {
            filters: 'price <= ' + this.maxPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        ((!this.showProducts && !this.showTips && !this.showPackets) || this.showProducts) &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        (!this.selectedCategory || this.selectedCategory == 'ALL') &&
        this.minPrice &&
        this.maxPrice
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'price >= ' + this.minPrice + ' AND price <= ' + this.maxPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }

      if (
        this.showProducts &&
        this.selectedCategory &&
        this.selectedCategory != 'ALL' &&
        (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') &&
        this.selectedAge &&
        (!this.maxPrice || this.maxPrice == 0) &&
        this.minPrice
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedAge: ' + this.selectedAge + ' AND price >= ' + this.minPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        this.showProducts &&
        this.selectedCategory &&
        this.selectedCategory != 'ALL' &&
        (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') &&
        this.selectedAge &&
        (!this.minPrice || this.minPrice == 0) &&
        this.minPrice
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedAge: ' + this.selectedAge + ' AND price <= ' + this.maxPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (this.showProducts && this.selectedCategory && this.selectedCategory != 'ALL' && (!this.selectedSubcategory || this.selectedSubcategory == 'ALL') && this.selectedAge && this.minPrice && this.maxPrice) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedAge: ' + this.selectedAge + ' AND price >= ' + this.minPrice + ' AND price <= ' + this.maxPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        this.showProducts &&
        this.selectedCategory &&
        this.selectedCategory != 'ALL' &&
        this.selectedSubcategory != 'ALL' &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        (!this.minPrice || this.minPrice == 0) &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedSubcategory: ' + this.selectedSubcategory
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        this.showProducts &&
        this.selectedCategory &&
        this.selectedCategory != 'ALL' &&
        this.selectedSubcategory != 'ALL' &&
        this.selectedAge &&
        (!this.minPrice || this.minPrice == 0) &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedSubcategory: ' + this.selectedSubcategory + ' AND selectedAge: ' + this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        this.showProducts &&
        this.selectedCategory &&
        this.selectedCategory != 'ALL' &&
        this.selectedSubcategory != 'ALL' &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        this.minPrice &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedSubcategory: ' + this.selectedSubcategory + ' AND selectedAge: ' + this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        this.showProducts &&
        this.selectedCategory &&
        this.selectedCategory != 'ALL' &&
        this.selectedSubcategory != 'ALL' &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        this.minPrice &&
        (!this.maxPrice || this.maxPrice == 0)
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedSubcategory: ' + this.selectedSubcategory + ' AND price >= ' + this.minPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (this.showProducts && this.selectedCategory && this.selectedCategory != 'ALL' && this.selectedSubcategory != 'ALL' && this.selectedAge && this.minPrice && (!this.maxPrice || this.maxPrice == 0)) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedSubcategory: ' + this.selectedSubcategory + ' AND price >= ' + this.minPrice + ' AND selectedAge: ' + this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (
        this.showProducts &&
        this.selectedCategory &&
        this.selectedCategory != 'ALL' &&
        this.selectedSubcategory != 'ALL' &&
        (!this.selectedAge || this.selectedAge == 'ALL') &&
        (!this.minPrice || this.minPrice == 0) &&
        this.maxPrice
      ) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedSubcategory: ' + this.selectedSubcategory + ' AND price <= ' + this.maxPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (this.showProducts && this.selectedCategory && this.selectedCategory != 'ALL' && this.selectedSubcategory != 'ALL' && this.selectedAge && (!this.minPrice || this.minPrice == 0) && this.maxPrice) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedSubcategory: ' + this.selectedSubcategory + ' AND price <= ' + this.maxPrice + ' AND selectedAge: ' + this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (this.showProducts && this.selectedCategory && this.selectedCategory != 'ALL' && this.selectedSubcategory != 'ALL' && (!this.selectedAge || this.selectedAge == 'ALL') && this.minPrice && this.maxPrice) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters: 'selectedCategory: ' + this.selectedCategory + ' AND selectedSubcategory: ' + this.selectedSubcategory + ' AND price <= ' + this.maxPrice + ' AND price >= ' + this.minPrice
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
      if (this.showProducts && this.selectedCategory && this.selectedCategory != 'ALL' && this.selectedSubcategory != 'ALL' && this.selectedAge && this.minPrice && this.maxPrice) {
        const index = this.algoliaClient.initIndex('products');

        index
          .search(searchQuery, {
            filters:
              'selectedCategory: ' +
              this.selectedCategory +
              ' AND selectedSubcategory: ' +
              this.selectedSubcategory +
              ' AND price <= ' +
              this.maxPrice +
              ' AND price >= ' +
              this.minPrice +
              ' AND selectedAge: ' +
              this.selectedAge
          })
          .then(async responses => {
            // Response from Algolia:
            // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
            for (const hit of responses.hits) {
              // eslint-disable-next-line
              const algoliaItem = Object.assign({}, hit) as any;
              const ref = await app.config.globalProperties.$firestore
                .collection('products')
                .doc(algoliaItem.objectID)
                .get();
              if (!ref.exists) {
                continue;
              }
              const item = ref.data();
              item.type = 'product';
              item.id = ref.id;
              this.itemList.push(item);
            }
          });
      }
    } else if (this.showFavorites) {
      /* IMPORTANT
        These filters apply only for specific Ids.
      */
      let queryFavoritesRef = app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('favorites');
      if (this.showProducts) {
        queryFavoritesRef = queryFavoritesRef.where('type', '==', 'product');
      }
      if (this.showTips) {
        queryFavoritesRef = queryFavoritesRef.where('type', '==', 'tip');
      }
      if (this.showPackets) {
        queryFavoritesRef = queryFavoritesRef.where('type', '==', 'packet');
      }
      if (this.showBlogshops) {
        queryFavoritesRef = queryFavoritesRef.where('type', '==', 'blogshop');
      }
      if (!this.typeSort) {
        const queryFavorites = await queryFavoritesRef.get();

        if (!queryFavorites.docs) {
          return;
        }
        await Promise.all(
          queryFavorites.docs.map(async favorite => {
            const favoriteData = favorite.data();
            let collectionString = 'products';
            if (favoriteData.type === 'packet') {
              collectionString = 'packets';
            }
            if (favoriteData.type === 'tip') {
              collectionString = 'tips';
            }
            if (favoriteData.type === 'blogshop') {
              collectionString = 'blogshops';
            }

            const ref = await app.config.globalProperties.$firestore
              .collection(collectionString)
              .doc(favorite.id)
              .get();
            if (!ref.exists) {
              return;
            }
            const item = ref.data();
            item.type = collectionString.slice(0, -1);
            item.id = ref.id;
            this.itemList.push(item);
          })
        );
      }
    } else {
      /* IMPORTANT
        These filters apply only for querying entire firestore collection with conditionsbundleRenderer.
        You can't filter specifc IDs.
      */
      if (!this.typeSort && this.show12 == false) {
        if (this.showAll || this.showProducts) {
          const productsRef = await queryProducts.get();

          productsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'product';
            item.id = ref.id;

            this.itemList.push(item);
          });
        }

        if (this.showAll || this.showPackets) {
          const packetsRef = await queryPackets.get();
          packetsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'packet';
            item.id = ref.id;
            this.itemList.push(item);
          });
        }
        if (this.showAll || this.showTips) {
          const tipsRef = await queryTips.get();
          tipsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'tip';
            item.id = ref.id;
            this.itemList.push(item);
          });
        }
      }
      if (!this.typeSort && this.show12 == true) {
        if (this.showAll || this.showProducts) {
          const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          const maxMultiple = Math.floor(256 / chars.length) * chars.length;
          let autoId = '';
          const targetLength = 20;
          while (autoId.length < targetLength) {
          /* eslint-disable */
            const crypto = require('crypto');
            const bytes = crypto.randomBytes(40);
              for (let i = 0; i < bytes.length; ++i) {
                // Only accept values that are [0, maxMultiple), this ensures they can
                // be evenly mapped to indices of `chars` via a modulo operation.
                  if (autoId.length < targetLength && bytes[i] < maxMultiple) {
                      autoId += chars.charAt(bytes[i] % chars.length);
                  }
              }
          }
          
          
          
          const showProducts = await queryProducts
            .where(firebase.firestore.FieldPath.documentId(), '>=', autoId)
            .limit(12)
            .get()
            
            
          showProducts.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'product';
            item.id = ref.id;
            this.itemList.push(item);
          });

          const length = showProducts.size
          

          if (length < 12) {
              
              autoId = '00000000000000000000';
              const showProducts = await queryProducts
            .where(firebase.firestore.FieldPath.documentId(), '>=', autoId)
            .limit(12 - length)
            .get()
            

            showProducts.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'product';
            item.id = ref.id;
            this.itemList.push(item);
          });
          }
        }

        if (this.showAll || this.showPackets) {
          const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          const maxMultiple = Math.floor(256 / chars.length) * chars.length;
          let autoId = '';
          const targetLength = 20;
          while (autoId.length < targetLength) {
          /* eslint-disable */
            const crypto = require('crypto');
            const bytes = crypto.randomBytes(40);
              for (let i = 0; i < bytes.length; ++i) {
                // Only accept values that are [0, maxMultiple), this ensures they can
                // be evenly mapped to indices of `chars` via a modulo operation.
                  if (autoId.length < targetLength && bytes[i] < maxMultiple) {
                      autoId += chars.charAt(bytes[i] % chars.length);
                  }
              }
          }
          
          
          
          const showPackets = await queryPackets
            .where(firebase.firestore.FieldPath.documentId(), '>=', autoId)
            .limit(12)
            .get()
            
            
          showPackets.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'packet';
            item.id = ref.id;
            this.itemList.push(item);
          });

          const length = showPackets.size
          

          if (length < 12) {
              
              autoId = '00000000000000000000';
              const showPackets = await queryPackets
            .where(firebase.firestore.FieldPath.documentId(), '>=', autoId)
            .limit(12 - length)
            .get()
            

            showPackets.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'packet';
            item.id = ref.id;
            this.itemList.push(item);
          });
          }
        }
        

        if (this.showAll || this.showTips) {

          const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          const maxMultiple = Math.floor(256 / chars.length) * chars.length;
          let autoId = '';
          const targetLength = 20;
          while (autoId.length < targetLength) {
          /* eslint-disable */
            const crypto = require('crypto');
            const bytes = crypto.randomBytes(40);
              for (let i = 0; i < bytes.length; ++i) {
                // Only accept values that are [0, maxMultiple), this ensures they can
                // be evenly mapped to indices of `chars` via a modulo operation.
                  if (autoId.length < targetLength && bytes[i] < maxMultiple) {
                      autoId += chars.charAt(bytes[i] % chars.length);
                  }
              }
          }
          
          
          
          const tipsRef = await queryTips
            .where(firebase.firestore.FieldPath.documentId(), '>=', autoId)
            .limit(12)
            .get()
            
            
          tipsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'tip';
            item.id = ref.id;
            this.itemList.push(item);
          });

          const length = tipsRef.size
          

          if (length < 12) {
              
              autoId = '00000000000000000000';
              const tipsRef = await queryTips
            .where(firebase.firestore.FieldPath.documentId(), '>=', autoId)
            .limit(12 - length)
            .get()
            

            tipsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'tip';
            item.id = ref.id;
            this.itemList.push(item);
          });
          }
        }
        
        
      }
      if (this.typeSort == 'Neueste') {
        if (this.showAll || this.showProducts) {
          const productsRef = await queryProducts.orderBy('createdAt', 'desc').get();

          productsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'product';
            item.id = ref.id;

            this.itemList.push(item);
          });
        }

        if (this.showAll || this.showPackets) {
          const packetsRef = await queryPackets.orderBy('createdAt', 'desc').get();
          packetsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'packet';
            item.id = ref.id;
            this.itemList.push(item);
          });
        }
        if (this.showAll || this.showTips) {
          const tipsRef = await queryTips.orderBy('createdAt', 'desc').get();
          tipsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'tip';
            item.id = ref.id;
            this.itemList.push(item);
          });
        }
      }
      if (this.typeSort == 'Beliebteste') {
        if (this.showAll || this.showProducts) {
          const productsRef = await queryProducts.orderBy('countFavorite', 'desc').get();

          productsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'product';
            item.id = ref.id;

            this.itemList.push(item);
          });
        }

        if (this.showAll || this.showPackets) {
          const packetsRef = await queryPackets.orderBy('countFavorite', 'desc').get();
          packetsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'packet';
            item.id = ref.id;
            this.itemList.push(item);
          });
        }
        if (this.showAll || this.showTips) {
          const tipsRef = await queryTips.orderBy('countFavorite', 'desc').get();
          tipsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'tip';
            item.id = ref.id;
            this.itemList.push(item);
          });
        }
      }
      if (this.typeSort == 'Günstigste') {
        if (this.showAll || this.showProducts) {
          const productsRef = await queryProducts.orderBy('price', 'asc').get();

          productsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'product';
            item.id = ref.id;

            this.itemList.push(item);
          });
        }

        if (this.showAll || this.showPackets) {
          const packetsRef = await queryPackets.orderBy('price', 'asc').get();
          packetsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'packet';
            item.id = ref.id;
            this.itemList.push(item);
          });
        }
        if (this.showAll || this.showTips) {
          const tipsRef = await queryTips.orderBy('price', 'asc').get();
          tipsRef.docs.forEach(ref => {
            if (ref.data().locked && this.userId != ref.data().userId) {
              return;
            }
            const item = ref.data();
            item.type = 'tip';
            item.id = ref.id;
            this.itemList.push(item);
          });
        }
      }
      if ((this.showAll && !this.selectedAge && this.typeSort != 'Günstigste') || this.showBlogshops) {
        queryBlogshops = queryBlogshops.where('blogshopExists', '==', true);
        const blogshopsRef = await queryBlogshops.get();
        blogshopsRef.docs.forEach(ref => {
          if (ref.data().locked && this.userId != ref.data().userId) {
            return;
          }
          const item = ref.data();
          item.type = 'blogshop';
          item.id = ref.id;
          this.itemList.push(item);
        });
      }
    }
  }
}
