
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue } from 'vue-class-component';
import app from '@/main';
import { useStore } from '@/store';

export default class Feedback extends Vue {
  public store = useStore();
  public useVisitorYes = false;
  public useVisitorNo = false;
  public useVisitorWhy = '';
  public comeOften = '';
  public useShopYes = false;
  public useShopNo = false;
  public useShopWhy = '';
  public useBlogshopYes = false;
  public useBlogshopNo = false;
  public useBlogshopWhy = '';
  public needBonusYes = false;
  public needBonusNo = false;
  public needBonusWhat = [];
  public needBonusText = '';
  public useBannerYes = false;
  public useBannerNo = false;
  public useRecomYes = false;
  public useRecomNo = false;
  public useRecomWhy = '';
  public opticDifferent = '';
  public contentDifferent = '';
  public extraInfo = '';
  public notIntuitive = '';
  public technicalProblems = '';
  public functionsNew = '';
  public longText = '';
  public modFunction = false;
  public feedbackIdExists = false;
  public feedbackId;

  public useVisitorYesChanged() {
    this.useVisitorNo = !this.useVisitorYes;
  }
  public useVisitorNoChanged() {
    this.useVisitorYes = !this.useVisitorNo;
  }
  public useShopYesChanged() {
    this.useShopNo = !this.useShopYes;
  }
  public useShopNoChanged() {
    this.useShopYes = !this.useShopNo;
  }
  public useBlogshopYesChanged() {
    this.useBlogshopNo = !this.useBlogshopYes;
  }
  public useBlogshopNoChanged() {
    this.useBlogshopYes = !this.useBlogshopNo;
  }
  public needBonusYesChanged() {
    this.needBonusNo = !this.needBonusYes;
  }
  public needBonusNoChanged() {
    this.needBonusYes = !this.needBonusNo;
  }
  public useBannerYesChanged() {
    this.useBannerNo = !this.useBannerYes;
  }
  public useBannerNoChanged() {
    this.useBannerYes = !this.useBannerNo;
  }
  public useRecomYesChanged() {
    this.useRecomNo = !this.useRecomYes;
  }
  public useRecomNoChanged() {
    this.useRecomYes = !this.useRecomNo;
  }

  public async created() {
    const feedbackId = this.$router.currentRoute.value.params.id;
    if (feedbackId) {
      this.feedbackIdExists = true;
      this.feedbackId = feedbackId;
      const feedbackRef = await app.config.globalProperties.$firestore
        .collection('feedback')
        .doc(feedbackId)
        .get();
      if (feedbackRef.exists && !this.$router.currentRoute.value?.params?.amazonProduct) {
        const feedback = feedbackRef.data();
        this.useVisitorYes = feedback.useVisitorYes;
        this.useVisitorNo = feedback.useVisitorNo;
        this.useVisitorWhy = feedback.useVisitorWhy;
        this.comeOften = feedback.comeOften;
        this.useShopYes = feedback.useShopYes;
        this.useShopNo = feedback.useShopNo;
        this.useShopWhy = feedback.useShopWhy;
        this.useBlogshopYes = feedback.useBlogshopYes;
        this.useBlogshopNo = feedback.useBlogshopNo;
        this.useBlogshopWhy = feedback.useBlogshopWhy;
        this.needBonusYes = feedback.needBonusYes;
        this.needBonusNo = feedback.needBonusNo;
        this.needBonusWhat = feedback.needBonusWhat;
        this.needBonusText = feedback.needBonusText;
        this.useBannerYes = feedback.useBannerYes;
        this.useBannerNo = feedback.useBannerNo;
        this.useRecomYes = feedback.useRecomYes;
        this.useRecomNo = feedback.useRecomNo;
        this.useRecomWhy = feedback.useRecomWhy;
        this.opticDifferent = feedback.opticDifferent;
        this.contentDifferent = feedback.contentDifferent;
        this.extraInfo = feedback.extraInfo;
        this.notIntuitive = feedback.notIntuitive;
        this.technicalProblems = feedback.technicalProblems;
        this.functionsNew = feedback.functionsNew;
        this.longText = feedback.longText;
      }
    }
  }

  public async sendFeedback() {
    const newFeedback: any = {
      useVisitorYes: this.useVisitorYes,
      useVisitorNo: this.useVisitorNo,
      useVisitorWhy: this.useVisitorWhy,
      comeOften: this.comeOften,
      useShopYes: this.useShopYes,
      useShopNo: this.useShopNo,
      useShopWhy: this.useShopWhy,
      useBlogshopYes: this.useBlogshopYes,
      useBlogshopNo: this.useBlogshopNo,
      useBlogshopWhy: this.useBlogshopWhy,
      needBonusYes: this.needBonusYes,
      needBonusNo: this.needBonusNo,
      needBonusWhat: this.needBonusWhat,
      needBonusText: this.needBonusText,
      useBannerYes: this.useBannerYes,
      useBannerNo: this.useBannerNo,
      useRecomYes: this.useRecomYes,
      useRecomNo: this.useRecomNo,
      useRecomWhy: this.useRecomWhy,
      opticDifferent: this.opticDifferent,
      contentDifferent: this.contentDifferent,
      extraInfo: this.extraInfo,
      notIntuitive: this.notIntuitive,
      technicalProblems: this.technicalProblems,
      functionsNew: this.functionsNew,
      longText: this.longText,
      createdAt: new Date()
    };
    if (this.user && this.modFunction == false) {
      newFeedback.userId = this.user.uid;
    }
    Object.keys(newFeedback).forEach(key => (newFeedback[key] === false || newFeedback[key] === '' ? delete newFeedback[key] : {}));

    await app.config.globalProperties.$firestore.collection('feedback').add(newFeedback);
    this.$toast.add({ severity: 'success', summary: 'Feedback gesendet', detail: 'Dein Feedback wurde erfolgreich gesendet', life: 3000 });

    this.$router.push({ name: 'Home' });
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
}
