
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue } from 'vue-class-component';
import app from '@/main';
import { useStore } from '@/store';

export default class WriteMessage extends Vue {
  public store = useStore();
  // eslint-disable-next-line
  public messageContent = '';
  public messageAbout = '';

  public async sendMessage() {
    const newChat = {
      userIds: ['Alle'],
      createdAt: new Date(),
      latestMessage: new Date(),
      supportMessage: true,
      messageAbout: this.messageAbout
    };

    const newMessage = {
      messageContent: this.messageContent,
      createdAt: new Date()
    };
    const messageRef = await app.config.globalProperties.$firestore.collection('chats').add(newChat);
    await app.config.globalProperties.$firestore
      .collection('chats')
      .doc(messageRef.id)
      .collection('messages')
      .add(newMessage);

    this.$router.go(-1);
    this.$toast.add({ severity: 'success', summary: 'Nachricht gesendet', detail: 'Deine Nachricht wurde erfolgreich gesendet', life: 3000 });
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
}
