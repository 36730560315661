
import { Options, Vue } from 'vue-class-component';
import Password from 'primevue/password';
import { useStore } from '@/store';
import app from '@/main';
import { Field, Form } from 'vee-validate';
import * as blogolinoData from '@/blogolinoData.json';

@Options({
  components: {
    Password,
    Field,
    Form
  }
})
export default class CreateBlogshop extends Vue {
  public store = useStore();

  public city = '';
  public blogshopExists = false;
  public validationErrors = {};
  public submitted = false;
  public blogshopDocument;
  public familyConstellation = '';
  public constellations = blogolinoData.familyConstellation;
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  created() {
    if (this.userDocument) {
      this.city = this.userDocument.city;
    }
  }

  public async createBlogShop() {
    this.submitted = true;
    if (!this.city) {
      this.validationErrors['city'] = !this.city;
      return;
    }
    // So können Daten in die Firestore Datenbank geschrieben werden.
    // https://firebase.google.com/docs/firestore/manage-data/add-data
    const userDocument = {
      city: this.city,
      blogshopExists: true
    };
    const blogshopDocument = {
      createdAt: new Date(),
      blogshopExists: true,
      city: this.city,
      familyConstellation: this.familyConstellation,
      countProducts: 0,
      countTips: 0,
      countPackets: 0,
      countFollowers: 0
    };
    await app.config.globalProperties.$firestore
      .collection('users')
      .doc(this.user.uid)
      .set(userDocument, { merge: true });
    this.store.commit('setUserDocument', userDocument);
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .set(blogshopDocument, { merge: true });
    this.$router.push({ name: 'MeinBlogshop' });
  }
}
