
import { Vue, Options } from 'vue-class-component';
import SettingsMenu from '@/components/SettingsMenu.vue'; // @ is an alias to /src
import { useStore } from '@/store';
import app from '@/main';

@Options({
  components: {
    SettingsMenu
  }
})
export default class SettingsMail extends Vue {
  public store = useStore();
  public getMessage = false;
  public getFollower = false;
  public likesIdea = false;
  public newsletter = false;

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  public async created() {
    if (this.user) {
      const userRef = await app.config.globalProperties.$firestore
        .collection('users')
        .doc(this.user.uid)
        .get();
      if (userRef.exists) {
        const userData = userRef.data();
        this.newsletter = userData.newsletter;
      }
    }
  }

  public async saveMailChanges() {
    const userDocument = {
      newsletter: this.newsletter
    };
    await app.config.globalProperties.$firestore
      .collection('users')
      .doc(this.user.uid)
      .set(userDocument, { merge: true });
    this.store.commit('setUserDocument', userDocument);
    this.$toast.add({ severity: 'success', summary: 'E-Mail-Einstellungen gespeichert', detail: 'Deine E-Mail-Einstellungen wurden erfolgreich gespeichert', life: 3000 });
  }
}
