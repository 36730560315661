
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { Vue, prop } from 'vue-class-component';
import { useStore } from '@/store';
import 'firebase/auth';
import * as blogolinoData from '@/blogolinoData.json';

class Props {
  selectedAge = prop({
    type: String,
    required: false
  });
}

export default class SidebarAge extends Vue.with(Props) {
  public visibleRight = false;
  public store = useStore();
  public selectedCode = '';

  public ages: any = [];

  public async created() {
    this.ages = blogolinoData.ages;
    console.log(this.selectedAge);
    if (this.selectedAge) {
      this.selectedCode = this.selectedAge;
    }
  }
  public reload() {
    if (this.selectedAge) {
      this.selectedCode = this.selectedAge;
    }
  }

  public openAge(code) {
    this.selectedCode = code;
    this.$router.push({ name: 'Search', query: { age: code } });
    this.visibleRight = false;
  }
}
