
import { Options, Vue } from 'vue-class-component';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src
import app from '@/main';

@Options({
  components: {
    DashboardMenu
  }
})
export default class DashboardNewsletter extends Vue {
  public newsletterList: [unknown?] = [];

  async created() {
    debugger;
    const queryNewsletter = await app.config.globalProperties.$firestore
      .collection('users')
      .where('newsletter', '==', true)
      .get();
    console.log(queryNewsletter);
    if (queryNewsletter) {
      queryNewsletter.docs.forEach(ref => {
        const item = ref.data();
        item.name = ref.name;
        item.email = ref.email;
        this.newsletterList.push(item);
      });
    }
  }
}
