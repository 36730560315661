
/* eslint-disable */

import { Vue, Options, prop } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';

// Define the props by using Vue's canonical way.
class Props {
  userId = prop({
    type: String,
    required: false
  });
  query = prop({
    type: String,
    required: false
  });
  showAll = prop({
    type: Boolean,
    required: false
  });
  showSupport = prop({
    type: Boolean,
    required: false
  });
  showAdmin = prop({
    type: Boolean,
    required: false
  });
  showMessages = prop({
    type: Boolean,
    required: false
  });
}

@Options({
  components: {}
})
export default class ChatGrid extends Vue.with(Props) {
  public store = useStore();
  public itemList: [any?] = [];
  public messages: [any?] = [];
  public lastMessage;
  public about;

  async mounted() {
    if (this.showSupport) {
      const queryChats = await app.config.globalProperties.$firestore
        .collection('chats')
        .where('supportMessage', '==', true)
        .where('userIds', 'array-contains-any', [this.user.uid, 'Alle']);

      const chatsRef = await queryChats.orderBy('latestMessage', 'desc').get();
      chatsRef.docs.forEach(async ref => {
        const item = ref.data();
        item.type = 'chat';
        item.id = ref.id;
        item.displayNames = [];
        item.showProfilePictures = [];
        item.userIds = item.userIds.filter(item => {
          return item !== this.user.uid;
        });

        for (let _userId of item.userIds) {
          if (_userId != 'Alle') {
            const displayName = await this.getUserName(_userId);
            item.displayNames.push(displayName);
            const showProfilePicture = await this.getUserPicture(_userId);
            item.showProfilePictures.push(showProfilePicture);
          }
        }
        const messagesRef = await app.config.globalProperties.$firestore
          .collection('chats')
          .doc(ref.id)
          .collection('messages')
          .orderBy('createdAt', 'desc')
          .limit(1)
          .get();
        messagesRef.docs.forEach(messageRef => {
          item.lastMessage = messageRef.data().messageContent;
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          item.lastMessageWritten = messageRef
            .data()
            .createdAt.toDate()
            .toLocaleDateString('de-DE', options);
        });
        this.itemList.push(item);
      });
    } else if (this.showAdmin) {
      const queryChats = await app.config.globalProperties.$firestore
        .collection('chats')
        .where('supportMessage', '==', true)
        .where('userIds', 'array-contains-any', ['H4GZM4XHtVU6g4v0huoYBG4pTPj1', 'Alle']);

      const chatsRef = await queryChats.orderBy('latestMessage', 'desc').get();

      chatsRef.docs.forEach(async ref => {
        const item = ref.data();
        item.type = 'chat';
        item.id = ref.id;
        item.displayNames = [];
        item.showProfilePictures = [];

        item.userIds = item.userIds.filter(item => {
          return item !== this.user.uid;
        });

        for (let _userId of item.userIds) {
          if (_userId != 'Alle') {
            const displayName = await this.getUserName(_userId);
            item.displayNames.push(displayName);
            const showProfilePicture = await this.getUserPicture(_userId);
            item.showProfilePictures.push(showProfilePicture);
          }
        }
        const messagesRef = await app.config.globalProperties.$firestore
          .collection('chats')
          .doc(ref.id)
          .collection('messages')
          .orderBy('createdAt', 'desc')
          .limit(1)
          .get();
        messagesRef.docs.forEach(messageRef => {
          item.lastMessage = messageRef.data().messageContent;
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          item.lastMessageWritten = messageRef
            .data()
            .createdAt.toDate()
            .toLocaleDateString('de-DE', options);
        });
        this.itemList.push(item);
      });
    } else {
      const queryChats = await app.config.globalProperties.$firestore
        .collection('chats')
        .where('supportMessage', '==', false)
        .where('userIds', 'array-contains', this.user.uid);
      const chatsRef = await queryChats.orderBy('latestMessage', 'desc').get();

      chatsRef.docs.forEach(async ref => {
        const item = ref.data();
        item.type = 'chat';
        item.id = ref.id;
        item.displayNames = [];
        item.showProfilePictures = [];
        item.unreadMessage = item.unreadMessage;
        item.itemType = item.itemType;
        item.itemId = item.itemId;
        item.about = '';
        console.log(item);

        const aboutRef = await app.config.globalProperties.$firestore
          .collection(item.itemType)
          .doc(item.itemId)
          .get();

        if (item.itemType == 'products' || item.itemType == 'tips' || item.itemType == 'packets') {
          item.about = aboutRef.data().title;
        } else if (item.itemType == 'blogshops') {
          item.about = aboutRef.data().name + '´s ' + 'Blogshop';
        }

        item.userIds = item.userIds.filter(item => {
          return item !== this.user.uid;
        });

        for (let _userId of item.userIds) {
          const displayName = await this.getUserName(_userId);
          item.displayNames.push(displayName);
          const showProfilePicture = await this.getUserPicture(_userId);
          if (showProfilePicture) {
            item.showProfilePictures.push(showProfilePicture);
          }
        }

        const messagesRef = await app.config.globalProperties.$firestore
          .collection('chats')
          .doc(item.id)
          .collection('messages')
          .orderBy('createdAt', 'desc')
          .limit(1)
          .get();
        messagesRef.docs.forEach(messageRef => {
          item.lastMessage = messageRef.data().messageContent;
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          item.lastMessageWritten = messageRef
            .data()
            .createdAt.toDate()
            .toLocaleDateString('de-DE', options);
        });
        this.itemList.sort((a, b) => b.latestMessage - a.latestMessage);
        this.itemList.push(item);
      });
    }
  }
  public goToWriteMessage(chatId, userIds, itemId, itemType) {
    this.$router.push({
      name: 'WriteMessage',
      params: { receiverId: userIds[0] },
      query: { itemId: itemId, chatId: chatId, type: itemType }
    });
  }
  async getUserName(userId) {
    const blogShopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(userId)
      .get();

    return blogShopRef.data().name;
  }
  async getUserPicture(userId) {
    const blogShopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(userId)
      .get();

    return blogShopRef.data()?.imageUrls ? blogShopRef.data().imageUrls[0] : null;
  }
  public showAbout() {}

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
}
