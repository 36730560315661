
import { Options, Vue } from 'vue-class-component';
import DashboardMenu from '@/components/DashboardMenu.vue'; // @ is an alias to /src

@Options({
  components: {
    DashboardMenu
  }
})
export default class DashboardPartners extends Vue {}
